import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import classNames from 'classnames';

import ArrowLeft from '../../SvgComponents/ArrowLeft_svg';
import ArrowRight from '../../SvgComponents/ArrowRight_svg';
import RewardItem from '../RewardItem';

import './RewardsList.scss';

const CustomIndicators = ({ index, count, onSelect }) => {
  const maxIndicators = Math.min(5, count);
  const indicators = [];

  for (let i = 0; i < maxIndicators; i++) {
    indicators.push(
      <div
        key={`indicator-${i}`}
        role="button"
        tabIndex={0}
        aria-label="Slide"
        className={`custom-indicator ${i === index ? 'active' : ''}`}
        onClick={() => onSelect(i)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            onSelect(i);
          }
        }}
      />,
    );
  }

  return <div className="custom-indicators">{indicators}</div>;
};

const SlideCounter = ({ currentIndex, totalCount }) => (
  <div className="slide-counter">
    {currentIndex + 1}
    /
    {totalCount}
  </div>
);

// eslint-disable-next-line no-unused-vars
const CustomCarousel = ({ rewardsList, onClick }) => {
  const [index, setIndex] = useState(0);

  const showControls = rewardsList.length > 1;

  const handleSelect = (selectedIndex) => {
    if (selectedIndex >= rewardsList.length) {
      setIndex(0);
    } else if (selectedIndex < 0) {
      setIndex(rewardsList.length - 1);
    } else {
      setIndex(selectedIndex);
    }
  };

  if (rewardsList.length === 0) {
    return null;
  }

  return (
    <div className={classNames('RewardsList-carousel', { withControls: showControls })}>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        indicators={false}
        prevIcon={showControls ? (
          <div className="arrow-button arrow-left">
            <ArrowLeft alt="arrow left" />
          </div>
        ) : null}
        nextIcon={showControls ? (
          <div className="arrow-button arrow-right">
            <ArrowRight alt="arrow right" />
          </div>
        ) : null}
      >
        {rewardsList.map((rewardItem) => (
          <Carousel.Item key={`reward-${rewardItem.uid}`}>
            <RewardItem rewardItem={rewardItem} onClick={onClick} />
          </Carousel.Item>
        ))}
        {showControls && (
          <div className="slider-footer-section">
            <CustomIndicators index={index} count={rewardsList.length} onSelect={handleSelect} />
            <SlideCounter currentIndex={index} totalCount={rewardsList.length} />
          </div>
        )}
      </Carousel>
    </div>
  );
};

const RewardsList = ({ rewardsList, onClick, rewardProperties }) => (
  <div className="RewardsList">
    {rewardsList.map((rewardItem) => (
      <RewardItem
        key={`reward-${rewardItem.uid}`}
        rewardItem={rewardItem}
        onClick={onClick}
        rewardProperties={rewardProperties}
      />
    ))}
  </div>
);

export default RewardsList;
