import { useEffect } from 'react';
import { isTrashieApp } from '../config/config';

const useScript = (innerHTML, src = null) => {
  if (!innerHTML && !src) {
    return;
  }

  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    if (!src) {
      script.innerHTML = innerHTML;
    } else {
      script.src = src;
      script.async = true;
    }

    document.head.appendChild(script);

    return () => {
      if (isTrashieApp()) {
        document.head.removeChild(script);
      }
    };
  }, [innerHTML]);
};

export default useScript;
