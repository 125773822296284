export const TRASHIE_BASE_URL = {
  development: 'http://localhost:3030',
  production: 'https://app.trashie.io',
  feature: 'https://app.feature.trashie.io',
  qa: 'https://app.qa.trashie.io',
  staging: 'https://app.staging.trashie.io',
  preview: /https:\/\/fd-swap-feature--(preview|feat|fix)-(.*)\.web\.app/,
};

export const CLOSET_CASH_URL = {
  development: 'http://localhost:3000',
  production: 'https://closet.fordays.com',
  feature: 'https://fd-swap-feature.web.app/',
  qa: 'https://fd-swap-qa.web.app',
  staging: 'https://fd-swap-staging.web.app',
};

export const getConfig = (key) => {
  const base = {
    BASE_URL: 'http://localhost:3000',
    WEB_URL: 'https://www.fordays.com',
    BASE_URL_TRASHIE: TRASHIE_BASE_URL.development,
    WEB_URL_TRASHIE: 'https://www.trashie.io',
    REACT_APP_FIREBASE_PUB_KEY: 'AIzaSyChjNp9tctkkZWX-QhNdOTVLCVQg6y8GDA',
    REACT_APP_FIREBASE_AUTH_DOMAIN: 'fd-swap-app-prod-1.firebaseapp.com',
    REACT_APP_FIREBASE_DATABASE_URL: 'https://fd-swap-app-prod-1.firebaseio.co',
    REACT_APP_FIREBASE_PROJECT_ID: 'fd-swap-app-prod-1',
    REACT_APP_FIREBASE_STORAGE_BUCKET: 'fd-swap-app-prod-1.appspot.com',
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '643780589845',
    REACT_APP_FIREBASE_APP_ID: '1:643780589845:web:1c30e6819054abe9e2111c',
    REACT_APP_SWAP_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api',
    // REACT_APP_SWAP_API_ENDPOINT: 'http://localhost:5000/fd-swap-app-prod-1/us-central1/api',
    REACT_APP_STORE_ID: '5280b4e0-5354-4e8e-9e4d-2bffee9e37f2',
    // REACT_APP_CLOUD_API_ENDPOINT: 'http://localhost:5001/fd-swap-app-prod-1/us-central1/api_dev',
    REACT_APP_CLOUD_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_v2',
  };

  const development = {
    ...base,
    REACT_APP_FIREBASE_AUTH_DOMAIN: TRASHIE_BASE_URL.staging.replace('https://', ''),
  };

  const feature = {
    ...base,
    BASE_URL: 'https://fd-swap-feature.web.app/',
    BASE_URL_TRASHIE: TRASHIE_BASE_URL.feature,
    REACT_APP_FIREBASE_AUTH_DOMAIN: TRASHIE_BASE_URL.feature.replace('https://', ''),
    REACT_APP_SWAP_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_feature',
    REACT_APP_CLOUD_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_feature',
  };

  const qa = {
    ...base,
    BASE_URL: 'https://fd-swap-qa.web.app',
    BASE_URL_TRASHIE: TRASHIE_BASE_URL.qa,
    REACT_APP_FIREBASE_AUTH_DOMAIN: TRASHIE_BASE_URL.qa.replace('https://', ''),
    REACT_APP_SWAP_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_qa',
    REACT_APP_CLOUD_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_qa',
  };

  const staging = {
    ...base,
    BASE_URL: 'https://fd-swap-staging.web.app',
    BASE_URL_TRASHIE: TRASHIE_BASE_URL.staging,
    REACT_APP_FIREBASE_AUTH_DOMAIN: TRASHIE_BASE_URL.staging.replace('https://', ''),
    REACT_APP_SWAP_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_staging',
    REACT_APP_CLOUD_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_staging',
  };

  const production = {
    ...base,
    BASE_URL: 'https://closet.fordays.com',
    BASE_URL_TRASHIE: TRASHIE_BASE_URL.production,
    REACT_APP_FIREBASE_AUTH_DOMAIN: TRASHIE_BASE_URL.production.replace('https://', ''),
    REACT_APP_SWAP_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_v2',
    REACT_APP_CLOUD_API_ENDPOINT: 'https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api_v2',
  };

  const configSettings = {
    development,
    feature,
    qa,
    staging,
    production,
  };

  const env = process.env.REACT_APP_ENV || 'production';
  const config = configSettings[env] || configSettings.development;
  if (!key) {
    return config;
  }

  return config[key];
};

export const isTrashieApp = () => {
  const {
    location: { protocol, hostname, port },
  } = window;

  const currentLocation = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

  return (
    currentLocation.match(TRASHIE_BASE_URL.preview) ||
    [
      TRASHIE_BASE_URL.development,
      TRASHIE_BASE_URL.feature,
      TRASHIE_BASE_URL.qa,
      TRASHIE_BASE_URL.staging,
      TRASHIE_BASE_URL.production,
    ].includes(currentLocation)
  );
};

export const trashieRoute = () => {
  const {
    location: { protocol, hostname, port },
  } = window;

  const currentLocation = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

  switch (currentLocation) {
    case CLOSET_CASH_URL.development:
      return TRASHIE_BASE_URL.development;
    case CLOSET_CASH_URL.production:
      return TRASHIE_BASE_URL.production;
    case CLOSET_CASH_URL.feature:
      return TRASHIE_BASE_URL.feature;
    case CLOSET_CASH_URL.qa:
      return TRASHIE_BASE_URL.qa;
    case CLOSET_CASH_URL.staging:
      return TRASHIE_BASE_URL.staging;
    default:
      return TRASHIE_BASE_URL.production;
  }
};

export const isProd = () => process.env?.REACT_APP_ENV === 'production';
export const environmentSelected = process.env?.REACT_APP_ENV;

export default {
  getConfig,
  isTrashieApp,
  isProd,
  environmentSelected,
};
