import OfferItem from '../OfferItem';

import './OffersList.scss';

const OffersList = ({ offersList, isDashBoard, style }) => (
  <div className="OffersList" style={{ ...style }}>
    {offersList.map((offerItem) => (
      <OfferItem
        key={`offer-${offerItem.merchantId}`}
        offerItem={offerItem}
        isDashBoard={isDashBoard}
      />
    ))}
  </div>
);

export default OffersList;
