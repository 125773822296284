import Slider from '@mui/material/Slider';
import { styled } from '@mui/material';

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) => prop !== 'discountCodes',
})(({
  slim, // boolean
  trackLimit, // string (e.g. '50%')
  exceed, // boolean
}) => ({
  color: '#3333FF',
  height: '6px',
  padding: '20px 0',
  width: 'calc(100% - 32px)',
  margin: '0 16px 25px',
  ...(slim && {
    height: '2px',
    padding: '22px 0',
  }),
  ...(exceed && {
    color: '#FF7F50',
  }),
  '& .MuiSlider-rail': {
    backgroundColor: '#D9D9D9',
    opacity: 1,
    ...(slim && {
      backgroundColor: '#F0F0F0',
    }),
    ...(trackLimit && {
      '::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '0%',
        borderRadius: 'inherit',
        height: 'inherit',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: '#A9A9A9',
        width: trackLimit,
      },
    }),
  },
  '.MuiSlider-mark': {
    display: 'none',
  },
  '.MuiSlider-markActive': {
    display: 'none',
  },
  '.MuiSlider-thumb': {
    width: '32px',
    height: '32px',
    color: '#FCFFFD',
    backgroundColor: '#FCFFFD',
    filter: 'drop-shadow(0px 0px 7.111px rgba(0, 0, 0, 0.25))',
    '&:hover': {
      boxShadow: '0px 0px 7.111px rgba(0, 0, 0, 0.25)',
    },
  },
  '.MuiSlider-markLabel': {
    top: '45px',
    color: '#696969',
    fontFamily: 'GT Maru',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '175%',
  },
  '.MuiSlider-valueLabelOpen': {
    boxShadow: '0px 0px 7.111px rgba(0, 0, 0, 0.25)',
  },
}));

export default StyledSlider;
