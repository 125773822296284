import * as CryptoJS from 'crypto-js';
import { REGISTER_DATA_PARAM } from '../config/routes';
import { getSearchParam } from './routes';
import { isJson } from './utils';

function getIv() {
  return CryptoJS.lib.WordArray.random(16);
}

export function encryptWithPassphrase(text, passphrase) {
  return CryptoJS.AES.encrypt(text, passphrase, {
    iv: getIv(),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}

export function decryptWithPassphrase(encryptedText, passphrase) {
  return CryptoJS.AES.decrypt(encryptedText, passphrase, {
    iv: getIv(),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
}

export function getDecryptedData(query, userUid) {
  const dataParam = getSearchParam(query, REGISTER_DATA_PARAM);
  const dataDecoded = dataParam && userUid ? decryptWithPassphrase(decodeURIComponent(dataParam), btoa(userUid)) : '{}';
  const dataParsed = isJson(dataDecoded) ? JSON.parse(dataDecoded) : {};

  return dataParsed;
}
