import { useMemo } from 'react';
import classNames from 'classnames';

import TBBBlueRegister from '../../../../images/tbb-blue-register.png';
import TrashiCoinLogo from '../../../../images/trashie-coin-logo.svg';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { getNextButtonStyles } from '../../../../utils/tbbRegistration';

import './Welcome.scss';

const Welcome = ({
  handleOnClick,
  storeConfig,
}) => {
  const {
    isTrashie: isTrashieStore,
    mainLogoSrc,
    registerTbbFlow,
  } = useMemo(() => storeConfig, [storeConfig]);

  const isTrashie = useMemo(() => (
    isTrashieStore || !mainLogoSrc
  ), [isTrashieStore, mainLogoSrc]);

  return (
    <div className="RegisterWelcome">
      {isTrashie && (
        <div className="RegisterWelcome__image">
          <img
            src={TBBBlueRegister}
            alt="The take back bag"
          />
        </div>
      )}
      <div className={classNames('RegisterWelcome__logo', { 'is-trashie': isTrashie })}>
        <img
          src={isTrashie ? TrashiCoinLogo : mainLogoSrc}
          alt="store logo"
          style={registerTbbFlow?.landingPageStep?.logoStyleProperties}
        />
      </div>
      <div className="RegisterWelcome__text">
        {registerTbbFlow?.landingPageStep?.description || (
          <>
            REGISTER YOUR TAKE BACK BAG
            <br />
            AND GET REWARDS
          </>
        )}
      </div>
      <Button
        type={BUTTON_TYPE.QUATERNARY}
        size={BUTTON_SIZE.LARGE}
        onClick={handleOnClick}
        style={getNextButtonStyles(storeConfig)}
      >
        {registerTbbFlow?.landingPageStep?.button?.text ?? 'GET STARTED'}
      </Button>
    </div>
  );
};

export default Welcome;
