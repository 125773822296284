import React from 'react';

export default function UserCircle({ color = '#696969', width = 24, height = 25, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 36" fill="none" {...rest}>
      <path d="M18.0002 33C26.2845 33 33.0002 26.2843 33.0002 18C33.0002 9.71573 26.2845 3 18.0002 3C9.71597 3 3.00024 9.71573 3.00024 18C3.00024 26.2843 9.71597 33 18.0002 33Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.0002 19.5C20.4855 19.5 22.5002 17.4853 22.5002 15C22.5002 12.5147 20.4855 10.5 18.0002 10.5C15.515 10.5 13.5002 12.5147 13.5002 15C13.5002 17.4853 15.515 19.5 18.0002 19.5Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.0593 30.3532C10.0593 27.4294 13.3292 24.1768 18.0005 24.1768C22.6718 24.1768 25.9417 27.4294 25.9417 30.3532" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
