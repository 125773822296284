import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { isTrashieApp, trashieRoute } from '../../../../config/config';
import {
  ANCHOR_REWARDS,
  EMAIL_SEARCH_PARAM,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_REWARDS_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
  TYPE_SEARCH_PARAM,
} from '../../config/routes';
import { getRewards, getRewardsOffer, getConfigurations } from '../../utils/service';
import CycloneIcon from '../../images/cyclone-icon.svg';
import LoadingBar from '../LoadingBar';
import { viewType } from '../GlobalLogin';
import TitleSection from '../TitleSection';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../Button';
import RewardsList from './RewardsList';

import './Rewards.scss';

const LIST_LOAD_SIZE = 10;

const Rewards = ({
  isDashboard = true,
  userEmail = '',
  isNewUser,
  storesId = [],
}) => {
  const history = useHistory();
  const timeoutRef = useRef(null);
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [rewardToScroll, setRewardToScroll] = useState(null);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [rewardProperties, setRewardProperties] = useState({});

  const scrollToReward = useCallback((uid) => {
    const mainElement = document.getElementById(`RewardItem_${uid}`);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
    }
  }, []);

  const loadRewards = useCallback(async () => {
    if (hasLoaded && isLoading) {
      return;
    }

    setIsLoading(true);

    const rewardsParams = {
      pageSize: LIST_LOAD_SIZE,
      page: page + 1,
    };

    const { data: rewardsData, error } = isDashboard
      ? await getRewards(rewardsParams)
      : await getRewardsOffer({ ...rewardsParams, stores: storesId });

    if (error) {
      setRewardToScroll(null);
      setIsLoading(false);
      setHasLoaded(true);
      return;
    }

    const {
      total,
      rewards,
    } = rewardsData;

    setListData(prev => [...prev, ...rewards]);
    setTotalItems(total);
    setPage(page + 1);
    setRewardToScroll((hasLoaded && rewards[0]?.uid) || null);
    setIsLoading(false);
    setHasLoaded(true);
  }, [isLoading, isDashboard, storesId, page, hasLoaded]);

  const handleLoadMore = useCallback(async () => {
    await loadRewards();
  }, [loadRewards]);

  const handleOnClick = useCallback(({ storeId, rewardId }) => {
    const relativePath = ROUTE_REWARDS_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, storeId)
      .replace(REWARD_ID_ROUTE_PARAM, rewardId);

    if (!isDashboard) {
      const basePath = !isTrashieApp() ? trashieRoute() : '';

      const search = new URLSearchParams({});

      if (isNewUser) {
        search.set(TYPE_SEARCH_PARAM, viewType.VIEW_EMAIL_FORM);
        search.set(EMAIL_SEARCH_PARAM, userEmail);
      }

      window.location.href = `${basePath}${relativePath}${search.toString() && `?${search}`}`;
    } else {
      history.push(relativePath);
    }
  }, [isDashboard, isNewUser]);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  useEffect(() => {
    if (rewardToScroll) {
      scrollToReward(rewardToScroll);
    }
  }, [rewardToScroll]);

  useEffect(() => {
    loadRewards();
    rewardsConfig();
    return () => timeoutRef?.current && clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div id={ANCHOR_REWARDS} className="Rewards__main">
      <div className="Rewards__main--container">
        <TitleSection
          pretitle="TrashieCash™"
          title="Use TrashieCash™"
          subtitle="Drop alert! Check this section weekly for updated deals and exclusive offers from a growing list of brands."
        />
        {hasLoaded && Boolean(listData.length) && (
          <>
            <RewardsList
              rewardsList={listData}
              onClick={handleOnClick}
              rewardProperties={rewardProperties}
            />
            {listData.length < totalItems && (
              <Button
                className="Rewards__main--load-more"
                type={BUTTON_TYPE.SECONDARY}
                size={BUTTON_SIZE.LARGE}
                onClick={handleLoadMore}
              >
                Load more rewards
              </Button>
            )}
          </>
        )}
        {hasLoaded && !listData.length && (
          <div className="noData">
            <img className="noData__image" src={CycloneIcon} alt="cyclone icon" />
            <span className="noData__message">
              We’ll keep looking for rewards!
            </span>
          </div>
        )}
        {(isLoading || isLoadingConfig) && <LoadingBar />}
      </div>
    </div>
  );
};

export default Rewards;
