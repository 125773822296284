/* eslint-disable no-debugger */
/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect, useHistory } from 'react-router-dom';

import {
  EMAIL_SEARCH_PARAM,
  PROMO_ID_PARAM,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  TYPE_SEARCH_PARAM,
} from '../../../config/routes';

import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import { getSearchParam, isRedeemPath } from '../../../utils/routes';

const PrivateRoute = ({
  component: Component,
  componentProps = {},
  ...rest
}) => {
  const { isSignedIn, isLoading } = useFirebaseAuth();
  const { location } = useHistory();

  if (isLoading) {
    return null;
  }

  // Handle deep link
  if (!isSignedIn) {
    // Store the route the user tried to visit
    // Only handle if the current location is not the login page or register flow
    if (
      location.pathname !== ROUTE_LOGIN &&
      location.pathname !== ROUTE_LOGOUT &&
      !isRedeemPath(location.pathname)
    ) {
      const search = new URLSearchParams(location.search);
      // Set login search params
      const loginSearch = new URLSearchParams({});
      const typeParam = getSearchParam(search, TYPE_SEARCH_PARAM);
      const emailParam = getSearchParam(search, EMAIL_SEARCH_PARAM);
      const promoParam = getSearchParam(search, PROMO_ID_PARAM);
      if (typeParam) {
        loginSearch.set(TYPE_SEARCH_PARAM, typeParam);
      }
      if (emailParam) {
        loginSearch.set(EMAIL_SEARCH_PARAM, emailParam);
      }
      if (promoParam) {
        loginSearch.set(PROMO_ID_PARAM, promoParam);
      }
      // Remove login search params for redirect
      search.delete(TYPE_SEARCH_PARAM);
      search.delete(EMAIL_SEARCH_PARAM);
      search.delete(PROMO_ID_PARAM);

      return (
        <Redirect
          to={{
            pathname: ROUTE_LOGIN,
            search: loginSearch.toString(),
            state: {
              from: {
                ...location,
                search: search.toString(),
              },
            },
          }}
        />
      );
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        (isSignedIn && !isLoading) ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect to={ROUTE_LOGIN} />
        )
      )}
    />
  );
};

export default PrivateRoute;
