import React, { useState, useRef } from 'react';

import style from './InputForm.module.scss';

const {
  formItem,
  formItemLabel,
  formItemElement,
  active,
  error,
  formItemInput,
  formItemError,
  password,
  inputClassContainer,
} = style;

const InputForm = ({
  type,
  placeholder,
  label,
  disabled,
  onChange,
  errorMessage,
  onFocus,
  onBlur,
  required,
  name,
  id,
  value,
  classes,
  containerClassName,
  preinput, // pre and post are intended to receive any jsx element to enhance the component
  postinput,
  propRef,
  useInputValidations = false,
}) => {
  const [err, setError] = useState(null);
  const inputRef = useRef();
  const validateInput = (val) => {
    switch (type) {
      case 'email':
        // eslint-disable-next-line no-case-declarations
        const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$/;
        if (val && !emailRegex.test(val)) {
          setError('Invalid email format');
        } else {
          setError(null);
        }
        break;
      case 'password':
        if (val && val.length < 8) {
          setError('Password must be at least 8 characters');
        } else {
          setError(null);
        }
        break;
      case 'name':
        if (val && val.length < 2) {
          setError('Name must be at least 2 characters');
        } else {
          setError(null);
        }
        break;
      case 'postal':
        // eslint-disable-next-line no-case-declarations
        const postalRegex = /^[0-9]{5}$/;
        if (val && !postalRegex.test(val)) {
          setError('Invalid postal code');
        } else {
          setError(null);
        }
        break;
      default:
        if (!val && required) {
          setError('This field is required');
        } else if ((val && required) || !required) {
          setError(null);
        }
    }
  };

  const [focused, setFocused] = useState(false);

  const handleOnChange = (e) => {
    if (useInputValidations) {
      validateInput(inputRef.current?.value);
    }

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  const handleOnFocus = () => {
    setFocused(true);
    setError(null);

    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const handleOnBlur = () => {
    setFocused(false);
    if (useInputValidations) {
      validateInput(inputRef.current?.value);
    }

    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  return (
    <div className={`${inputClassContainer} ${classes || ''}`}>
      {preinput}
      <div className={`${formItem} ${containerClassName ?? ''}`}>
        {label && (
          <p className={formItemLabel}>
            {label}
          </p>
        )}
        <div className={`${formItemElement} ${focused ? active : ''} ${errorMessage ? error : ''}`}>
          <input
            type={type}
            id={id}
            className={`${formItemInput} ${type === 'password' ? password : ''}`}
            placeholder={placeholder}
            value={value}
            name={name}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            disabled={disabled}
            ref={propRef || inputRef}
          />
        </div>
        {errorMessage && typeof errorMessage === 'string' && (
          <p className={formItemError}>
            {errorMessage}
          </p>
        )}
        {(useInputValidations && err) && typeof err === 'string' && (
          <p className={formItemError}>
            {err}
          </p>
        )}
      </div>
      {postinput}
    </div>
  );
};

export default InputForm;
