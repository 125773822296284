export const getNextButtonStyles = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    background: registerTbbFlow?.nextButton?.bgColor,
    color: registerTbbFlow?.nextButton?.textColor,
    border: registerTbbFlow?.nextButton?.bgColor,
  };
};

export const getOptionButtonStyles = (storeConfig, border = false) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    color: registerTbbFlow?.nextButton?.bgColor,
    ...(border && { borderColor: registerTbbFlow?.nextButton?.bgColor }),
  };
};

export const REDEMPTION_CODE_STATUS_IDS = {
  CLAIMED: 1,
  RETURNED: 2,
  PENDING: 3,
  BLOCKED: 4,
};

export const getCouponStyle = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    color: registerTbbFlow.rewardStep?.cardTextColor,
    background: registerTbbFlow.rewardStep?.cardBgColor,
    border: 'none',
  };
};
