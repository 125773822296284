import classNames from 'classnames';
import CopyAlt from '../../images/copy-alt.svg';

import './CopyButton.scss';

const CopyButton = ({
  className,
  data,
  copied,
  onClick,
  trackerProps = {},
}) => (
  <button
    type="button"
    className={classNames('CopyButton', className)}
    onClick={onClick}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...trackerProps}
  >
    {data && (
      <p className="CopyButton__data">{data}</p>
    )}
    {onClick && (
      <span className="CopyButton__button">
        <img src={CopyAlt} alt="copy icon" />
      </span>
    )}
    {copied && (
      <p className="CopyButton__copied">Copied!</p>
    )}
  </button>
);

export default CopyButton;
