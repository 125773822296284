import { REWARD_TERMS_PREFIX,
  REWARD_TEMS_PREFIX_2ND,
  REWARD_TEMS_PREFIX_3RD,
} from '../../../config/rewards';

import './Advice.scss';

const Advice = () => (
  <div className="advice-container">
    Please also consider:
    <ul>
      <li>{REWARD_TERMS_PREFIX}</li>
      <li>{REWARD_TEMS_PREFIX_2ND}</li>
      <li>
        {REWARD_TEMS_PREFIX_3RD}
      </li>
    </ul>
  </div>
);

export default Advice;
