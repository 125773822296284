import { useState } from 'react';
import classNames from 'classnames';

import style from './Input.module.scss';

const {
  formItem,
  formItemLabel,
  formItemElement,
  active,
  error,
  formItemInput,
  formItemError,
  password,
} = style;

const Input = ({
  type = 'text',
  id,
  containerClassName,
  className,
  elementClassName,
  label,
  placeholder,
  value,
  errorMessage,
  onFocus,
  onBlur,
  onChange,
}) => {
  const [focused, setFocused] = useState(false);

  const handleOnChange = (event) => {
    const {
      target: {
        value: newValue,
      },
    } = event;

    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const handleOnFocus = () => {
    setFocused(true);

    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const handleOnBlur = () => {
    setFocused(false);

    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  return (
    <div className={classNames(formItem, containerClassName)}>
      {label && (
        <p className={formItemLabel}>
          {label}
        </p>
      )}
      <div className={classNames(formItemElement, className, {
        [active]: focused, [error]: errorMessage,
      })}
      >
        <input
          type={type}
          id={id}
          className={classNames(formItemInput, elementClassName, { [password]: type === 'password' })}
          placeholder={placeholder}
          value={value}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
      </div>
      {errorMessage && typeof errorMessage === 'string' && (
        <p className={formItemError}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Input;
