import * as React from 'react';
import {
  Image,
  Carousel,
} from 'react-bootstrap';
import Style from './TbbRewardPage.module.scss';
import Offer from '../../Offers/Offer';
import Chevron from '../../../images/chevron-up.svg';
import { isMobile } from '../../../../../hooks/useBreakPoint';

const {
  spendSection,
  title,
  offerCarousel,
  arrowRight,
  arrowLeft,
} = Style;

const TbbEarnMoreSection = ({ offersList }) => (
  <>
    <div
      className={spendSection}
      style={{
        paddingBottom: '56px',
      }}
    >
      <p
        className={title}
      >
        Earn more TrashieCash™ when you
        <br />
        shop the brands below:
      </p>
      {
          isMobile() ? (
            <div className={offerCarousel}>
              <Carousel
                slide
                controls
                indicators={false}
                style={{
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                prevIcon={(
                  <Image
                    style={{
                      marginLeft: '8px',
                    }}
                    className={arrowLeft}
                    src={Chevron}
                    alt="brands-image"
                  />
            )}
                nextIcon={(
                  <Image
                    style={{
                      marginLeft: '8px',
                    }}
                    className={arrowRight}
                    src={Chevron}
                    alt="brands-image"
                  />
            )}
              >
                {offersList?.map(offerItem => (
                  <Carousel.Item style={{ width: '100%' }}>
                    <Offer key={`offer-${offerItem.merchantId}`} offerItem={offerItem} />
                  </Carousel.Item>
                ))}
              </Carousel>

            </div>
          ) : (
            <div style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
              gap: '30px',
              width: '100%',
            }}
            >
              {offersList?.map(offerItem => (
                <Offer key={`offer-${offerItem.merchantId}`} offerItem={offerItem} />
              ))}
            </div>
          )
        }
    </div>

    {/* <Button className={viewAllButton} type="button">View all</Button> */}
  </>

);

export default TbbEarnMoreSection;
