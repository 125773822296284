import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_MY_ACCOUNT, ROUTE_SETTINGS } from '../../config/routes';
import Form from '../../global/Form';
import Button, { BUTTON_TYPE } from '../../global/Button/Button';
import { formatTransactionDate } from '../../utils/dates';
import { getGenericError } from '../../utils/errors';
import { getUserName } from '../../utils/utils';
import { updateUser } from '../../utils/service';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../../providers/AlertProvider';
import Loader from '../../../global/Loader';
import ListHeaders from '../../global/ListHeaders';
import { isMobile } from '../../../../hooks/useBreakPoint';

import './Profile.scss';

const Profile = () => {
  const { userData: fbUserData, isLoading: isLoadingFirebaseAuth } = useFirebaseAuth();
  const history = useHistory();
  const setAlert = useAlert();
  const mobile = isMobile();

  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);

  const sections = useMemo(() => [
    {
      id: 'userDetails',
      title: 'Stay up to date so we can get you the best deals',
      rows: [
        {
          id: 'firstName',
          title: '',
          inputs: [
            {
              id: 'firstName',
              name: 'firstName',
              label: 'First Name',
              type: 'name',
              required: true,
              defaultValue: userData.firstName ?? '',
            },
          ],
        },
        {
          id: 'lastName',
          title: '',
          inputs: [
            {
              id: 'lastName',
              name: 'lastName',
              label: 'Last Name',
              type: 'name',
              required: true,
              defaultValue: userData.lastName ?? '',
            },
          ],
        },
      ],
    },
  ], [userData.firstName, userData.lastName]);

  const sendProfile = useCallback(async (formState) => {
    setIsLoading(true);

    const {
      data: updateUserData,
      error: updateUserError,
    } = await updateUser(fbUserData.uid, formState);

    if (updateUserError) {
      setError(updateUserError);
      setIsLoading(false);
    } else {
      setUserData(updateUserData);
      setError('');
      history.go(0);
    }
  }, [fbUserData]);

  const handleSubmit = useCallback((formState) => {
    sendProfile(formState);
  }, []);

  const handlePasswordClick = useCallback(() => {
    history.push(ROUTE_SETTINGS);
  }, []);

  useEffect(() => {
    if (isLoadingFirebaseAuth) {
      return;
    }

    if (fbUserData?.error) {
      setAlert({
        type: 'notification',
        message: getGenericError(),
        error,
      });
      setUserData({});
    } else {
      setUserData({ ...fbUserData });
    }

    setHasLoaded(true);
  }, [fbUserData, isLoadingFirebaseAuth]);

  return (
    <div className="Profile">
      <div className="Profile__content">
        <div className="container">
          {isLoading || isLoadingFirebaseAuth || !hasLoaded ? (
            <Loader />
          ) : (
            <>
              {!mobile && (
                <ListHeaders returnActionRoute={ROUTE_MY_ACCOUNT} />
              )}
              <Form
                sections={sections}
                formName="profile"
                formTitle={getUserName(userData)}
                formSubtitle={`Member Since: ${formatTransactionDate(userData.memberSince)}`}
                formButtonText="Update"
                formButtonType={BUTTON_TYPE.PRIMARY}
                formButtonAction={handleSubmit}
              />
              <span className="separator" />
              <div className="password">
                <span className="password__title">PASSWORD</span>
                <Button
                  type={BUTTON_TYPE.SECONDARY}
                  onClick={handlePasswordClick}
                >
                  Change your Password
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
