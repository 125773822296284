/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import { useFirebaseAuth } from '../../FirebaseProvider/FirebaseProvider';

const PublicRoute = ({
  component: Component,
  restricted,
  render: customRender,
  ...rest
}) => {
  const { isSignedIn } = useFirebaseAuth();
  return (
    <Route
      {...rest}
      render={(props) => (
        customRender
          ? customRender(props.location)
          : (isSignedIn && restricted && (
            <Redirect to="/wallet" />
          )) || <Component {...props} />
      )}
    />
  );
};
export default PublicRoute;
