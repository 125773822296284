import TakeBackBagsListItem from './TakeBackBagsListItem';

import './TakeBackBagsList.scss';

const TakeBackBagsList = ({ tbbList, style }) => (
  <div className="TakeBackBagsList" style={{ ...style }}>
    {tbbList.map((tbbItem) => (
      <TakeBackBagsListItem
        key={`tbb-${tbbItem.id}`}
        tbbItem={tbbItem}
      />
    ))}
  </div>
);

export default TakeBackBagsList;
