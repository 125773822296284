import * as React from 'react';
import {
  Image,
  Carousel,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Chevron from '../../../images/chevron-up.svg';

import Style from './TbbRewardPage.module.scss';

const {
  carouselContainer,
  carouselImage,
  shopNowButton,
  spendSection,
  carouselItem,
  productDescription,
  productTitle,
  arrowRight,
  arrowLeft,
} = Style;

const TbbSpendSection = ({ storeConfig }) => {
  const buyAtShop = storeConfig?.registerTbbFlow?.rewardStep?.buyAtShop;

  return (
    <div className={spendSection}>
      <Carousel
        slide
        className={carouselContainer}
        indicators={false}
        prevIcon={buyAtShop?.products.length > 1 && (
        <Image
          style={{
            paddingLeft: '8px',
          }}
          className={arrowLeft}
          src={Chevron}
          alt="brands-image"
        />
        )}
        nextIcon={buyAtShop?.products.length > 1 && (
        <Image
          style={{
            paddingRight: '8px',
          }}
          className={arrowRight}
          src={Chevron}
          alt="brands-image"
        />
        )}
      >
        {buyAtShop?.products?.map(product => (
          <Carousel.Item>
            <div className={carouselItem}>
              <Image
                className={carouselImage}
                src={product.imageUrl}
                alt="product-image"
                onClick={() => window.open(storeConfig?.shopRedirect, '_blank')}
              />
              {product.name && (
                <p className={productTitle}>{product.name}</p>
              )}
              {product.description && (
                <p className={productDescription}>{product.description}</p>
              )}
              <Link
                to={{ pathname: storeConfig?.shopRedirect }}
                target="_blank"
                className={shopNowButton}
                style={{
                  background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
                  color: storeConfig.registerTbbFlow?.nextButton?.textColor,
                  borderColor: !storeConfig.isTrashie &&
                    storeConfig.registerTbbFlow?.nextButton?.bgColor,
                }}
              >
                SHOP NOW
              </Link>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>

  );
};

export default TbbSpendSection;
