import * as React from 'react';
import Card from '@mui/material/Card';
import { styled } from '@mui/material';

const StyledCard = styled((props) => <Card {...props} />)({
  'border-radius': '8px',
  background: 'var(--Trashie-UI-Crisp-White, #FCFFFD)',
  'box-shadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.09)',
  '&:hover': {
    cursor: 'pointer',
    border: '1px solid #A9A9A9',
    'box-shadow': '0px 4px 15px 0px #00000017',
  },
});

export default StyledCard;
