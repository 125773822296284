import { FormSelect } from 'react-bootstrap';
import './StateSelector.scss';
import { getUSStates } from '../../../utils/locations';

const StateSelector = ({
  selectedState,
  selectedStateCode,
  setSelectedState,
  setSelectedStateCode,
}) => {
  const selectedOption = JSON.stringify({ name: selectedState, code: selectedStateCode });
  return (
    <div className="state-select-container">
      <FormSelect
        className="state-select"
        id="state"
        label="State*"
        value={selectedOption}
        defaultValue={selectedOption}
        onChange={({ target }) => {
          const { name, code } = JSON.parse(target.value);
          setSelectedState(name);
          setSelectedStateCode(code);
        }}
      >
        {getUSStates().map((state) => (
          <option className="state-select-option" key={state.code} value={JSON.stringify(state)}>
            {state.name}
          </option>
        ))}
      </FormSelect>
    </div>
  );
};

export default StateSelector;
