import style from './SortedBy.module.scss';

const { container } = style;
const SortedBy = ({ sortedText }) => (
  <div className={container}>
    <div>Sorted By:</div>
    <div>{sortedText}</div>
  </div>
);

export default SortedBy;
