import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import chevronUpCircleIcon from '../../images/chevron-up-circle-icon.svg';
import chevronDownCircleIcon from '../../images/chevron-down-circle-icon.svg';

import './CollapsableSection.scss';

export const ICON_SIZE = {
  DEFAULT: 'default',
  LARGE: 'large',
};

/*
  Uncontrolled:
    <CollapsableSection
      title={title}
      content={content}
    />

  Controlled:
    <CollapsableSection
      title={title}
      content={content}
      collapsed={collapsed}
      onChange={collapsed => handleOnChange(collapsed)}
    />
*/

const CollapsableSection = ({
  title,
  content,
  collapsed = true,
  onChange,
  className,
  contentMaxHeight = 1000,
  iconSize = ICON_SIZE.DEFAULT,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const handleOnClick = useCallback(() => {
    setIsCollapsed(prev => {
      if (onChange) {
        onChange(!prev);
      }

      return !prev;
    });
  }, []);

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);

  return (
    <div className={classNames('CollapsableSection', className)}>
      <button
        type="button"
        className="CollapsableSection__title"
        onClick={handleOnClick}
      >
        {title}
        <img
          className={classNames('CollapsableSection__icon', iconSize)}
          src={isCollapsed ? chevronDownCircleIcon : chevronUpCircleIcon}
          alt={isCollapsed ? 'expand icon' : 'collapse icon'}
        />
      </button>
      <div
        className="CollapsableSection__content"
        style={{ maxHeight: `${isCollapsed ? 0 : contentMaxHeight}px` }}
      >
        {content}
      </div>
    </div>
  );
};

export default CollapsableSection;
