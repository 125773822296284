import classNames from 'classnames';

import './CardSection.scss';

const CardSection = ({ children, title, footer, classNameContainer }) => (
  <div className="CardSection">
    <div className={classNames('CardSection__container', classNameContainer)}>
      {title && (
      <div className="CardSection__container--title">
        {title}
      </div>
      )}
      <div className="CardSection__container--content">
        {children}
      </div>
    </div>
    {footer && (
      <div className="CardSection__footer">
        {footer}
      </div>
    )}
  </div>
);

export default CardSection;
