import EyeOff from '../SvgComponents/EyeOff_svg';
import EyeOn from '../SvgComponents/EyeOn_svg';

import './EyeToggle.scss';

const EyeToggle = ({
  onClick,
  isVisible = true,
  disabled = true,
}) => (
  <div className="EyeToggle">
    <button
      type="button"
      className="EyeToggle__button"
      disabled={disabled}
      onClick={onClick}
    >
      {isVisible && !disabled ? (
        <EyeOff />
      ) : (
        <EyeOn stroke={!disabled ? '#000' : '#D9D9D9'} />
      )}
    </button>
  </div>
);

export default EyeToggle;
