import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { FORDAYS_STORE_ID } from '../../config/config';
import { REWARD_AMOUNT_TYPES } from '../../config/rewards';
import {
  REWARD_ID_PARAM,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_REWARD_INFO,
  ROUTE_DEAL_INFO,
  ROUTE_REWARDS,
  STORE_ID_PARAM,
  WF_RETURN_URL_SEARCH_PARAM,
  ROUTE_DEALS,
} from '../../config/routes';
import {
  getActionType,
  getEventName,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
} from '../../config/tracker';
import {
  getRewardsSelectorTitle,
  getRewardsValidated,
  isFundsErrorCode,
  isLimitErrorCode,
  isNotFoundErrorCode,
  isUserLimitErrorCode,
  getRewardsAllStatus,
  getRewardStatusSubtitle,
} from '../../utils/rewards';
import { getSearchParam } from '../../utils/routes';
import { getStoreRewards, patchReward, getConfigurations } from '../../utils/service';
import ArrowBack from '../../images/circle-arrow-left-gray.svg';
import LoadingBar from '../../global/LoadingBar';
import GeneralModal from '../../global/GeneralModal';
import StickyButton from '../../global/StickyButton/StickyButton';
import {
  RewardContentError,
  RewardContentLoading,
  RewardContentConfirm,
  RewardContentUnavailable,
  RewardContentMerchantMain,
  RewardContentTerms,
  RewardContentFinal,
} from './RewardContent';
import { CONTENT_TERMS_TYPE } from './RewardContent/RewardContentTerms';
import { isMobile } from '../../../../hooks/useBreakPoint';
import RedeemModal from './RedeemModal';

import './Reward.scss';

const {
  rewards: {
    singleMerchantPage: {
      confirmButton: trackerConfirmButton,
    },
  },
  myRewards: {
    detail: {
      shopButton: trackerShopButton,
    },
  },
} = TRACKER_IDS;

const MAIN_ELEMENT_ID = 'Reward';

const VIEW_STATE = {
  LOADING: 'loading',
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  FINAL: 'final',
  ERROR: 'error',
  TERMS: 'terms',
};

const MODAL_STATE = {
  LOADING: 'loading',
  CONFIRM: 'confirm',
  TERMS: 'terms',
  TERMS_FINAL: 'terms-final',
};

const Reward = ({
  isDeal,
}) => {
  const { userData, isLoading: isLoadingFirebaseAuth, isSignedIn, user } = useFirebaseAuth();
  const posthog = usePostHog();
  const {
    [STORE_ID_PARAM]: storeId,
    [REWARD_ID_PARAM]: rewardId,
  } = useParams();
  const history = useHistory();
  const timeoutRef = useRef(null);
  const { search, pathname } = useLocation();
  const mobile = isMobile();

  const [store, setStore] = useState({});
  const query = new URLSearchParams(search);
  const [rewards, setRewards] = useState([]);
  const [rewardItem, setRewardItem] = useState({});
  const [currentBalance, setCurrentBalance] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [viewState, setViewState] = useState(VIEW_STATE.LOADING);
  const [modalState, setModalState] = useState(MODAL_STATE.LOADING);
  const [rewardsValidatedState, setRewardsValidatedState] = useState({});
  const [errorId, setErrorId] = useState(null);
  const [areAllRewardsDisabled, setAreAllRewardsDisabled] = useState(false);
  const wfReturnUrl = getSearchParam(query, WF_RETURN_URL_SEARCH_PARAM);
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);

  const typeText = useMemo(() => (isDeal ? 'DEAL' : 'REWARD'), [isDeal]);

  const isDiscountCodeStore = useMemo(() => (
    storeId === FORDAYS_STORE_ID
  ), [storeId]);

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const handleOpen = useCallback(() => {
    if (openRedeemModal) {
      return;
    }

    if (isDeal) {
      setOpenRedeemModal(true);

      posthog.capture(
        getEventName(trackerConfirmButton),
        {
          [POSTHOG_PROPERTIES.REWARD_ID]: rewardItem.uid,
          [POSTHOG_PROPERTIES.REWARD_NAME]: rewardItem.name,
          ...(isSignedIn && user && ({
            $set: { email: user.email },
          })),
        },
      );

      return;
    }

    setModalState(MODAL_STATE.CONFIRM);
    toggleModal();
  }, [isDeal, openRedeemModal, rewardItem, isSignedIn, user]);

  const handleOpenTerms = useCallback((isFinal) => {
    setModalState(isFinal ? MODAL_STATE.TERMS_FINAL : MODAL_STATE.TERMS);
    toggleModal();
  }, []);

  const handleClose = useCallback(() => {
    setModalState(MODAL_STATE.LOADING);
    toggleModal();
  }, []);

  const handleSelect = useCallback((selectedRewardId) => {
    const selectedReward = rewards.find(({ uid }) => uid === selectedRewardId) ?? {};

    setRewardItem({
      ...selectedReward,
      store,
    });
  }, [rewards, store]);

  const scrollToTop = useCallback(() => {
    const mainElement = document.getElementById(MAIN_ELEMENT_ID).parentElement;

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  const loadRewards = useCallback(async () => {
    setViewState(VIEW_STATE.LOADING);

    const {
      data,
      error: storeRewardsError,
    } = await getStoreRewards(storeId);

    if (storeRewardsError) {
      setStore({});
      setRewards([]);
      setRewardItem({});
      setCurrentBalance(0);
      setErrorId(null);
      setViewState(VIEW_STATE.ERROR);
      return;
    }

    const {
      rewards: rewardsData,
      store: storeData,
    } = data;

    const {
      balance: {
        currentBalance: currentBalanceData = 0,
      },
    } = userData;

    const {
      rewardsValidated,
      validatedState,
    } = getRewardsValidated(rewardsData, currentBalanceData);

    setAreAllRewardsDisabled(validatedState.allDisabled);
    // Start Define selected reward item
    let selectedRewardItem;
    // Check for rewardId provided in URL
    if (rewardId) {
      selectedRewardItem = rewardsValidated.find(({ uid, disabled }) => (
        uid === rewardId && !disabled
      ));
    }
    // Or select default first not disabled
    if (!selectedRewardItem) {
      selectedRewardItem = validatedState.allDisabled ?
        rewardsValidated[0] ?? {} : rewardsValidated.find(({ disabled }) => !disabled) ?? {};
    }
    // End Define selected reward item

    setStore(storeData);
    setRewards(rewardsValidated);
    setRewardItem({
      ...selectedRewardItem,
      store: storeData,
    });
    setCurrentBalance(currentBalanceData);
    setErrorId(null);
    setViewState(rewardsValidated.length === 0 ? VIEW_STATE.ERROR : VIEW_STATE.AVAILABLE);
    setRewardsValidatedState(validatedState);

    scrollToTop();
  }, [storeId, rewardId, userData, isDiscountCodeStore]);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  const redeemReward = useCallback(async () => {
    setModalState(MODAL_STATE.LOADING);

    const { data: rewardData, error } = await patchReward(rewardItem.uid, wfReturnUrl);

    if (error) {
      if (isFundsErrorCode(error.code)) {
        setViewState(VIEW_STATE.UNAVAILABLE);
      } else if (
        isLimitErrorCode(error.code) ||
        isNotFoundErrorCode(error.code) ||
        isUserLimitErrorCode(error.code)
      ) {
        setErrorId(error.code);
        setViewState(VIEW_STATE.ERROR);
      } else {
        setErrorId(null);
        setViewState(VIEW_STATE.ERROR);
      }

      setRewardItem({});
      toggleModal();
      scrollToTop();
      return;
    }

    posthog.capture(
      getEventName(trackerConfirmButton),
      {
        [POSTHOG_PROPERTIES.REWARD_ID]: rewardItem.uid,
        [POSTHOG_PROPERTIES.REWARD_NAME]: rewardItem.name,
        ...(isSignedIn && user && ({
          $set: { email: user.email },
        })),
      },
    );

    const {
      rewardCode: {
        uid: rewardCodeUid,
      },
    } = rewardData;

    let newURL = (isDeal ? ROUTE_DEAL_INFO : ROUTE_REWARD_INFO)
      .replace(REWARD_ID_ROUTE_PARAM, rewardCodeUid);

    if (wfReturnUrl) {
      newURL += `?${WF_RETURN_URL_SEARCH_PARAM}=${wfReturnUrl}`;
    }

    history.push(newURL);
  }, [rewardItem, isSignedIn, user]);

  const getRewardContent = useCallback(state => {
    switch (state) {
      case VIEW_STATE.ERROR:
        return (
          <RewardContentError
            errorId={errorId}
            onBack={handleClose}
            isDeal={isDeal}
          />
        );
      case VIEW_STATE.UNAVAILABLE:
        return (
          <RewardContentUnavailable
            value={parseFloat(rewardItem.exchangeValue) - parseFloat(currentBalance)}
          />
        );
      case VIEW_STATE.AVAILABLE:
        return (
          <RewardContentMerchantMain
            rewards={rewards}
            rewardItem={rewardItem}
            rewardsAllStatus={getRewardsAllStatus(rewardsValidatedState)}
            selectorTitle={getRewardsSelectorTitle(
              rewardsValidatedState,
              rewards.length,
            )}
            onSelect={handleSelect}
            onSubmit={handleOpen}
            onShowTerms={handleOpenTerms}
            isDiscountCode={isDiscountCodeStore}
            currentBalance={currentBalance}
            submitDisabled={areAllRewardsDisabled}
            isDeal={isDeal}
            rewardProperties={rewardProperties}
          />
        );
      case MODAL_STATE.CONFIRM:
        return (
          <RewardContentConfirm
            rewardItem={rewardItem}
            onSubmit={redeemReward}
            onClose={handleClose}
            rewardProperties={rewardProperties}
          />
        );
      case VIEW_STATE.FINAL:
        return (
          <RewardContentFinal
            rewardItem={rewardItem}
            onShowTerms={() => handleOpenTerms(true)}
            isFinalStep
            isDeal={isDeal}
          />
        );
      case VIEW_STATE.TERMS:
      case MODAL_STATE.TERMS:
        return (
          <RewardContentTerms
            rewardItem={rewardItem}
            onClose={handleClose}
          />
        );
      case MODAL_STATE.TERMS_FINAL:
        return (
          <RewardContentTerms
            rewardItem={rewardItem}
            onClose={handleClose}
            type={CONTENT_TERMS_TYPE.FINAL}
          />
        );
      case MODAL_STATE.LOADING:
        return (
          <RewardContentLoading rewardItem={rewardItem} />
        );
      case VIEW_STATE.LOADING:
      default:
        return <LoadingBar />;
    }
  }, [
    modalState,
    rewards,
    rewardItem,
    currentBalance,
    handleClose,
    handleSelect,
    handleOpen,
    redeemReward,
    errorId,
    handleOpenTerms,
    rewardsValidatedState,
    isDiscountCodeStore,
    areAllRewardsDisabled,
  ]);

  const buttonLabelSelector = () => {
    if (areAllRewardsDisabled) {
      return `Browse all ${typeText.toLowerCase()}s`;
    }
    if (viewState === VIEW_STATE.FINAL) {
      return 'SHOP NOW';
    }
    return `GET THIS ${typeText}`;
  };

  const handleStickyOnClick = () => {
    if (areAllRewardsDisabled) {
      return () => { history.push(ROUTE_REWARDS); };
    }
    if (viewState === VIEW_STATE.FINAL) {
      return () => { window.location.href = rewardItem?.affiliateLink; };
    }
    return handleOpen;
  };

  const stickySectionSubtitle = () => {
    if (areAllRewardsDisabled) {
      return getRewardStatusSubtitle(rewardItem?.status);
    }
    if (viewState === VIEW_STATE.FINAL) {
      return null;
    }
    return REWARD_AMOUNT_TYPES[rewardItem?.amountType]?.getText(rewardItem?.rewardAmount, true);
  };

  const handleBackToUrl = useCallback(() => {
    if (pathname.includes(ROUTE_DEALS)) {
      history.push(ROUTE_DEALS);
    } else {
      history.push(ROUTE_REWARDS);
    }
  }, []);

  useEffect(() => {
    if (!isLoadingFirebaseAuth && userData && storeId) {
      loadRewards();
      rewardsConfig();
    }
  }, [userData, isLoadingFirebaseAuth, storeId]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div id={MAIN_ELEMENT_ID} className="Reward">
      <div className="Reward__content">
        <div className="Reward__content--container">
          {!mobile && (
            <button
              className="Reward__content--button"
              type="button"
              onClick={() => (
                history.length > 1 ? history.goBack() : handleBackToUrl()
              )}
            >
              <img src={ArrowBack} alt="arrow-back" />
              {`GO TO ALL ${typeText}S`}
            </button>
          )}
          <div className="Reward__content--section">
            {viewState === VIEW_STATE.LOADING || isLoadingConfig ? (
              <LoadingBar className="Reward__content--loader" />
            ) : (
              getRewardContent(viewState)
            )}
          </div>
        </div>
      </div>
      {viewState !== VIEW_STATE.LOADING && viewState !== VIEW_STATE.ERROR && (
      <StickyButton
        buttonLabel={buttonLabelSelector()}
        onSubmit={handleStickyOnClick()}
        title={viewState !== VIEW_STATE.FINAL &&
          !rewardsValidatedState.notEnoughCash ? rewardItem?.store?.title : null}
        subTitle={stickySectionSubtitle()}
        showDescription={viewState !== VIEW_STATE.FINAL}
        typeSecondary={areAllRewardsDisabled}
        disabled={!areAllRewardsDisabled && rewardItem.disabled}
        trackerProps={{
          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
          [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
        }}
      />
      )}
      <GeneralModal
        onClose={toggleModal}
        showModal={showModal}
        showModalHeader={false}
        showModalFooter={false}
        canClose={modalState !== MODAL_STATE.LOADING}
        showClose={modalState !== MODAL_STATE.LOADING}
      >
        {getRewardContent(modalState)}
      </GeneralModal>
      <RedeemModal
        rewardItem={rewardItem}
        showModal={openRedeemModal}
        onClose={() => setOpenRedeemModal(false)}
      />
    </div>
  );
};

export default Reward;
