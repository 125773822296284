import { Image } from 'react-bootstrap';
import classNames from 'classnames';

import LoadingBar from '../../../global/LoadingBar';
import TrashieHeart from '../../../images/red-heart-icon.svg';

import './RewardContent.scss';
import { REWARD_AMOUNT_TYPES } from '../../../config/rewards';

const ModalContentLoading = ({
  rewardItem,
  isDeal,
}) => (
  <div className={classNames('body-style loading', { isDeal })}>
    <div className="body-top">
      <div className="trashie-logo">
        <Image
          className="trashie-coin-logo"
          src={TrashieHeart}
          alt="trashie-image"
        />
      </div>
      <div className="bold-title">
        doing trashie
        things
      </div>
      <LoadingBar />
      <div className="loading-desc">
        {'GETTING YOUR '}
        <br />
        {REWARD_AMOUNT_TYPES[rewardItem?.amountType]?.getText(rewardItem?.rewardAmount)}
        {` at ${rewardItem?.store?.title}`}
      </div>
    </div>
  </div>
);

export default ModalContentLoading;
