import classNames from 'classnames';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../global/Button';
import LoadingBar from '../../../global/LoadingBar';
import ChevronUpSvg from '../../../global/SvgComponents/ChevronUp_svg';

import './DashboardNewSection.scss';

const DashboardNewSection = ({
  anchor = '',
  title = '',
  subTitle = '',
  onClick = () => {},
  isLoading,
  error = '',
  noData = '',
  ctaTrackerProps = {},
  children,
  contained,
}) => (
  <div id={anchor} className="DashboardNewSection">
    <div className={classNames('DashboardNewSection__container', { contained })}>
      <div className={classNames('DashboardNewSection__container--top', { contained })}>
        <div className="DashboardNewSection__container--top-text">
          <h2 className="DashboardNewSection__container--top-text-title">{title}</h2>
          {subTitle && (
            <p className="DashboardNewSection__container--top-text-subTitle">{subTitle}</p>
          )}
        </div>
        <Button
          size={BUTTON_SIZE.LARGE}
          type={BUTTON_TYPE.LINK_QUINARY}
          onClick={onClick}
          className="DashboardNewSection__container--top-cta"
          trackerProps={ctaTrackerProps}
        >
          <span>VIEW ALL</span>
          <ChevronUpSvg color="#3333FF" height={14} />
        </Button>
      </div>
      <div className="DashboardNewSection__container--content">
        {isLoading ? (
          <LoadingBar className="DashboardNewSection__container--loader" />
        ) : (
          error || noData || children
        )}
      </div>
    </div>
  </div>
);

export default DashboardNewSection;
