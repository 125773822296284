import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

export const SIZE_XS = 'xs';
export const SIZE_SM = 'sm';
export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';
export const SIZE_XL = 'xl';
export const SIZE_XXL = 'xxl';

const resolveBreakpoint = (width) => {
  if (width < 576) {
    return SIZE_XS;
  } if (width >= 576 && width < 768) {
    return SIZE_SM;
  } if (width >= 768 && width < 992) {
    return SIZE_MD;
  } if (width >= 992 && width < 1200) {
    return SIZE_LG;
  } if (width >= 1200 && width < 1440) {
    return SIZE_XL;
  }
  return SIZE_XXL;
};

/** Get Media Query Breakpoints in React */
export const useBreakpoint = () => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = debounce(() => {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return size;
};

export const isMobile = (sizes = []) => {
  const size = useBreakpoint();
  return (sizes.length ? sizes : [SIZE_XS, SIZE_SM, SIZE_MD]).includes(size);
};

export default useBreakpoint;
