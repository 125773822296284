import {
  TransactionStatusTypes,
} from '../config/transactions';

const isPaidStatusType = (statusType) => (
  statusType &&
  statusType === TransactionStatusTypes.TRANSACTION_PAID
);

export default isPaidStatusType;
