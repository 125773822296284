import { useMemo } from 'react';
import classNames from 'classnames';

import Card from '../../../../../global/Mui/Card';
import ShippingProviderLocations from '../ShippingProviderLocations';

import './ShippingProviderSelectItem.scss';

function ShippingProviderSelectItem({
  index,
  shippingProvider,
  selectedIndex,
  address,
  onSelect,
  storeConfig,
}) {
  const {
    promotion,
    logoURL,
    name,
    description,
    configuration,
  } = useMemo(() => shippingProvider, [shippingProvider]);

  const isCurrentItemSelected = useMemo(() => (index === selectedIndex), [index, selectedIndex]);

  return (
    <>
      <Card
        onClick={() => onSelect(index, name)}
        className={classNames('ShippingProviderSelectItem', {
          selectedCardContainer: isCurrentItemSelected,
        })}
      >
        <div className="ShippingProviderSelectItem__header">
          <img src={logoURL} alt={`${name} logo`} className="shippingProviderImage" />
          <div className="shippingProviderDescriptionContainer">
            <div className="shipingProviderNameContainer">
              <p className="shipingProviderNameText">
                {description}
              </p>
              <input
                type="radio"
                className="radioButton"
                readOnly
                checked={isCurrentItemSelected}
                style={{
                  ...(isCurrentItemSelected && !storeConfig?.isTrashie && {
                    backgroundColor: storeConfig?.registerTbbFlow?.nextButton?.bgColor,
                  }),
                }}
              />
            </div>
            {promotion && (
              <p className="shipingProviderPromotionText">
                {`Earn ${promotion.amount} TrashieCash™* with ${name}`}
              </p>
            )}
          </div>
        </div>
        {configuration?.details && (
          <div className="ShippingProviderSelectItem__details">
            {configuration?.details}
          </div>
        )}
        {configuration?.locations?.enabled && (
          <ShippingProviderLocations
            locations={configuration.locations}
            address={address}
          />
        )}
      </Card>
      {promotion && (
        <p className="shipingProviderPromotionnNote">
          * Gets awarded upon bag registration.
        </p>
      )}
    </>
  );
}

export default ShippingProviderSelectItem;
