import { useCallback, useState } from 'react';
import {
  Image,
} from 'react-bootstrap';
import tCoinLogoBlack from '../../../images/t-coin-logo-black.svg';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../Button';
import SquareLink from '../../../images/square-arrow-out-up-right-blue.svg';
import { getCouponStyle, getOptionButtonStyles } from '../../../utils/tbbRegistration';

import Style from './TbbRewardPage.module.scss';

const {
  balance,
  cardContainer,
  cardIconContainer,
  cardIcon,
  cardUserName,
  cardDescription,
  cardBalanceContainer,
  cardTopSection,
  cardBottomSection,
  couponContainer,
  couponSection,
  ctaLink,
} = Style;

const REWARD_LINK_RULE = 'rewardLink';

const TbbCardBalanceSection = ({
  couponCreditAmt,
  storeConfig,
  couponData,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    if (storeConfig.couponRule === REWARD_LINK_RULE) {
      window.open(couponData?.couponCode, '_blank');
    } else {
      navigator.clipboard.writeText(couponData?.couponCode);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, '1850');
    }
  }, [couponData]);

  return (
    <div className={couponSection}>
      <div className={couponContainer}>
        <div
          style={getCouponStyle(storeConfig)}
          className={cardContainer}
        >
          <div className={cardBalanceContainer}>
            <div className={cardTopSection}>
              <div
                className={cardIconContainer}
                style={storeConfig.registerTbbFlow.rewardStep?.couponStyle}
              >
                <Image
                  className={cardIcon}
                  src={storeConfig.isTrashie ? tCoinLogoBlack :
                    storeConfig.registerTbbFlow.rewardStep?.logoCouponURL}
                  alt="small-store-logo"
                />
              </div>
              {couponCreditAmt ? (
                <div
                  style={{
                    color: `${!storeConfig?.isTrashie && storeConfig.registerTbbFlow.rewardStep?.cardTextColor}`,
                  }}
                  className={balance}
                >
                  {`$${couponCreditAmt}`}
                </div>
              ) : null}
            </div>
            {storeConfig.couponRule !== REWARD_LINK_RULE && (
              <div className={cardBottomSection}>
                <p className={cardDescription}>REWARD CODE</p>
                <p className={cardUserName}>{couponData?.couponCode}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Button
        type={BUTTON_TYPE.QUINARY}
        size={BUTTON_SIZE.LARGE}
        onClick={handleCopy}
        style={getOptionButtonStyles(storeConfig, true)}
      >
        {storeConfig.couponRule === REWARD_LINK_RULE ? (
          <div className={ctaLink}>
            SHOP WITH REWARD
            <img src={SquareLink} alt="link" />
          </div>
        ) : (<div>{copied ? 'CODE COPIED' : 'COPY CODE'}</div>)}
      </Button>
    </div>
  );
};

export default TbbCardBalanceSection;
