// import { useFirebaseAuth } from '../components/global/FirebaseProvider/FirebaseProvider';
import crud from '../api/crud';

const sendRedemptionEmail = async (redemptionCode, user, emailInput) => {
  const redemption = await crud.get({
    path: `/redemptions/${redemptionCode}`,
  });

  const stores = await crud.get({
    path: '/stores',
  });

  const { meta } = stores.find(({ uid }) => uid === redemption.storeId);

  if (meta.disableEmail) {
    return;
  }

  let firstName;
  try {
    if (user.displayName?.split(' ')[0]) {
      firstName = user.displayName?.split(' ')[0];
    } else if (user.displayName) {
      firstName = user.displayName;
    } else {
      firstName = '';
    }
  } catch {
    // do nothing
  }
  const label = JSON.parse(redemption.labeldata);
  if (redemption.labeldata && meta.registerTbbFlow) {
    await crud.post({
      path: '/email',
      body: {
        email: emailInput || user?.email,
        messageId: meta.registerTbbFlow?.emailTemplateId,
        messageData: {
          name: firstName,
          qrUrl: redemption?.shipment?.qrLabelUrl ?? '',
          labelUrl: label.postage_label.label_url,
          cashAmount: meta.registerTbbFlow?.rewardStep.cashValue,
          couponCode: redemption.couponData,
          trackerUrl: label.tracker?.public_url,
          shippingProviderId: redemption?.shipment?.shippingMethod?.shippingProviderId,
          carrier: redemption?.shipment?.shippingMethod?.carriers?.[0],
          shippingLabelExpiresAt: redemption?.shipment?.shippingLabelExpiresAt,
        },
      },
    });
  }
};

export default sendRedemptionEmail;
