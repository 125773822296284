import React from 'react';

export default function ArrosLeft({ width = 64, height = 64, color = '#696969', ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64 64" fill="none" {...rest}>
      <path d="M7.5 19L11.5 11.5H49V19H56.5V51H7.5V19Z" fill="#F0F0F0" />
      <path d="M42.6668 31.3528H48.0002V42.0195H42.6668V31.3528Z" fill={color} />
      <path d="M53.3335 18.0195V12.6862C53.3335 9.74484 50.9415 7.35284 48.0002 7.35284H13.3335C8.92283 7.35284 5.3335 10.9422 5.3335 15.3528V47.3528C5.3335 53.2222 10.1175 55.3528 13.3335 55.3528H53.3335C56.2748 55.3528 58.6668 52.9608 58.6668 50.0195V23.3528C58.6668 20.4115 56.2748 18.0195 53.3335 18.0195ZM13.3335 12.6862H48.0002V18.0195H13.3335C12.6469 17.9888 11.9986 17.6944 11.5236 17.1977C11.0486 16.701 10.7834 16.0401 10.7834 15.3528C10.7834 14.6655 11.0486 14.0047 11.5236 13.508C11.9986 13.0113 12.6469 12.7169 13.3335 12.6862ZM53.3335 50.0195H13.3655C12.1335 49.9875 10.6668 49.4995 10.6668 47.3528V22.8595C11.5042 23.1608 12.3922 23.3528 13.3335 23.3528H53.3335V50.0195Z" fill={color} />
    </svg>
  );
}
