import { useCallback, useEffect, useState } from 'react';

import { ANCHOR_OFFERS } from '../../config/routes';
import { getOffers, getPublicOffers } from '../../utils/service';

import { useAlert } from '../../../providers/AlertProvider';
import OffersList from './OffersList';
import TitleSection from '../TitleSection/TitleSection';
import LoadingBar from '../LoadingBar';

import styles from './Offers.module.scss';
import { DOWNLOAD_EXTENSION_URL } from '../../config/externalURL';

const {
  main,
  container,
  noData,
  loader,
  subTitleExtraStyle,
  cta,
} = styles;

const Offers = ({ isDashBoard = true }) => {
  const setAlert = useAlert();

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadOffers = useCallback(async () => {
    setIsLoading(true);

    const { data: offers, error } = isDashBoard ? await getOffers() : await getPublicOffers();

    if (error) {
      setAlert({
        type: 'alert',
        message: error,
        error,
      });
      setListData([]);
      setIsLoading(false);

      return;
    }

    setListData(offers);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadOffers();
  }, []);

  return (
    <div id={ANCHOR_OFFERS} className={main}>
      <div className={container}>
        <TitleSection
          pretitle="Earn TrashieCash™"
          title={isDashBoard ? 'New brands just added to trashie' : 'earn more trashiecash™ when you shop brands below:'}
          subtitle={isDashBoard && (
            <>
              {'Earn TrashieCash™ while you shop online. Get the '}
              <a className={cta} href={DOWNLOAD_EXTENSION_URL} target="_blank" rel="noreferrer">
                Chrome Extension and start earning at over 15,000 brands.
              </a>
            </>
          )}
          extraSubtitleStyle={subTitleExtraStyle}
        />
        {isLoading ? (
          <LoadingBar className={loader} />
        ) : (
          (listData.length && <OffersList offersList={listData} isDashBoard={isDashBoard} />) || (
            <span className={noData}>
              No offers available at the moment
            </span>
          )
        )}
      </div>
    </div>
  );
};

export default Offers;
