import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';

import useTbbAmountsExchange from '../../../../../hooks/useTbbAmountsExchange';
import cashIcon from '../../../images/cash-icon.svg';
import copyIcon from '../../../images/copy-icon.svg';

import Style from '../Register.module.scss';

const {
  firstLegend,
  coupon,
  couponHeader,
  couponLogo,
  couponValue,
  couponContent,
  couponMessage,
  couponCode,
  copyButton,
  copyCode,
  buttonContainer,
  buttonTrashie,
  trashieCouponContainer,
} = Style;

const RedemptionCouponView = ({
  storeConfig,
  couponData,
  copied,
  handleCopy,
}) => {
  const { tbbAmount } = useTbbAmountsExchange();

  return (
    <Card className="pt-40 px-lg-48 px-xl-96 px-16 px-md-80 pb-lg-40 pb-xl-56 pb-32 card">
      <h4 className={firstLegend}>
        {storeConfig.discountCodeText || 'Here is your TrashieCash™'}
      </h4>
      <div
        className={`d-flex text-center align-items-center justify-content-center py-lg-80 p-40 ${coupon}`}
        style={{
          backgroundColor: !storeConfig.isTrashie ? '#E2E2E2' : '',
          color: storeConfig.isTrashie ? storeConfig.color2 : '',
          marginBottom: couponData?.couponCode ? '0px' : '45px',
        }}
      >
        <div className={couponHeader}>
          <img
            className={couponLogo}
            src={storeConfig.mainLogoSrc}
            alt="coupon logo"
          />
          <div className={couponValue}>
            <img
              src={cashIcon}
              alt="coupon logo"
            />
          </div>
        </div>
        {couponData?.couponCode ? (
          <div className={couponContent}>
            <p className={couponMessage}>Coupon Code</p>
            <h4 className={couponCode}>{couponData?.couponCode}</h4>
          </div>
        ) : (
          <div className={trashieCouponContainer}>
            <h4 className={couponCode}>{`$${storeConfig.uid === 1 ? tbbAmount : 20} TRASHIECASH™ WAS ADDED TO YOUR ACCOUNT`}</h4>
          </div>
        )}

      </div>
      {couponData?.couponCode && (
      <Button
        className={copyButton}
        onClick={() => handleCopy()}
        style={{ boxShadow: 'none' }}
      >
        {copied ? 'Code Copied to Clipboard!' : 'Tap to Copy'}
        {!copied && (
        <img
          className={copyCode}
          src={copyIcon}
          alt="copy icon"
        />
        )}
      </Button>
      )}

      <div className={buttonContainer}>
        <Link
          className={buttonTrashie}
          to={{ pathname: storeConfig.shopRedirect }}
          target="_blank"
          style={{
            backgroundColor: storeConfig.color1,
            ...(storeConfig.color2 ? { color: storeConfig.color2 } : ''),
          }}
        >
          {storeConfig.shopRedirectText}
        </Link>
      </div>
    </Card>
  );
};

export default RedemptionCouponView;
