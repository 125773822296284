const SvgComponentArrow = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      fill={fill}
      d="m11.293 17.05 1.414 1.415 6.707-6.707-6.707-6.707-1.414 1.414 4.293 4.293H6v2h9.586l-4.293 4.293Z"
    />
  </svg>
);
export default SvgComponentArrow;
