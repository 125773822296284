import { isTrashieApp } from '../../../config/config';

const script = isTrashieApp() ? (
  'window.interdeal = {' +
  '  "sitekey": "5171dde7ae6e4bd74b749d10731ec460",' +
  '  "Position": "Right",' +
  '  "domains": {' +
  '    "js": "https://cdn.equalweb.com/",' +
  '    "acc": "https://access.equalweb.com/"' +
  '  },' +
  '  "Menulang": "EN",' +
  '  "btnStyle": {' +
  '    "vPosition": [ "80%", null ],' +
  '    "scale": [ "0.8", "0.8" ],' +
  '    "color": { "main": "#1876c9", "second": "" },' +
  '    "icon": { "type": 2, "shape": "circle", "outline": false }' +
  '  }' +
  '};' +
  '(function(doc, head, body){' +
  '  var coreCall             = doc.createElement("script");' +
  '  coreCall.src             = interdeal.domains.js + "core/4.5.2/accessibility.js";' +
  '  coreCall.defer           = true;' +
  '  coreCall.integrity       = "sha512-GVvo5c2SV7jwI6rUxQrAjIT6u0WHdJ+pbzRZyzfhOUGMaiKekbDs26ipItwEjD9jCvaV1qWbWurNBQGF5eY9aw==";' +
  '  coreCall.crossOrigin     = "anonymous";' +
  '  coreCall.setAttribute("data-cfasync", true );' +
  '  body? body.appendChild(coreCall) : head.appendChild(coreCall);' +
  '})(document, document.head, document.body);'
) : null;

export default script;
