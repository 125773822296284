import ItemRow from './TbbItemRow';
import style from './TbbItemList.module.scss';

const { container } = style;
const ItemList = ({ dataList, setTbbData }) => (
  <div className={container}>
    {dataList.map((itemData) => (<ItemRow itemData={itemData} setTbbData={setTbbData} />)) }
  </div>
);

export default ItemList;
