import './MarketingBanner.scss';

const MarketingBanner = ({
  title,
  description,
  ctaLabel,
  ctaURL,
  backgroundImage,
}) => (
  <a
    href={ctaURL ?? '#'}
    className="MarketingBanner"
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    <div className="MarketingBanner__container">
      <div className="MarketingBanner__container--title">
        {title}
      </div>
      <div className="MarketingBanner__container--description">
        {description}
      </div>
      {ctaLabel && (
        <span className="MarketingBanner__container--cta">
          {ctaLabel}
        </span>
      )}
    </div>
  </a>
);

export default MarketingBanner;
