import { useState, useEffect } from 'react';
import moment from 'moment';
import './MaintenanceBanner.scss';

const MaintenanceBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleDismiss = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const now = moment();
    const limit = moment(new Date('2024-06-06T22:00:00.000-07:00')); // PST UTC-8
    if (now.isBefore(limit)) {
      setIsVisible(true);
    }
  }, []);

  return (
    isVisible && (
    <div className="MaintenanceBanner">
      <div className="MaintenanceBanner__content">
        <span
          className="MaintenanceBanner__content--dismiss"
          onClick={handleDismiss}
          onKeyDown={handleDismiss}
          role="button"
          tabIndex={0}
        >
          X
        </span>
        <span className="MaintenanceBanner__content--message">
          <b>Trashie scheduled maintenance</b>
          <br />
          Thursday, June 06, from 9:00 pm to 10:00 pm (PT).
          <br />
          <br />
          You might experience service disruption during this time.
          <br />
          We apologize for any inconvenience.
        </span>
      </div>
    </div>
    )
  );
};

export default MaintenanceBanner;
