import { useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

import CheckCircleIcon from '../../../images/check-circle-icon.svg';
import CheckCircleGreenIcon from '../../../images/check-circle-green-icon.svg';
import InfoCircleIcon from '../../../images/info-circle-icon.svg';
import LockIconBlank from '../../../images/lock-icon-blank.svg';
import SkullCrossbonesIcon from '../../../images/skull-crossbones-icon.svg';

import './CompoundRadioOption.scss';

export const OPTION_STATUS = {
  AVAILABLE: 'available',
  LOCKED: 'locked',
  CHECKED: 'checked',
  USED: 'used',
  FORBIDDEN: 'forbidden',
};

const getDisabledIcon = (status) => {
  switch (status) {
    case OPTION_STATUS.CHECKED:
      return CheckCircleIcon;
    case OPTION_STATUS.USED:
      return CheckCircleGreenIcon;
    case OPTION_STATUS.FORBIDDEN:
      return SkullCrossbonesIcon;
    case OPTION_STATUS.LOCKED:
      return LockIconBlank;
    default:
      return null;
  }
};

const CompoundRadioOption = ({
  id,
  name,
  title,
  subtitle,
  description,
  value,
  status = OPTION_STATUS.AVAILABLE,
  tooltip,
  onClick,
  trackerProps = {},
}) => {
  const disabled = status !== OPTION_STATUS.AVAILABLE;

  const handleOnClick = useCallback(() => {
    if (!disabled) {
      onClick(id);
    }
  }, [disabled]);

  return (
    <button
      type="button"
      className={classNames('CompoundRadioOption', status, {
        active: value === id,
      })}
      disabled={disabled}
      onClick={handleOnClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(disabled ? {} : trackerProps)}
    >
      <div className="CompoundRadioOption__option">
        {disabled ? (
          <img
            className="CompoundRadioOption__option--icon"
            src={getDisabledIcon(status)}
            alt="disabled icon"
          />
        ) : (
          <input
            type="radio"
            className="CompoundRadioOption__option--input"
            id={id}
            name={name}
            value={id}
            checked={value === id}
            readOnly
          />
        )}
      </div>
      <div className="CompoundRadioOption__label">
        <div className="CompoundRadioOption__label--heading">
          <div className="CompoundRadioOption__label--heading-title">
            {title}
          </div>
          <div className="CompoundRadioOption__label--heading-subtitle">
            {subtitle && (
              <div>
                {subtitle}
              </div>
            )}
            {tooltip && (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>{tooltip}</Tooltip>}
              >
                <img src={InfoCircleIcon} alt="info circle icon" />
              </OverlayTrigger>
            )}
          </div>
        </div>
        <div className="CompoundRadioOption__label--subheading">
          {description}
        </div>
      </div>
    </button>
  );
};

export default CompoundRadioOption;
