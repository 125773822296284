import classNames from 'classnames';
import './DotsLoader.scss';

const DotsLoader = ({
  className,
}) => (
  <div className="DotsLoader">
    <div className={classNames('dot', className)} />
    <div className={classNames('dot', className)} />
    <div className={classNames('dot', className)} />
  </div>
);

export default DotsLoader;
