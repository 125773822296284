const TransactionActivityTypes = {
  TAKE_BACK_BAG: 'take back bag',
};

const TransactionStatusTypes = {
  TRANSACTION_PENDING: 'PENDING',
  TRANSACTION_PAID: 'PAID',
  TRANSACTION_DISQUALIFIED: 'DISQUALIFIED',
};

export {
  TransactionStatusTypes,
  TransactionActivityTypes,
};
