import ActivityListRow from './ActivityListRow';

import styles from './ActivityList.module.scss';

const {
  list,
} = styles;

const ActivityList = ({ listData }) => (
  <div className={list}>
    {listData.map((rowData) => (
      <ActivityListRow rowData={rowData} key={rowData.id} />
    ))}
  </div>
);

export default ActivityList;
