import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material';

const StyledDialog = styled((props) => <Dialog {...props} />)({
  '& .MuiDialog-paper': {
    borderRadius: 12,
  },
  '& .MuiBackdrop-root': {
    backgroundColor: '#292829',
    opacity: '0.85 !important',
  },
});

export default StyledDialog;
