import moment from 'moment';

const currentDate = moment();
const QR_EXPIRATION_DAYS = 28;
const SHIPPING_LABEL_EXPIRATION_MONTHS = 4;

export const shippingProviderStatus = {
  unknown: 'UNKNOWN',
  pre_transit: 'PRE_TRANSIT',
  in_transit: 'IN_TRANSIT',
  out_for_delivery: 'OUT_FOR_DELIVERY',
  delivered: 'DELIVERED',
  available_for_pickup: 'AVAILABLE_FOR_PICKUP',
  returned_to_sender: 'RETURNED_TO_SENDER',
  failure: 'FAILURE',
  cancelled: 'CANCELLED',
  error: 'ERROR',
};

export const isExpiredLabel = (shippingLabelExpiresAt) => {
  const expiredDate = moment(shippingLabelExpiresAt);
  return currentDate.isAfter(expiredDate) || currentDate.isSame(expiredDate);
};

export const qrIsExpired = (createdAt, shipmentStatus, shippingLabelExpiresAt) => {
  const releaseDate = moment('2024-04-09');
  const registeredDate = moment(createdAt);
  const dayDiff = releaseDate.diff(registeredDate, 'days');
  const monthDiff = releaseDate.diff(registeredDate, 'months');

  if (dayDiff >= QR_EXPIRATION_DAYS && monthDiff < SHIPPING_LABEL_EXPIRATION_MONTHS &&
    shipmentStatus !== shippingProviderStatus.in_transit &&
    shipmentStatus !== shippingProviderStatus.out_for_delivery &&
    shipmentStatus !== shippingProviderStatus.delivered &&
    !isExpiredLabel(shippingLabelExpiresAt)
  ) {
    return true;
  }

  return false;
};

export const EXPIRED_QR_DESCRIPTION = 'To ship, simply print your shipping label and take it to your local USPS location.';
