import { formatTransactionDate } from '../../../utils/dates';
import { formatCurrencyNumber, getTransactionAmount, isNegativeTransaction } from '../../../utils/numbers';
import isPaidStatusType from '../../../utils/transactions';
import TrashieCoin from '../../../images/TC-icon.svg';

import styles from './ActivityListRow.module.scss';

const {
  main,
  container,
  rowContent,
  origin,
  originText,
  details,
  merchant,
  sale,
  date,
  amount,
  negative,
  status,
  statusText,
  tcIcon,
} = styles;

const DATA_NOT_AVAILABLE = 'Unknown';

const ActivityListRow = ({ rowData }) => {
  const {
    id,
    activityType,
    merchant: rowMerchant,
    saleAmount,
    createdAt,
    amount: rowAmount,
    typeId: transactionType,
    transactionStatus,
  } = rowData;

  return (
    <div className={main} key={`activity-row-${id}`}>
      <div className={container}>
        <div className={`${rowContent} ${origin}`}>
          <span className={originText}>
            {activityType ?? DATA_NOT_AVAILABLE}
          </span>
        </div>
        <div className={`${rowContent} ${details}`}>
          <span className={merchant}>
            {rowMerchant ?? DATA_NOT_AVAILABLE}
          </span>
          <div className={rowContent}>
            {saleAmount && (
              <span className={sale}>
                {formatCurrencyNumber(saleAmount)}
              </span>
            )}
            <span className={date}>
              {formatTransactionDate(createdAt)}
            </span>
          </div>
        </div>
        <div className={`${rowContent} ${amount}`}>
          <span className={isNegativeTransaction(transactionType) ? negative : ''}>
            {formatCurrencyNumber(getTransactionAmount(rowAmount, transactionType), new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
            }))}
            <img src={TrashieCoin} className={tcIcon} alt="trashie cash icon" />
          </span>
        </div>
        <div className={`${rowContent} ${status}`}>
          <span className={statusText}>
            {!isPaidStatusType(transactionStatus) && transactionStatus}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActivityListRow;
