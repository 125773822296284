/* eslint-disable react/jsx-one-expression-per-line */
import { Link } from 'react-router-dom';
import { Card, Button, Image, Anchor } from 'react-bootstrap';

import printerIcon from '../../../images/printer-icon.svg';
import facepalmIcon from '../../../images/facepalm-icon.svg';
import refreshIcon from '../../../images/refresh-icon.svg';
import SvgComponentArrow from '../../../global/SvgComponentArrow';
import qrPostalOffice from '../../../images/qrPostalOffice.webp';
import { EXPIRED_QR_DESCRIPTION } from '../../../utils/easyPost';
import { formatMMDDYY } from '../../../utils/dates';
import InfoSection from '../../../global/InfoSection';

import Style from '../Register.module.scss';

const {
  qrButton,
  centeredContainer,
  qrCard,
  qrImage,
  printLabel,
  buttonContainer,
  button,
  infoContainer,
  expirationDateContainer,
  expirationDateTitle,
  expirationDate,
} = Style;

const RedemptionQrView = ({
  storeConfig,
  qrFailed,
  qrCodeImgUrl,
  printLabelUrl,
  onQrFormButtonClick,
  isAQrExpired,
  expirationLabelDate,
}) => {
  const labelDescription = () => {
    if (!qrFailed && !isAQrExpired) {
      return storeConfig?.registerTbbFlow?.qrScanStep?.descriptionText;
    } if (isAQrExpired) {
      return EXPIRED_QR_DESCRIPTION;
    }
    return '';
  };
  return (
    <div className={centeredContainer}>
      <div className={infoContainer}>
        <InfoSection
          titleText={qrFailed ? 'OOPS! SOMETHING WENT WRONG WITH YOUR QR CODE' : 'HERE\'S YOUR DIGITAL SHIPPING LABEL'}
          descriptionText={labelDescription()}
        />
        { !isAQrExpired && (
        <div className={expirationDateContainer}>
          <div className={expirationDateTitle}>Label Expires by:</div>
          <div className={expirationDate}>{` ${formatMMDDYY(expirationLabelDate)}`}</div>
        </div>
        )}
      </div>
      { isAQrExpired ? (
        <div className={qrCard}>
          <Image className={qrImage} src={qrPostalOffice} alt="qr-code-expired" />
        </div>
      )
        : (
          <Card>
            <div className={qrCard}>
              {qrFailed ? <Image className={qrImage} src={facepalmIcon} alt="qr-code-error" /> :
              <Image className={qrImage} src={qrCodeImgUrl} alt="qr-code" /> }
            </div>
          </Card>
        )}
      <div>
        {qrFailed ?
          (
            <Anchor
              onClick={() => window.location.reload()}
              className={printLabel}
            >
              Try Again
              <Image className={qrButton} src={refreshIcon} />
            </Anchor>
          ) : (
            <Link
              to={{ pathname: printLabelUrl }}
              className={printLabel}
              target="_blank"
            >
              Print Label
              <Image className={qrButton} src={printerIcon} />
            </Link>
          )}
      </div>
      <div className={buttonContainer}>
        {!qrFailed && (
          <Button
            type="button"
            className={button}
            onClick={onQrFormButtonClick}
            style={{
              background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
              color: storeConfig.registerTbbFlow?.nextButton?.textColor,
              border: !storeConfig.isTrashie && storeConfig.registerTbbFlow?.nextButton?.bgColor,
            }}
            disabled={qrFailed}
          >
            {storeConfig?.registerTbbFlow?.qrScanStep.buttonText}
            <SvgComponentArrow fill={storeConfig.registerTbbFlow?.nextButton?.textColor} />
          </Button>
        ) }

      </div>
    </div>
  );
};

export default RedemptionQrView;
