import './StepProgressBar.scss';

const StepProgressBar = ({
  currentStep = 0,
  totalSteps = 1,
  color,
}) => (
  <div className="ProgressBar">
    <div
      className="ProgressBar__progress"
      style={{
        width: `${(currentStep / totalSteps) * 100}%`,
        ...(color && { background: color }),
      }}
    />
  </div>
);

export default StepProgressBar;
