import { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';

import {
  getActionType,
  getEventName,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
} from '../../../config/tracker';
import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import Cover, { COVER_TYPE } from '../Cover';

import './RewardItem.scss';

const {
  rewards: {
    list: {
      rewardItem: trackerRewardItem,
    },
  },
} = TRACKER_IDS;

const Reward = ({ rewardItem, onClick, rewardProperties }) => {
  const { isSignedIn, user } = useFirebaseAuth();
  const posthog = usePostHog();

  const {
    uid: rewardId,
    name: rewardName,
    store,
  } = rewardItem;

  const handleOnClick = useCallback(() => {
    if (onClick) {
      posthog.capture(
        getEventName(trackerRewardItem),
        {
          [POSTHOG_PROPERTIES.REWARD_ID]: rewardId,
          [POSTHOG_PROPERTIES.REWARD_NAME]: rewardName,
          ...(isSignedIn && user && ({
            $set: { email: user.email },
          })),
        },
      );

      onClick({
        storeId: store.id,
        rewardId,
      });
    }
  }, [rewardId, rewardName, onClick, isSignedIn, user]);

  return (
    <div
      id={`RewardItem_${rewardItem.uid}`}
      onClick={handleOnClick}
      role="button"
      tabIndex={0}
      aria-label="reward item"
      className="RewardItem"
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleOnClick();
        }
      }}
      {...{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerRewardItem),
        [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerRewardItem),
        [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: rewardId,
        [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: rewardName,
      }}
    >
      <Cover rewardItem={rewardItem} type={COVER_TYPE.LIST} rewardProperties={rewardProperties} />
    </div>
  );
};

export default Reward;
