import { useCallback, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';

import useInterval from '../../../../hooks/useInterval';

import './LoadingBar.scss';

const getProgress = (currentProgress) => {
  let step = 0.2;

  if (currentProgress >= 70) {
    step = 0.1;
  } else if (currentProgress >= 90) {
    step = 0.05;
  }

  const progress = currentProgress + step;
  const width = currentProgress >= 100 ? (
    100
  ) : (
    Math.round((Math.atan(progress) / (Math.PI / 2)) * 100 * 1000) / 1000
  );

  return { progress, width };
};

const LoadingBar = ({ className }) => {
  const [progress, setProgress] = useState(0);
  const [width, setWidth] = useState(0);

  const updateProgress = useCallback(() => {
    const { progress: newProgress, width: newWidth } = getProgress(progress);

    setProgress(newProgress);
    setWidth(newWidth);
  }, [progress]);

  useInterval(updateProgress, 100);

  return (
    <ProgressBar className={classNames('LoadingBar', className)}>
      <div className="LoadingBar__progress" style={{ width: `${width}%` }} />
    </ProgressBar>
  );
};

export default LoadingBar;
