import TCCoin from '../images/TC-icon.svg';

export const SORT_TYPES = {
  POPULAR: 'popular',
  NEWEST: 'newest',
  LOWEST: 'lowest',
  HIGHEST: 'highest',
};

export const sortingOptions = [
  { label: 'Most Popular', id: SORT_TYPES.POPULAR },
  { label: 'Newest first', id: SORT_TYPES.NEWEST },
  {
    label: (
      <div>
        <img src={TCCoin} alt="TCH" />
        <span> Low to High</span>
      </div>
    ),
    id: SORT_TYPES.LOWEST,
  },
  {
    label: (
      <div>
        <img src={TCCoin} alt="TCH" />
        <span> High to Low</span>
      </div>
    ),
    id: SORT_TYPES.HIGHEST,
  },
];

export const dealSortingOptions = [
  { label: 'Most Popular', id: SORT_TYPES.POPULAR },
  { label: 'Newest first', id: SORT_TYPES.NEWEST },
];

export const getSortingParam = (sortingSelected) => {
  switch (sortingSelected.id) {
    case SORT_TYPES.LOWEST:
      return { sort: 'exchangeValue' };
    case SORT_TYPES.HIGHEST:
      return { sort: '-exchangeValue' };
    case SORT_TYPES.POPULAR:
      return { sort: 'sortRank' };
    case SORT_TYPES.NEWEST:
      return { sort: '-createdAt' };
    default:
      return {};
  }
};

export const getSort = (sort) => (
  (sort && sortingOptions.find(({ id }) => id === sort)) ||
  sortingOptions[0]
);
