import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { formatMMDDYY } from '../../utils/dates';
import LoadingBar from '../../global/LoadingBar';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../global/Button';
import ImpactMetrics from '../../global/ImpactMetrics';
import { ROUTE_LOGOUT, ROUTE_PROFILE, ROUTE_TAKE_BACK_BAGS, ROUTE_TRANSACTIONS } from '../../config/routes';
import ItemListContainer from '../../global/ItemListContainer/ItemListContainer';
import { useAlert } from '../../../providers/AlertProvider';
import { FAQS_URL } from '../../config/externalURL';

import style from './MyAccount.module.scss';

const {
  main,
  container,
  headerInfo,
  userTitle,
  contentAccount,
  emailTitle,
  sinceTitle,
  button,
  redirectContainer,
  userInfo,
  name,
  sectionContainer,
} = style;

const MENU_ITEMS = [
  {
    label: 'TRANSACTION HISTORY',
    route: ROUTE_TRANSACTIONS,
  },
  {
    label: 'MY TAKE BACK BAGS',
    route: ROUTE_TAKE_BACK_BAGS,
  },
  {
    label: 'ACCOUNT DETAILS',
    route: ROUTE_PROFILE,
  },
  {
    label: 'FAQS',
    route: FAQS_URL,
    external: true,
  },
  {
    label: 'LOGOUT',
    route: ROUTE_LOGOUT,
  },
];

const MyAccount = () => {
  const { userData, isLoading: isLoadingFirebaseAuth } = useFirebaseAuth();
  const history = useHistory();
  const setAlert = useAlert();

  useEffect(() => {
    if (isLoadingFirebaseAuth) {
      return;
    }

    if (userData?.error) {
      setAlert(userData.error);
    }
  }, [userData?.error, isLoadingFirebaseAuth]);

  return (
    <div className={main}>
      <div className={container}>
        {isLoadingFirebaseAuth ? <LoadingBar /> : (
          <div className={contentAccount}>
            <div className={headerInfo}>
              <div>
                <div className={userTitle}>{userData.firstName ? `Hi, ${userData.firstName}` : 'Account'}</div>
                <div className={emailTitle}>{userData.email}</div>
              </div>
              <div className={sinceTitle}>{`Member Since: ${formatMMDDYY(userData.memberSince)}`}</div>
              <div className={redirectContainer}>
                {MENU_ITEMS.map(({ label, route, external }) => (
                  <Button
                    type={BUTTON_TYPE.SECONDARY}
                    size={BUTTON_SIZE.MEDIUM}
                    className={button}
                    onClick={() => {
                      if (external) {
                        window.location.href = route;
                        return;
                      }

                      history.push(route);
                    }}
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </div>
            <ImpactMetrics />
            <div className={sectionContainer}>
              <ItemListContainer title="Details">
                <div className={userInfo}>
                  <div className={name}>
                    UPDATE YOUR NAME OR PASSWORD
                  </div>
                  <Button
                    type={BUTTON_TYPE.SECONDARY}
                    size={BUTTON_SIZE.LARGE}
                    onClick={() => history.push(ROUTE_PROFILE)}
                  >
                    UPDATE NOW
                  </Button>
                </div>
              </ItemListContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
