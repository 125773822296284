import * as React from 'react';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const StyledTab = styled((props) => <Tab {...props} />)(
  ({ theme }) => ({
    fontFamily: 'GT Maru',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '14px',
    marginRight: theme.spacing(1),
    color: '#292829',
    opacity: 0.6,
    padding: '8px 16px',
    margin: 0,
    '&.Mui-selected': {
      opacity: 1,
      color: '#292829',
    },
    '&:hover, &.Mui-focusVisible': {
      opacity: 0.8,
    },
  }),
);

export default StyledTab;
