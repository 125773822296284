import React from 'react';

import { ROUTE_DASHBOARD_REWARDS } from '../../../config/routes';
import giftIcon from '../../../images/gift-icon.svg';

import './ZeroState.scss';

const ZeroState = () => (
  <div className="ZeroState">
    <img className="ZeroState__image" src={giftIcon} alt="gift icon" />
    <span className="ZeroState__title">
      ACCESS YOUR REWARDS AS YOU SHOP
    </span>
    <span className="ZeroState__message">
      As you spend your TrashieCash™ in exclusive rewards, you’ll be able to access them
      anytime you need them in this section. Shop brands you love now!
    </span>
    <a className="ZeroState__cta" href={ROUTE_DASHBOARD_REWARDS} target="_blank" rel="noreferrer">
      Get My Rewards Now
    </a>
  </div>
);

export default ZeroState;
