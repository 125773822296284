import { useCallback, useEffect, useState } from 'react';

import { DOWNLOAD_EXTENSION_URL, TAKE_BACK_BAG_URL } from '../../config/externalURL';
import ActivityList from '../../global/ActivityList';
import PageContent from '../../global/PageContent';
import moneyStackIcon from '../../images/money-stack-icon.svg';
import { getGenericError } from '../../utils/errors';
import { getTransactions } from '../../utils/service';

import './Transactions.scss';

const Transactions = () => {
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadTransactions = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: transactionsError,
    } = await getTransactions();

    if (transactionsError) {
      setListData([]);
      setError(transactionsError.message ?? getGenericError());
      setIsLoading(false);
      return;
    }

    setListData(data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadTransactions();
  }, []);

  return (
    <PageContent
      title="Transaction History"
      subtitle="Keep tabs on your recent earnings"
      isLoading={isLoading}
      error={error}
      className="Transactions"
    >
      {(listData.length && <ActivityList listData={listData} />) || (
        <div className="Transactions__noData">
          <img className="Transactions__noData--image" src={moneyStackIcon} alt="default icon" />
          <span className="Transactions__noData--message">
            Earn TrashieCash™ When you shop with the chrome extension
            or when you recycle with the Take back bag
          </span>
          <div className="Transactions__noData--buttons">
            <a className="buttonPrimary" href={TAKE_BACK_BAG_URL}>
              Buy a Take Back Bag
            </a>
            <a className="buttonSecondary" href={DOWNLOAD_EXTENSION_URL}>
              Add to Chrome
            </a>
          </div>
        </div>
      )}
    </PageContent>
  );
};

export default Transactions;
