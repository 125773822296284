import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../Button';
import './StickyButton.scss';

const StickyButton = ({
  buttonLabel,
  onSubmit,
  title,
  subTitle,
  disabled,
  showDescription = false,
  typeSecondary = false,
  trackerProps = {},
}) => (
  <div className="stickyButton">
    <div className="stickyButton__container" style={{ justifyContent: `${(!title && !subTitle) && 'center'}` }}>
      { showDescription && (
      <div className="stickyButton__container--description">
        {title && <div className="stickyButton__container--description--title">{title}</div>}
        {subTitle && <div className="stickyButton__container--description--subTitle">{subTitle}</div>}
      </div>
      )}
      <Button
        className="stickyButton__container--button"
        type={typeSecondary ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY}
        size={BUTTON_SIZE.LARGE}
        disabled={disabled}
        onClick={onSubmit}
        {...{ trackerProps }}
      >
        {buttonLabel}
      </Button>
    </div>
  </div>
);

export default StickyButton;
