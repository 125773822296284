import { TAKE_BACK_BAG_URL } from '../config/externalURL';
import {
  REWARDS_VALIDATED_STATE,
  REWARD_AMOUNT_TYPES,
  REWARD_TYPE,
} from '../config/rewards';
import {
  NOT_FOUND_ERROR,
  REWARDS_FUNDS_CODE_ERROR,
  REWARDS_NO_MORE_CODES,
  REWARDS_NO_MORE_CODES_PER_USER,
} from './errors';
import CircleArrowRight from '../images/circle-arrow-right.svg';
import CircleArrowUp from '../images/circle-arrow-up.svg';
import CircleArrowDown from '../images/circle-arrow-down.svg';
import TCCoin from '../images/TC-icon.svg';

const getRewardCode = (rewardItem) => (
  rewardItem.type === REWARD_TYPE.VANITY || rewardItem.type === REWARD_TYPE.ONE_TIME ||
    rewardItem.type === REWARD_TYPE.SHOPIFY_DISCOUNT_CODE ?
    (rewardItem.code) : null
);

const replaceRewardTags = (text, tags = {}) => {
  let newText = text;

  Object.entries(tags).forEach(([tag, value]) => {
    newText = newText.replace(new RegExp(`${tag}`), value);
  });

  return newText;
};

const isLimitErrorCode = (code) => code === REWARDS_NO_MORE_CODES;

const isUserLimitErrorCode = (code) => code === REWARDS_NO_MORE_CODES_PER_USER;

const isFundsErrorCode = (code) => code === REWARDS_FUNDS_CODE_ERROR;

const isNotFoundErrorCode = (code) => code === NOT_FOUND_ERROR;

const getArrowImg = (isMobileSize, showDetails) => {
  if (!isMobileSize) {
    return CircleArrowRight;
  }
  if (isMobileSize && showDetails) {
    return CircleArrowUp;
  }
  return CircleArrowDown;
};

const getRewardItemValidated = (rewardItem, currentBalance = 0) => {
  const { exchangeValue, status } = rewardItem;

  if (!rewardItem || !status) {
    return {
      disabled: true,
    };
  }

  let disabled = false;
  let tooltipDisabled;
  let calcStatus;

  if (isUserLimitErrorCode(status)) {
    disabled = true;
    tooltipDisabled = '';
  } else if (isLimitErrorCode(status)) {
    disabled = true;
    tooltipDisabled = '';
  } else if (parseFloat(currentBalance) < parseFloat(exchangeValue)) {
    disabled = true;
    tooltipDisabled = '';
    calcStatus = REWARDS_FUNDS_CODE_ERROR;
  }

  return {
    ...rewardItem,
    disabled,
    tooltipDisabled,
    ...(calcStatus && { status: calcStatus }),
  };
};

const getRewardsValidated = (rewards, currentBalance = 0) => {
  let areAllDisabled = true;

  const rewardsValidated = rewards.map(rewardItem => {
    const rewardItemValidated = getRewardItemValidated(rewardItem, currentBalance);

    if (areAllDisabled && !rewardItemValidated.disabled) {
      areAllDisabled = false;
    }

    return rewardItemValidated;
  }).sort((firstReward, secondReward) => {
    const {
      amountType: firstAmountType,
      rewardAmount: firstAmount,
    } = firstReward;
    const firstRewardAmountType = REWARD_AMOUNT_TYPES[firstAmountType];

    const {
      amountType: secondAmountType,
      rewardAmount: secondAmount,
    } = secondReward;
    const secondRewardAmountType = REWARD_AMOUNT_TYPES[secondAmountType];

    switch (true) {
      case
        (firstRewardAmountType && !firstRewardAmountType.hasAmount) ||
        (secondRewardAmountType && !secondRewardAmountType.hasAmount):
        return firstRewardAmountType.sortRanking - secondRewardAmountType.sortRanking;
      case
        (firstRewardAmountType && firstRewardAmountType.hasAmount) &&
        (secondRewardAmountType && secondRewardAmountType.hasAmount):
        return firstAmountType === secondAmountType ? (
          parseFloat(firstAmount) - parseFloat(secondAmount)
        ) : (
          firstRewardAmountType.sortRanking - secondRewardAmountType.sortRanking
        );
      default:
        return 0;
    }
  });

  return {
    rewardsValidated,
    validatedState: {
      [REWARDS_VALIDATED_STATE.NOT_ENOUGH_CASH]: areAllDisabled &&
        !rewardsValidated.some(({ status }) => !isFundsErrorCode(status)),
      [REWARDS_VALIDATED_STATE.SOLD_OUT]: areAllDisabled &&
        !rewardsValidated.some(({ status }) => !isLimitErrorCode(status)),
      [REWARDS_VALIDATED_STATE.USER_LIMIT]: areAllDisabled &&
        !rewardsValidated.some(({ status }) => !isUserLimitErrorCode(status)),
      [REWARDS_VALIDATED_STATE.ALL_DISABLED]: areAllDisabled,
    },
  };
};

const getRewardsSelectorTitle = (validatedState, rewardCount) => {
  const {
    [REWARDS_VALIDATED_STATE.NOT_ENOUGH_CASH]: notEnoughCash,
    [REWARDS_VALIDATED_STATE.SOLD_OUT]: soldOut,
    [REWARDS_VALIDATED_STATE.USER_LIMIT]: userLimit,
    [REWARDS_VALIDATED_STATE.ALL_DISABLED]: allDisabled,
  } = validatedState;

  switch (true) {
    case soldOut:
      return 'REWARD SOLD OUT';
    case userLimit:
      return 'REWARD CLAIMED';
    case notEnoughCash:
      return (
        <p>
          <a href={TAKE_BACK_BAG_URL} style={{ textDecoration: 'underline' }}>GET MORE </a>
          TRASHIECASH™ TO UNLOCK THIS REWARD
        </p>
      );
    case allDisabled && rewardCount > 1:
      return 'No rewards available';
    case rewardCount === 1:
      return 'WHAT YOU’LL GET';
    default:
      return 'CHOOSE YOUR REWARD';
  }
};

const getRewardsAllStatus = (validatedState) => {
  const {
    [REWARDS_VALIDATED_STATE.NOT_ENOUGH_CASH]: notEnoughCash,
    [REWARDS_VALIDATED_STATE.SOLD_OUT]: soldOut,
    [REWARDS_VALIDATED_STATE.USER_LIMIT]: userLimit,
  } = validatedState;

  switch (true) {
    case notEnoughCash:
      return REWARDS_FUNDS_CODE_ERROR;
    case soldOut:
      return REWARDS_NO_MORE_CODES;
    case userLimit:
      return REWARDS_NO_MORE_CODES_PER_USER;
    default:
      return 'OK';
  }
};

const getRewardOptionSubtitle = (status, exchangeValue) => {
  switch (true) {
    case isLimitErrorCode(status):
      return 'SOLD OUT';
    case isUserLimitErrorCode(status):
      return 'CLAIMED';
    default:
      return exchangeValue > 0 ? `${exchangeValue}` : 'FREE!';
  }
};

const getRewardStatusSubtitle = (status) => {
  switch (true) {
    case isLimitErrorCode(status):
      return 'SOLD OUT';
    case isUserLimitErrorCode(status):
      return 'CLAIMED';
    case isFundsErrorCode(status):
      return (
        <>
          <span>You need more </span>
          <img src={TCCoin} alt="TCH" />
        </>
      );
    default:
      return '';
  }
};

const getUniqueLink = (rewardItem) => {
  const {
    affiliateLink,
    store: {
      url: storeUrl,
    } = {},
  } = rewardItem;

  return affiliateLink || storeUrl;
};

export {
  getRewardCode,
  replaceRewardTags,
  isLimitErrorCode,
  isUserLimitErrorCode,
  isFundsErrorCode,
  isNotFoundErrorCode,
  getArrowImg,
  getRewardsValidated,
  getRewardsSelectorTitle,
  getRewardsAllStatus,
  getRewardOptionSubtitle,
  getRewardStatusSubtitle,
  getUniqueLink,
};
