import { useEffect } from 'react';
import { isTrashieApp } from '../config/config';

const useNoScript = (innerHTML) => {
  if (!innerHTML) {
    return;
  }

  useEffect(() => {
    const noscriptElement = document.createElement('noscript');
    noscriptElement.innerHTML = innerHTML;
    document.body.appendChild(noscriptElement);

    return () => {
      if (isTrashieApp()) {
        document.body.removeChild(noscriptElement);
      }
    };
  }, [innerHTML]);
};

export default useNoScript;
