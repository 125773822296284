import classNames from 'classnames';
import LoadingBar from '../LoadingBar';

import './PageContent.scss';

const PageContent = ({
  title = '',
  subtitle = '',
  children,
  cta,
  isLoading,
  error = '',
  className = '',
}) => (
  <div className={classNames('PageContent', className)}>
    <div className="PageContent__container">
      {cta && (
        <div className="PageContent__cta">
          {cta}
        </div>
      )}
      <div className="PageContent__header">
        <h2 className="PageContent__header--title">{title}</h2>
        <p className="PageContent__header--subtitle">{subtitle}</p>
      </div>
      <div className="PageContent__content">
        {error ? (
          <h2 className="PageContent__content--error">{error}</h2>
        ) : (
          (isLoading && (
            <LoadingBar className="PageContent__content--loader" />
          )) || children
        )}
      </div>
    </div>
  </div>
);

export default PageContent;
