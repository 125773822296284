const EyeOff = ({
  fill = 'none',
  width = 24,
  height = 24,
  stroke = '#000',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
  >
    <g
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M9.88 10.38a3 3 0 1 0 4.24 4.24M10.73 5.58c.421-.052.845-.08 1.27-.08 7 0 10 7 10 7a13.163 13.163 0 0 1-1.67 2.68" />
      <path d="M6.61 7.11A13.526 13.526 0 0 0 2 12.5s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61M2 2.5l20 20" />
    </g>
  </svg>
);

export default EyeOff;
