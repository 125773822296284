const EyeOn = ({
  fill = 'none',
  width = 24,
  height = 25,
  stroke = '#D9D9D9',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={height}
    height={width}
    fill={fill}
  >
    <g
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M2 12.5s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
      <path d="M12 15.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
    </g>
  </svg>
);

export default EyeOn;
