import tCoinLogo from '../../../images/t-coin-logo.svg';
import Flame from '../../../images/flame.svg';
import ChevronUp from '../../SvgComponents/ChevronUp_svg';
import { ROUTE_LOGIN } from '../../../config/routes';
import { isTrashieApp, trashieRoute } from '../../../../../config/config';

import styles from './Offer.module.scss';

const {
  main,
  imgContainer,
  offerDetails,
  brand,
  activateButton,
  chevron,
  imageButton,
} = styles;

const Offer = ({ offerItem, isDashBoard }) => {
  const {
    merchantId,
    name,
    offer,
    logoUrl,
    url,
  } = offerItem;

  return (
    <div className={main} key={`offer-box-${merchantId}`}>
      <div className={imgContainer}>
        <img src={logoUrl || tCoinLogo} alt="merchant logo" />
      </div>
      <div className={offerDetails}>
        <span className={brand}>
          {name}
        </span>
        <a className={activateButton} href={isDashBoard ? url : `${!isTrashieApp() ? trashieRoute() : ''}${ROUTE_LOGIN}?uri=${url}`} target="_blank" rel="noreferrer">
          <img src={Flame} alt="flame" className={imageButton} />
          {offer}
          <div className={chevron}>
            <ChevronUp color="#3333FF" />
          </div>

        </a>
      </div>
    </div>
  );
};

export default Offer;
