import { useCallback, useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import {
  REWARD_ID_PARAM,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_DASHBOARD_REWARDS,
  ROUTE_REWARDS_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
} from '../../config/routes';
import { getReward } from '../../utils/service';
import LoadingBar from '../../global/LoadingBar';

const OldReward = () => {
  const { [REWARD_ID_PARAM]: rewardId } = useParams();
  const [storeId, setStoreId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadReward = useCallback(async () => {
    const { data, error } = await getReward(rewardId);

    if (error) {
      setStoreId(null);
      setIsLoading(false);
      return;
    }

    setStoreId(data.reward?.store?.id);
    setIsLoading(false);
  }, [rewardId]);

  useEffect(() => {
    loadReward();
  }, []);

  if (isLoading) {
    return <LoadingBar />;
  }

  return (
    <Redirect
      to={storeId && rewardId ? (
        ROUTE_REWARDS_STORE_REWARD_ID
          .replace(STORE_ID_ROUTE_PARAM, storeId)
          .replace(REWARD_ID_ROUTE_PARAM, rewardId)
      ) : (
        ROUTE_DASHBOARD_REWARDS
      )}
    />
  );
};

export default OldReward;
