import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

const StyledDrawer = styled((props) => <Drawer {...props} />)({
  '& .MuiDrawer-paperAnchorBottom': {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    backgroundColor: '#FCFFFD',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: '#292829',
    opacity: '0.85 !important',
  },
});

export default StyledDrawer;
