import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { REWARD_ID_PARAM, ROUTE_MY_REWARDS } from '../../config/routes';
import { getRewardCodeByUidAndUser } from '../../utils/service';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../global/Button';
import ArrowBack from '../../images/arrow-back.svg';
import LoadingBar from '../../global/LoadingBar';
import './RewardInfo.scss';
import { RewardContentFinal, RewardContentTerms } from '../Reward/RewardContent';
import GeneralModal from '../../global/GeneralModal';
import { CONTENT_TERMS_TYPE } from '../Reward/RewardContent/RewardContentTerms';
import { getActionType, getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../config/tracker';
import { isMobile } from '../../../../hooks/useBreakPoint';

const {
  myRewards: {
    list: {
      shopButton: trackerShopButton,
    },
  },
} = TRACKER_IDS;

const REWARD_INFO_ID = 'rewardInfo';

const RewardInfo = ({
  isDeal,
}) => {
  const { [REWARD_ID_PARAM]: rewardId } = useParams();
  const history = useHistory();
  const [rewardItem, setRewardItem] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const timeoutRef = useRef(null);
  const mobile = isMobile();

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const loadReward = useCallback(async () => {
    setIsLoading(true);
    const {
      data,
      error: rewardDataError,
    } = await getRewardCodeByUidAndUser(rewardId);

    if (rewardDataError) {
      setRewardItem({});
    }

    const {
      code,
      expireAt,
      pin,
      redeemUrl,
      reward,
    } = data.result;

    setRewardItem({
      code,
      expireAt,
      pin,
      redeemUrl,
      ...reward,
    });

    setIsLoading(false);
  }, [rewardId]);

  const scrollToTop = useCallback(() => {
    const mainElement = document.getElementById(REWARD_INFO_ID).parentElement;

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  useEffect(() => {
    loadReward();
    scrollToTop();

    return () => timeoutRef?.current && clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div className="RewardInfo" id={REWARD_INFO_ID}>
      <div className="RewardInfo__content">
        <div className="RewardInfo__content--container">
          {!mobile && (
            <Button
              type={BUTTON_TYPE.SECONDARY}
              size={BUTTON_SIZE.MEDIUM}
              className="RewardInfo__content--button"
              onClick={() => (
                history.length > 1 ? history.goBack() : history.push(ROUTE_MY_REWARDS)
              )}
            >
              <img src={ArrowBack} alt="arrow-back" />
            </Button>
          )}
          <div className="RewardInfo__content--section">
            { isLoading ? (
              <LoadingBar className="RewardInfo__content--loader" />
            ) : (
              <RewardContentFinal
                rewardItem={rewardItem}
                onShowTerms={() => setShowModal(true)}
                isDeal={isDeal}
              />
            )}
          </div>
        </div>
      </div>
      <div className="RewardInfo__ctaContainer">
        <a
          className="cta-shop"
          href={rewardItem.affiliateLink || rewardItem.store?.url}
          {...{
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
            [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
          }}
        >
          SHOP NOW
        </a>
      </div>
      <GeneralModal
        onClose={toggleModal}
        showModal={showModal}
        showModalHeader={false}
        showModalFooter={false}
        canClose
        showClose
      >
        <RewardContentTerms
          rewardItem={rewardItem}
          onClose={toggleModal}
          type={CONTENT_TERMS_TYPE.FINAL}
        />
      </GeneralModal>
    </div>
  );
};

export default RewardInfo;
