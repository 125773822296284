import { useCallback, useMemo } from 'react';

import Slider from '../../../global/Mui/Slider';
import TCCoinSvg from '../../../global/SvgComponents/TCCoin_svg';

import './SliderSelector.scss';

const SliderSelector = ({
  value,
  title,
  options,
  onChange,
  currentBalance,
}) => {
  const min = useMemo(() => (
    Number(options[0]?.rewardAmount) ?? 0
  ), [options]);

  const max = useMemo(() => (
    Number(options[options.length - 1]?.rewardAmount) ?? 0
  ), [options]);

  const selectedOption = useMemo(() => (
    options.find(option => option.uid === value) ?? {}
  ), [options, value]);

  const trackLimit = useMemo(() => {
    const maxIndex = currentBalance < max ? (
      options.findIndex(option => option.exchangeValue > currentBalance)
    ) : (
      options.length
    );

    return `${(maxIndex * 100) / options.length}%`;
  }, [options, currentBalance]);

  const exceed = useMemo(() => (
    Number(selectedOption.exchangeValue) > currentBalance
  ), [selectedOption.exchangeValue, currentBalance]);

  const handleOnChange = useCallback((_, newValue) => {
    onChange(options.find((option) => option.rewardAmount === newValue.toString())?.uid ?? '');
  }, [options, onChange]);

  return (
    <div className="SliderSelector">
      {title && (
        <span className="SliderSelector__title">
          {title}
        </span>
      )}
      <div className="SliderSelector__slider">
        <Slider
          value={Number(selectedOption.rewardAmount)}
          onChange={handleOnChange}
          step={null}
          marks={options.map(({ rewardAmount }) => ({
            value: Number(rewardAmount),
            label: (Number(rewardAmount) === min || Number(rewardAmount) === max) ? `$${rewardAmount}` : '',
          }))}
          min={min}
          max={max}
          slim
          exceed={exceed}
          trackLimit={trackLimit}
          valueLabelDisplay="auto"
        />
        {exceed && (
          <span className="SliderSelector__slider--exceed">
            You don’t have enough TrashieCash™
          </span>
        )}
      </div>
      <div className="SliderSelector__content">
        <div className="SliderSelector__content--item">
          <span className="SliderSelector__content--item-label">
            Reward value
          </span>
          <div className="SliderSelector__content--item-data">
            <span>{`$${selectedOption.exchangeValue} OFF`}</span>
          </div>
        </div>
        <div className="SliderSelector__content--item">
          <span className="SliderSelector__content--item-label">
            TrashieCash™
          </span>
          <div className="SliderSelector__content--item-data">
            <span>{selectedOption.rewardAmount}</span>
            <TCCoinSvg width="20" height="20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderSelector;
