import { useMemo, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Markdown from 'react-markdown';
import classNames from 'classnames';

import { REWARD_ID_ROUTE_PARAM, ROUTE_MY_REWARDS, ROUTE_REWARD_INFO, ROUTE_REWARDS } from '../../../config/routes';
import { REWARD_AMOUNT_TYPES, REWARD_EXPIRE_AT_TAG } from '../../../config/rewards';
import { getActionType, getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import { formatFullDateUTC } from '../../../utils/dates';
import { getRewardOptionSubtitle, isFundsErrorCode, isLimitErrorCode, isUserLimitErrorCode, replaceRewardTags } from '../../../utils/rewards';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';
import { Flag } from '../../../global/Rewards';
import TermsDisclaimer from '../../../global/TermsDisclaimer/TermsDisclaimer';
import CompoundRadioSelector, { OPTION_STATUS } from '../../../global/CompoundRadioSelector';
import Advice from '../Advice';
import RewardSummary from './RewardSummary';
import SliderSelector from '../SliderSelector';
import TrashieCoin from '../../../images/TC-icon.svg';

import './RewardContent.scss';

const {
  rewards: {
    singleMerchantPage: {
      rewardItem: trackerRewardItem,
    },
  },
} = TRACKER_IDS;

const RewardContentMerchantMain = ({
  rewards,
  rewardItem,
  rewardsAllStatus,
  selectorTitle,
  submitDisabled,
  onSelect,
  onSubmit,
  onShowTerms,
  isDiscountCode,
  currentBalance,
  isDeal,
  rewardProperties,
}) => {
  const [seeMoreHideText, setSeeMoreHideText] = useState(true);
  const history = useHistory();

  const {
    uid: rewardId,
    terms = '',
    expireAt,
    disabled: statusDisabledReward,
    affiliateLink,
    store: {
      imgUrl,
      description: storeDescription,
      logoUrl,
      RewardLogoProperties,
      url,
    },
  } = rewardItem;

  const selectorSectionsMain = useMemo(() => [
    {
      id: 'defaultSection',
      title: '',
      iconTitle: '',
      options: rewards
        .filter(({ disabled, status }) => !(disabled && isUserLimitErrorCode(status)))
        .map(({
          uid,
          name,
          amountType,
          rewardAmount,
          exchangeValue,
          description,
          status,
          disabled,
          tooltipDisabled,
        }) => ({
          id: uid,
          title: REWARD_AMOUNT_TYPES[amountType]?.getText(rewardAmount) ?? '',
          subtitle: getRewardOptionSubtitle(status, exchangeValue) === 'FREE!'
            || getRewardOptionSubtitle(status, exchangeValue) === 'SOLD OUT' ?
            getRewardOptionSubtitle(status, exchangeValue) : (
              <>
                {getRewardOptionSubtitle(status, exchangeValue)}
                <img src={TrashieCoin} className="tcIcon" alt="trashie cash icon" />
              </>
            ),
          description: disabled && isFundsErrorCode(status) ? (
            <>
              <p>{description}</p>
              <p>You need more TrashieCash™</p>
            </>
          ) : description,
          status: !disabled ? OPTION_STATUS.AVAILABLE : (
            (isLimitErrorCode(status) && OPTION_STATUS.FORBIDDEN) ||
            (isUserLimitErrorCode(status) && OPTION_STATUS.CHECKED) ||
            OPTION_STATUS.LOCKED
          ),
          tooltipDisabled,
          trackerProps: {
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerRewardItem),
            [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerRewardItem),
            [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: uid,
            [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: name,
          },
        })),
    },
  ], [rewards]);

  const typeText = useMemo(() => (isDeal ? 'DEAL' : 'REWARD'), [isDeal]);

  const selectorSectionsClaimed = useMemo(() => {
    const redeemedCodesOptions = rewards
      .filter(({ rewardCodes }) => rewardCodes?.length)
      .map(({
        amountType,
        rewardAmount,
        description,
        rewardCodes,
      }) => (
        rewardCodes.map(({ uid: rewardCodeid }) => ({
          id: rewardCodeid,
          title: REWARD_AMOUNT_TYPES[amountType]?.getText(rewardAmount) ?? '',
          subtitle: (
            <Link
              to={ROUTE_REWARD_INFO.replace(REWARD_ID_ROUTE_PARAM, rewardCodeid)}
            >
              VIEW DETAILS
            </Link>
          ),
          description,
          status: OPTION_STATUS.USED,
        }))
      ))
      .flat(Infinity);

    const sections = [];

    if (redeemedCodesOptions.length) {
      sections.push({
        id: 'MyWalletSection',
        title: '',
        iconTitle: '',
        options: redeemedCodesOptions.splice(0, 5),
        extraContent: (
          <Link to={ROUTE_MY_REWARDS}>
            {`VIEW ALL CLAIMED ${typeText}S`}
          </Link>
        ),
      });
    }

    return sections;
  }, [rewards, typeText]);

  return (
    <div className="body-style merchant_main">
      <div className="merchant-info">
        <a href={affiliateLink ?? url}>
          <div
            className="merchant-image"
            alt="merchant image"
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),url(${imgUrl})`,
            }}
          >
            <Flag
              status={rewardsAllStatus}
              properties={rewardProperties}
            />
            <img
              className="merchant-logo"
              src={logoUrl}
              style={{ ...RewardLogoProperties }}
              alt="merchant logo"
            />
          </div>

        </a>
        <div className="description-container">
          <div className={seeMoreHideText && 'description-hidden'}>
            <Markdown className={classNames('markdown', { 'description-hidden': seeMoreHideText })}>
              {storeDescription}
            </Markdown>
          </div>
          {storeDescription && (
            <div className="see-more" onClick={() => setSeeMoreHideText(!seeMoreHideText)} aria-hidden>
              {seeMoreHideText ? 'more' : 'less'}
            </div>
          )}
        </div>
      </div>
      <div className="rewardInfo">
        {rewards.lenght === 0 ? (
          <p className="title">NO REWARDS AVAILABLE FOR THIS MERCHANT</p>
        ) : (
          <>
            <div className="action-container">
              {isDiscountCode ? (
                <SliderSelector
                  value={rewardItem.uid}
                  title={selectorTitle}
                  options={rewards.filter(({ disabled, status }) => (
                    !(disabled && isUserLimitErrorCode(status))
                  ))}
                  onChange={(optionId) => onSelect(optionId)}
                  currentBalance={currentBalance}
                />
              ) : (
                selectorSectionsMain[0]?.options?.length > 0 && (
                  <CompoundRadioSelector
                    name="main"
                    value={rewardItem.uid}
                    title={selectorTitle}
                    sections={selectorSectionsMain}
                    onChange={(optionId) => onSelect(optionId)}
                  />
                )
              )}
              {!isDiscountCode && selectorSectionsClaimed[0]?.options?.length > 0 && (
                <CompoundRadioSelector
                  name="claimed"
                  title="CLAIMED BY YOU"
                  sections={selectorSectionsClaimed}
                />
              )}
              <div className="confirm-button-container">
                <Button
                  className="confirm-button"
                  type={statusDisabledReward ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY}
                  size={BUTTON_SIZE.LARGE}
                  disabled={!submitDisabled && statusDisabledReward}
                  onClick={statusDisabledReward ?
                    () => (history.push(ROUTE_REWARDS)) : onSubmit}
                >
                  {submitDisabled ? `Browse all ${typeText.toLowerCase()}s` : `GET THIS ${typeText}`}
                </Button>
              </div>
            </div>
            {rewardId && (
              <div className="content">
                <RewardSummary
                  rewardItem={rewardItem}
                  title={`${typeText} DETAILS`}
                />
              </div>
            )}
            <TermsDisclaimer
              prefix={(<Advice />)}
              terms={replaceRewardTags(terms, {
                [REWARD_EXPIRE_AT_TAG]: formatFullDateUTC(expireAt),
              })}
              ctaText="VIEW ALL TERMS"
              termsTitle="MERCHANT TERMS"
              onHandleClick={onShowTerms}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RewardContentMerchantMain;
