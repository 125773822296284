/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, {
  createContext, useState, useContext, useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const AlertContext = createContext(undefined);

export const useAlert = () => {
  const alertValue = useContext(AlertContext);
  if (alertValue === undefined) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return alertValue.setAlert;
};

const RenderAlert = () => {
  const { alert, setAlert, error } = useContext(AlertContext);
  console.info({ alert });
  let AlertComponent = null;
  if (alert) {
    if (alert.type === 'notification') {
      AlertComponent = (
        <div className="container-fluid fixed-top">
          <div
            className={`row text-dark shadow-sm border-bottom border-dark py-3 ${alert.className || 'bg-warning'}`}
            ref={node => node?.style.setProperty('background-color', '#FFDD42', 'important')}
          >
            <div className="col-lg-4 offset-lg-4 col-10 text-center">
              {alert.message || 'Something went wrong. Please contact support@fordays.com'}
            </div>
            <div className="col-lg-4 col-2 text-end" style={{ marginLeft: 'auto', width: 'auto' }}>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setAlert(null)}
              />
            </div>
          </div>
        </div>
      );
    }

    if (alert.type === 'alert') {
      AlertComponent = (
        <div
          className="modal fade show modal-background"
          style={{ display: 'block' }}
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content rounded-0 border-dark">
              <div className="modal-header bg-danger text-white">
                Error
              </div>
              <div className="modal-body">
                {alert.message || 'Something went wrong. Please contact support@fordays.com'}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-dark"
                  onClick={() => setAlert(null)}
                  type="button"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      );
      console.warn(error);
    }

    if (alert.type === 'critical') {
      window.alert(alert.message || 'Something went wrong. Please contact support@fordays.com');
      console.error(error);
    }

    if (alert.type === 'passive') {
      console.error(error);
    }
  }

  setTimeout(() => {
    setAlert(null);
  }, 50000);

  return AlertComponent;
};

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);
  const value = useMemo(() => ({ alert, setAlert }), [alert]);
  return (
    <AlertContext.Provider value={value}>
      {alert && <RenderAlert />}
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
