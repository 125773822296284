/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';

import { ROUTE_DASHBOARD } from '../../../config/routes';
import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';

const PublicRoute = ({
  component: Component,
  handleRedirect = true,
  render: customRender,
  ...rest
}) => {
  const { isSignedIn } = useFirebaseAuth();

  return (
    <Route
      {...rest}
      render={(props) => (
        customRender
          ? customRender(props.location)
          : (handleRedirect && isSignedIn && (
            <Redirect to={ROUTE_DASHBOARD} />
          )) || <Component {...props} />
      )}
    />
  );
};

export default PublicRoute;
