import style from './TitleSectionAccount.module.scss';

const { container, titleStyle, subTitleStyle, urlTitleStyle, subTitleSection } = style;
const TitleSectionAccount = ({ title, subTitle, url, actionTitle }) => (
  <div className={container}>
    <div className={titleStyle}>{title}</div>
    {(subTitle || actionTitle) && (
      <div className={subTitleSection}>
        <div className={subTitleStyle}>
          {subTitle}
          {' '}
          <a href={url} className={urlTitleStyle}>{actionTitle}</a>
        </div>
      </div>
    )}
  </div>
);

export default TitleSectionAccount;
