import { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import EyeToggle from '../EyeToggle';

import './InputNew.scss';

const InputNew = ({
  type = 'text',
  id,
  containerClassName,
  className,
  elementClassName,
  label,
  placeholder,
  value,
  errorMessage,
  disabled,
  onFocus,
  onBlur,
  onChange,
  instructions,
  inputRef = undefined,
}) => {
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleOnChange = (event) => {
    const {
      target: {
        value: newValue,
      },
    } = event;

    onChange(newValue);
  };

  const handleOnFocus = () => {
    setFocused(true);

    if (onFocus) {
      onFocus();
    }
  };

  const handleOnBlur = () => {
    setFocused(false);

    if (onBlur) {
      onBlur();
    }
  };

  return (
    <div className={classNames('InputNew', containerClassName)}>
      {label && (
        <p className="InputNew__label">
          {label}
        </p>
      )}
      <div className={classNames(
        'InputNew__element',
        className,
        {
          active: focused,
          error: errorMessage && !instructions,
        },
      )}
      >
        <input
          ref={inputRef}
          type={showPassword ? 'text' : type}
          id={id}
          className={classNames(
            'InputNew__input',
            elementClassName,
            { password: type === 'password' },
          )}
          placeholder={placeholder}
          value={value}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          disabled={disabled}
        />
        {type === 'password' && (
          <EyeToggle
            isVisible={showPassword}
            onClick={() => { setShowPassword(!showPassword); }}
            disabled={_.isEmpty(value)}
          />
        )}
      </div>
      {typeof errorMessage === 'string' && errorMessage !== '' && (
        <p className={instructions ? 'InputNew__instructions' : 'InputNew__error'}>
          {instructions ?? errorMessage}
        </p>
      )}
    </div>
  );
};

export default InputNew;
