import { environmentSelected } from '../../../../config/config';

import './EnvironmentBanner.scss';

const messageCreator = (environment) => {
  const textComponent = [];

  for (let index = 0; index < 5; index++) {
    textComponent.push(
      <div
        key={`EnvironmentBanner-${environment}-${index}`}
        className="EnvironmentBanner__text"
      >
        {`${environment} Environment`}
      </div>,
    );
  }

  return textComponent;
};

const EnvironmentBanner = () => (
  <div className="EnvironmentBanner">
    <div
      className="EnvironmentBanner__content"
      style={{ background: environmentSelected === 'staging' ? 'red' : 'orangered' }}
    >
      {messageCreator(environmentSelected)}
    </div>
  </div>
);

export default EnvironmentBanner;
