const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatCurrencyNumber = (number = 0, numberFormat = USDollar) => numberFormat.format(number);

const formatTrashieCashNumber = (number = 0, className = '') => (
  <p className={className}>
    T
    <sup>$</sup>
    {` ${Number(number).toFixed(2)}`}
  </p>
);

const transactionTypes = {
  withdraw: 1,
  deposit: 2,
};

const isNegativeTransaction = (transactionType) => transactionType === transactionTypes.withdraw;

const getTransactionAmount = (amount, transactionType) => {
  switch (transactionType) {
    case transactionTypes.withdraw:
      return amount * -1;
    case transactionTypes.deposit:
    default:
      return amount;
  }
};

const formatQuantity = (quantity) => {
  const thousand = 1000;
  const million = 1000000;
  const billion = 1000000000;

  let value = quantity;
  let suffix = '';

  if (quantity >= thousand && quantity < million) {
    value = quantity / thousand;
    suffix = 'K';
  }

  if (quantity >= million && quantity < billion) {
    value = quantity / million;
    suffix = 'M';
  }

  if (quantity > billion) {
    value = quantity / million;
    suffix = 'B';
  }

  value = parseFloat(value.toFixed(1));

  if (value % 1 === 0) {
    value = parseFloat(value.toFixed(0));
  } else if (!suffix) {
    value = Math.ceil(value);
  }

  return { value, suffix };
};

export {
  formatCurrencyNumber,
  formatTrashieCashNumber,
  getTransactionAmount,
  isNegativeTransaction,
  formatQuantity,
};
