import { useEffect, useCallback, useState, useRef } from 'react';
import style from './TakeBackBags.module.scss';
import crud from '../../../../api/crud';
import ItemList from '../../global/ItemList';
import ListHeaders from '../../global/ListHeaders';
import LoadingBar from '../../global/LoadingBar';
import ListPaginator from '../../global/ListPaginator';
import { TBB_LIST_BY_USER } from '../../config/service';
import { useAlert } from '../../../providers/AlertProvider';
import TakeBackBagsNoData from '../../global/TakeBackBagsNoData';
import { ANCHOR_MY_TBB } from '../../config/routes';
import MarketingBanner from '../../global/MarketingBanner';
import {
  HOW_WE_RECYCLE_URL,
  TAKE_BACK_BAG_COLLECTION_URL,
  TAKE_BACK_BAG_COTTON_FABRIC_SCRAP_URL,
  TAKE_BACK_BAG_DOUBLE_RAINBOW_URL,
  TAKE_BACK_BAG_KIDS_URL,
  TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
  TAKE_BACK_BAG_URL,
} from '../../config/externalURL';
import TBBBagsImage from '../../images/TBB-bags-desktop.png';
import TBBSingle from '../../images/tbb-single.png';
import TBB3Pack from '../../images/tbb-3-pack.png';
import TBBScrap from '../../images/tbb-scrap.png';
import TBBDouble from '../../images/tbb-double.png';
import TBBSubscribe from '../../images/tbb-subscribe.png';
import TBBKids from '../../images/tbb-kids.png';
import DashboardNewSection from '../DashboardNew/DashboardNewSection/DashboardNewSection';
import TakeBackBagsList from '../../global/TakeBackBagsList';

const {
  main,
  container,
  listContainer,
} = style;

const PAGE_SIZE = 10;

const TBB_ITEMS = [
  {
    id: 1,
    label: 'The single',
    url: TAKE_BACK_BAG_URL,
    imgUrl: TBBSingle,
    bgColor: '#9AD2FF',
  },
  {
    id: 2,
    label: 'The 3-pack',
    url: TAKE_BACK_BAG_RAINBOW_3_PACK_URL,
    imgUrl: TBB3Pack,
    bgColor: '#D8C6FF',
  },
  {
    id: 3,
    label: 'The scrap bag',
    url: TAKE_BACK_BAG_COTTON_FABRIC_SCRAP_URL,
    imgUrl: TBBScrap,
    bgColor: '#CFFADD',
  },
  {
    id: 4,
    label: 'The 6-pack',
    url: TAKE_BACK_BAG_DOUBLE_RAINBOW_URL,
    imgUrl: TBBDouble,
    bgColor: '#D8C6FF',
  },
  {
    id: 5,
    label: 'Subscribe',
    url: TAKE_BACK_BAG_URL,
    imgUrl: TBBSubscribe,
    bgColor: '#9AD2FF',
  },
  {
    id: 6,
    label: 'Kid’s TBB',
    url: TAKE_BACK_BAG_KIDS_URL,
    imgUrl: TBBKids,
    bgColor: '#FFCEE0',
  },
];

const TakeBackBags = () => {
  const timeoutRef = useRef(null);

  const [tbbData, setTbbData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const setAlert = useAlert();

  const userTbb = useCallback(
    async () => {
      setLoading(true);
      try {
        const { count, result } = await crud.get({ path: `${TBB_LIST_BY_USER}?page=${currentPage}&pageSize=${PAGE_SIZE}` });
        const data = result.map((redemption) => {
          let parsedRedemption;
          if (redemption.labeldata) {
            parsedRedemption = {
              ...redemption,
              labeldata: JSON.parse(redemption.labeldata),
            };
          } else {
            parsedRedemption = redemption;
          }
          return parsedRedemption;
        });
        setTbbData(data);
        setTotalRecords(count);
      } catch (error) {
        setAlert({
          type: 'alert',
          message: error.error.message,
          error: error.error.message });
      }
      setLoading(false);
      const mainElement = document.getElementById(ANCHOR_MY_TBB);

      if (mainElement) {
        timeoutRef.current = setTimeout(() => {
          mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
      }
    },
    [currentPage],
  );

  useEffect(() => {
    userTbb();
  }, [currentPage]);

  return (
    <div id={ANCHOR_MY_TBB} className={main}>
      <div className={container}>
        <MarketingBanner
          title="How do we recycle?"
          description="Trashie can handle up to 1M LBS of clothing and textile waste per week"
          ctaLabel="Learn more"
          ctaURL={HOW_WE_RECYCLE_URL}
          backgroundImage={TBBBagsImage}
        />
        <DashboardNewSection
          title="Shop the TBB"
          onClick={() => { window.location.href = TAKE_BACK_BAG_COLLECTION_URL; }}
          contained
        >
          <TakeBackBagsList tbbList={TBB_ITEMS} />
        </DashboardNewSection>
        <ListHeaders
          title="My Take back bags"
          sortedText="Most recently registered"
        />
        {loading ? <LoadingBar /> : (
          <div className={listContainer}>
            {tbbData.length > 0 ? (
              <div>
                <ItemList dataList={tbbData} setTbbData={setTbbData} />
                <ListPaginator
                  pageSize={PAGE_SIZE}
                  totalItems={totalRecords}
                  currentPage={currentPage}
                  onPageSelection={(selectedPage) => {
                    setCurrentPage(selectedPage);
                  }}
                />
              </div>
            ) : (
              <TakeBackBagsNoData />
            )}
          </div>
        )}

      </div>
    </div>

  );
};

export default TakeBackBags;
