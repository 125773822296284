import React, { useState } from 'react';

import classNames from 'classnames';
import InputForm from '../InputForm';
import { Button, BUTTON_TYPE } from '../Button';

import styles from './Form.module.scss';

const {
  customForm,
  formSectionStyle,
  formTitleStyle,
  formSubtitleStyle,
  formRows,
  formRowTitle,
  formRowInputs,
  formRowInputContainer,
  formSectionsButtons,
  formSectionTitle,
  formSectionSubtitle,
} = styles;

const getInitialState = (sections = []) => {
  const state = {};
  const required = {};

  sections.forEach(({ rows: sectionRows }) => {
    sectionRows.forEach(({ inputs }) => {
      inputs.forEach(({
        name,
        defaultValue,
        required: inputRequired,
      }) => {
        state[name] = defaultValue;
        required[name] = inputRequired;
      });
    });
  });

  return {
    state,
    required,
  };
};

function Form({
  onSubmit,
  preform, // pre and post are intended to receive any jsx element to enhance the component
  postform,
  formButtonText,
  formButtonAction,
  formButtonType,
  formTitle,
  formSubtitle,
  formName,
  formStyles,
  sections, // can be several sections as a display way to organize the form, the state is the same
}) {
  const { state, required } = getInitialState(sections);
  const [formState, setFormState] = useState(state);

  const isFormButtonDisabled = () => Object.entries(formState).some(([field, value]) => (
    required[field] === true && !value
  ));

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState);
  };

  const handleFormButtonAction = (event) => {
    event.preventDefault();
    formButtonAction(formState);
  };

  const handleSectionButtonClick = (button) => {
    button.action();
  };

  return (
    <div className={classNames(customForm, formStyles?.form)}>
      {preform}
      <form onSubmit={handleSubmit} name={formName}>
        {formTitle && (
          <h2 className={classNames(formTitleStyle, formStyles?.formTitle)}>
            {formTitle}
          </h2>
        )}
        {formSubtitle && (
          <p className={classNames(formSubtitleStyle, formStyles?.formSubtitle)}>
            {formSubtitle}
          </p>
        )}
        {sections.map((section) => (
          <div
            key={`section-${section.id}`}
            className={classNames(formSectionStyle, formStyles?.formSections)}
          >
            {section.presection}
            {section.title && (
              <h2 className={classNames(formSectionTitle, formStyles?.formSectionTitle)}>
                {section.title}
              </h2>
            )}
            {section.subtitle && (
              <p className={classNames(formSectionSubtitle, formStyles?.formSectionSubtitle)}>
                {section.subtitle}
              </p>
            )}
            {section.rows?.map((row) => (
              <div
                key={`row-${row.id}`}
                className={classNames(formRows, formStyles?.formRows)}
              >
                {row.title && (
                  <div className={classNames(formRowTitle, formStyles?.formRowTitle)}>
                    {row.title}
                  </div>
                )}
                <div className={classNames(formRowInputs, formStyles?.formRowInputs)}>
                  {
                    row.inputs.map((input) => (
                      <div
                        key={`input-${input.id}`}
                        className={classNames(
                          formRowInputContainer,
                          formStyles?.formRowInputContainer,
                        )}
                      >
                        <InputForm
                          type={input.type}
                          name={input.name}
                          value={formState[input.name]}
                          onChange={handleChange}
                          defaultValue={input.defaultValue}
                          placeholder={input.placeholder}
                          label={input.label || ''}
                          classes={input.classes}
                          disabled={input.disabled}
                          required={input.required}
                          preinput={input.preinput}
                          postinput={input.postinput}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            ))}
            <div className={classNames(formSectionsButtons, formStyles?.formSectionsButtons)}>
              {section.buttons?.map((button) => (
                <Button
                  type={BUTTON_TYPE.SECONDARY}
                  onClick={() => handleSectionButtonClick(button)}
                  style={button.style}
                >
                  <span>{button.text}</span>
                </Button>
              ))}
            </div>
            {section.postsection}
          </div>
        ))}
        {formButtonText && (
          <Button
            type={formButtonType}
            disabled={isFormButtonDisabled()}
            onClick={handleFormButtonAction}
          >
            <span>{formButtonText}</span>
          </Button>
        )}
      </form>
      {postform}
    </div>
  );
}

export default Form;
