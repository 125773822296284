import SideNav from '../../SideNav/SideNav';

import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';

import styles from './HeaderAccount.module.scss';

const {
  main,
  container,
  menu,
} = styles;

const HeaderAccount = () => {
  const { isSignedIn, loading } = useFirebaseAuth();

  if (!isSignedIn && !loading) {
    return null;
  }

  return (
    <div className={main}>
      <div className={container}>
        <SideNav iconClassName={menu} />
      </div>
    </div>
  );
};

export default HeaderAccount;
