import {
  createContext, useState, useEffect, useContext, useCallback,
} from 'react';
import { auth, fetchUserInfo, storage, firestore, now } from '../../../utils/firebase';
import { getUser, syncUser } from '../../trashie/utils/service';

const FirebaseAuthContext = createContext(undefined);

export function useFirebaseAuth() {
  const context = useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error(
      'useFirebaseAuth must be used within a FirebaseProvider',
    );
  }
  const contexty = {
    isSignedIn: !!context.user,
    user: context.user,
    isLoading: context.isLoading,
    fbKey: context.fbKey,
    userData: context.userData,
    reloadUserData: context.getAndSetUserData,
    storage,
    firestore,
    now,
    signOut: context.signOut,
  };

  return contexty;
}

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const fetchUserData = useCallback(async (userAuth) => {
    const userInfo = await fetchUserInfo(userAuth.uid);
    const { data: dbData, error: dbError } = await getUser(userAuth.uid);

    let data = {};
    let error;

    if (dbError) {
      error = dbError;
    } else if (!dbData) {
      const {
        data: syncData,
        error: syncError,
      } = await syncUser(userAuth.uid, userAuth.email);

      if (syncError) {
        error = syncError;
      } else {
        data = syncData;
      }
    } else {
      data = dbData;
    }

    return {
      ...userInfo,
      ...data,
      error,
    };
  }, []);

  const signOut = useCallback(async () => {
    await auth.signOut();

    setUser(null);
    setUserData(null);
  }, []);

  const getAndSetUserData = useCallback(async () => {
    if (!user) {
      return;
    }

    setIsLoading(true);
    setUserData(await fetchUserData(user));
    setIsLoading(false);
  }, [user]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { user, isLoading, userData, getAndSetUserData, signOut };

  useEffect(() => {
    if (user) {
      getAndSetUserData();
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        window.localStorage.setItem('loggedIn', true);
        setUser(userAuth);
        setIsLoading(false);
      } else {
        window.localStorage.setItem('loggedIn', false);
        window.localStorage.removeItem('logged_into_closet_cash');
        setIsLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};
