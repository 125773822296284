import Offer from '../Offer';

import styles from './OffersList.module.scss';

const {
  offers,
} = styles;

const OffersList = ({ offersList, isDashBoard }) => (
  <div className={offers}>
    {offersList.map((offerItem) => (
      <Offer key={`offer-${offerItem.merchantId}`} offerItem={offerItem} isDashBoard={isDashBoard} />
    ))}
  </div>
);

export default OffersList;
