import * as React from 'react';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material';

const StyledRadio = styled((props) => <Radio {...props} size="small" />)({
  color: '#1B1A1B',
  padding: '2px',
  '&.Mui-checked': {
    color: '#1B1A1B',
  },
});

export default StyledRadio;
