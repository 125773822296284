import React from 'react';

export default function ArrosLeft({ width = 24, height = 24, color = '#696969' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_14243_4475)">
        <path d="M20.6371 2.28044V6.60032M22.797 4.44038H18.4771M3.35752 17.4V19.56M4.43749 18.48H2.27754M9.76931 15.7801C9.67289 15.4063 9.47808 15.0653 9.20515 14.7923C8.93222 14.5194 8.59113 14.3246 8.21739 14.2282L1.59176 12.5196C1.47872 12.4876 1.37923 12.4195 1.30839 12.3257C1.23755 12.232 1.19922 12.1177 1.19922 12.0002C1.19922 11.8827 1.23755 11.7684 1.30839 11.6746C1.37923 11.5809 1.47872 11.5128 1.59176 11.4807L8.21739 9.77112C8.591 9.67479 8.932 9.48015 9.20492 9.20742C9.47784 8.93469 9.67272 8.59383 9.76931 8.22028L11.4778 1.59465C11.5096 1.48117 11.5776 1.38119 11.6715 1.30997C11.7654 1.23875 11.88 1.2002 11.9978 1.2002C12.1157 1.2002 12.2303 1.23875 12.3242 1.30997C12.4181 1.38119 12.4861 1.48117 12.5178 1.59465L14.2253 8.22028C14.3217 8.59403 14.5165 8.93511 14.7894 9.20804C15.0624 9.48097 15.4034 9.67578 15.7772 9.7722L22.4028 11.4796C22.5168 11.5111 22.6172 11.579 22.6888 11.673C22.7604 11.7671 22.7992 11.882 22.7992 12.0002C22.7992 12.1184 22.7604 12.2333 22.6888 12.3273C22.6172 12.4214 22.5168 12.4893 22.4028 12.5207L15.7772 14.2282C15.4034 14.3246 15.0624 14.5194 14.7894 14.7923C14.5165 15.0653 14.3217 15.4063 14.2253 15.7801L12.5168 22.4057C12.485 22.5192 12.417 22.6192 12.3231 22.6904C12.2292 22.7616 12.1146 22.8002 11.9967 22.8002C11.8789 22.8002 11.7643 22.7616 11.6704 22.6904C11.5765 22.6192 11.5085 22.5192 11.4767 22.4057L9.76931 15.7801Z" stroke={color} strokeWidth="1.79995" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_14243_4475">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
