import Loader from '../Loader';

const ContentWrapper = ({
  children,
  className,
  isLoading = false,
  loadingMessage = null,
  size = 'md',
  embeeded,
}) => (
  <div className={`p-16 ${embeeded ? ' container-content-embeeded bg-cc-green' : ''}`}>
    {isLoading ? (
      <div className="d-flex text-center align-items-center justify-content-center w-100 h-100">
        <Loader message={loadingMessage || 'Loading...'} />
      </div>
    ) : (
      <div
        className={`container-content-${size || 'md'} mx-auto ${
          className || ''
        } mt-lg-32`}
      >
        {children}
      </div>
    )}
  </div>
);

export default ContentWrapper;
