import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)({
  fontFamily: 'GT Maru',
  margin: '0 0 0 -2px',
  gap: '6px',
  '& .MuiFormControlLabel-label': {
    width: '100%',
    color: '#696969',
    fontFamily: 'GT Maru',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '175%',
  },
});

export default StyledFormControlLabel;
