import { TRASHIE_GA_TAG_ID } from '../config/config';

import { isProd, isTrashieApp } from '../../../config/config';

const script = isTrashieApp() ? (
  'window.dataLayer = window.dataLayer || [];' +
  'function gtag(){dataLayer.push(arguments);}' +
  "gtag('js', new Date());" +
  `gtag('config', '${isProd() ? TRASHIE_GA_TAG_ID.PRODUCTION : TRASHIE_GA_TAG_ID.STAGING}');`
) : null;

export default script;
