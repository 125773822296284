import { useState, memo } from 'react';
import Box from '@mui/material/Box';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button';
import ArrowSorting from '../../images/arrow-to-bottom.svg';
import CheckSvg from '../SvgComponents/Check_svg';
import { isMobile, SIZE_XS, SIZE_SM } from '../../../../hooks/useBreakPoint';
import CloseSvg from '../SvgComponents/Close_svg';
import Dialog from '../Mui/Dialog';
import Drawer from '../Mui/Drawer';

import './Sorting.scss';

const ModalItems = (
  { sortingOptions,
    handleSortingOption,
    sortingOptionSelected },
) => (sortingOptions.map((option) => (
  <div
    key={option.id}
    aria-hidden
    className="Sorting__modal--options--option"
    onClick={() => handleSortingOption(option)}
  >
    {option.label}
    {sortingOptionSelected.id === option.id &&
      <CheckSvg /> }
  </div>
)));

const ModalBody = memo(({
  sortingOptions,
  handleSortingOption,
  sortingOptionSelected,
  setShowModal }) => (
    <Box className="Sorting__modal">
      <div className="Sorting__modal--title">
        <div className="Sorting__modal--title--text">Sort by</div>
        <div onClick={() => setShowModal(false)} aria-hidden className="Sorting__modal--title--close ">
          <CloseSvg stroke="#A9A9A9" />
        </div>
      </div>
      <div className="Sorting__modal--options">
        <ModalItems
          sortingOptions={sortingOptions}
          handleSortingOption={handleSortingOption}
          sortingOptionSelected={sortingOptionSelected}
        />
      </div>
    </Box>
));

const Sorting = ({ sortingOptions, sortingOptionSelected, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentValue, setCurrentValue] = useState(sortingOptionSelected.id);
  const isMobileXsSm = isMobile([SIZE_XS, SIZE_SM]);
  const handleSortingOption = (option) => {
    if (currentValue === option.id) {
      setShowModal(false);
      return;
    }
    onChange(option);
    setShowModal(false);
    setCurrentValue(option.id);
  };

  return (
    <div className="Sorting">
      {isMobileXsSm ? (
        <Drawer open={showModal} anchor="bottom" onClose={() => setShowModal(false)}>
          <ModalBody
            sortingOptions={sortingOptions}
            handleSortingOption={handleSortingOption}
            sortingOptionSelected={sortingOptionSelected}
            setShowModal={setShowModal}
          />
        </Drawer>
      ) : (
        <Dialog open={showModal} onClose={setShowModal}>
          <ModalBody
            sortingOptions={sortingOptions}
            handleSortingOption={handleSortingOption}
            sortingOptionSelected={sortingOptionSelected}
            setShowModal={setShowModal}
          />
        </Dialog>
      )}
      <Button
        size={BUTTON_SIZE.LARGE}
        type={BUTTON_TYPE.SECONDARY}
        className="Sorting__button"
        onClick={() => setShowModal(true)}
      >
        <img src={ArrowSorting} alt="Arrow Sorting" />
        <div className="Sorting__button--label">{sortingOptionSelected.label}</div>
      </Button>
    </div>
  );
};

export default Sorting;
