import Rewards from './Rewards';
import Card from './Card';
import Cover, { COVER_TYPE } from './Cover';
import Flag from './Flag';

export default Rewards;
export {
  Card,
  Cover,
  Flag,
  COVER_TYPE,
};
