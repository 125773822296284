import { useEffect } from 'react';

import { isTrashieApp } from '../config/config';

const useExternalScript = (url) => {
  useEffect(() => {
    let head;
    let script;

    if (isTrashieApp()) {
      head = document.querySelector('head');
      script = document.createElement('script');

      script.setAttribute('src', url);

      head.appendChild(script);
    }

    return () => {
      if (isTrashieApp()) {
        head.removeChild(script);
      }
    };
  }, [url]);
};

export default useExternalScript;
