import { useCallback, useEffect, useState } from 'react';

import GeneralModal from '../../../global/GeneralModal';
import { patchReward } from '../../../utils/service';
import {
  isLimitErrorCode,
  isNotFoundErrorCode,
  isUserLimitErrorCode,
} from '../../../utils/rewards';
import {
  RewardContentError,
  RewardContentSuccess,
  RewardContentLoading,
} from '../RewardContent';

const MODAL_STATE = {
  LOADING: 'loading',
  ERROR: 'error',
  FINAL: 'final',
};

const RedeemModal = ({
  rewardItem,
  showModal,
  onClose,
}) => {
  const [modalState, setModalState] = useState(MODAL_STATE.LOADING);
  const [errorId, setErrorId] = useState(null);
  const [redeemedReward, setRedeemedReward] = useState(null);

  const redeemReward = useCallback(async () => {
    setModalState(MODAL_STATE.LOADING);

    const {
      data: rewardData,
      error,
    } = await patchReward(rewardItem.uid);

    if (error) {
      if (
        isLimitErrorCode(error.code) ||
        isNotFoundErrorCode(error.code) ||
        isUserLimitErrorCode(error.code)
      ) {
        setErrorId(error.code);
      } else {
        setErrorId(null);
      }

      setModalState(MODAL_STATE.ERROR);
      setRedeemedReward(null);
      return;
    }

    setRedeemedReward(rewardData.rewardCode);
    setErrorId(null);
    setModalState(MODAL_STATE.FINAL);
  }, [rewardItem]);

  const getContent = useCallback(() => {
    switch (modalState) {
      case MODAL_STATE.LOADING:
        return (
          <RewardContentLoading
            rewardItem={rewardItem}
            isDeal
          />
        );
      case MODAL_STATE.ERROR:
        return (
          <RewardContentError
            errorId={errorId}
            onBack={onClose}
            isDeal
          />
        );
      case MODAL_STATE.FINAL:
        return (
          <RewardContentSuccess
            rewardItem={redeemedReward}
            onFinish={onClose}
          />
        );
      default:
        return null;
    }
  }, [modalState, rewardItem, redeemedReward, errorId]);

  useEffect(() => {
    if (showModal) {
      redeemReward();
    }
  }, [showModal]);

  return (
    <GeneralModal
      onClose={onClose}
      showModal={showModal}
      showModalHeader={false}
      showModalFooter={false}
      canClose={modalState === MODAL_STATE.ERROR}
      showClose={modalState === MODAL_STATE.ERROR}
    >
      {getContent()}
    </GeneralModal>
  );
};

export default RedeemModal;
