import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToSection = () => {
  const scrolledRef = useRef(false);
  const timeoutRef = useRef(null);
  const { hash } = useLocation();
  const hashRef = useRef(hash);

  useEffect(() => {
    const doScroll = (elementId, maxRetries = 3) => {
      const element = document.getElementById(elementId);

      if (element) {
        timeoutRef.current = setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          scrolledRef.current = true;
        }, 1000);
      } else if (maxRetries > 0) {
        timeoutRef.current = setTimeout(() => {
          doScroll(element, maxRetries - 1);
        }, 500);
      } else {
        clearTimeout(timeoutRef.current);
      }
    };

    if (hash) {
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }

      if (!scrolledRef.current) {
        const elementId = hash.replace('#', '');

        doScroll(elementId);
      }
    }

    return () => clearTimeout(timeoutRef.current);
  });
};

export default useScrollToSection;
