import classNames from 'classnames';
import './InfoSection.scss';

const InfoSection = ({ titleText = '', descriptionText = '', titleStyles }) => (
  <div className="InfoSection">
    <div className={classNames('InfoSection__title', titleStyles)}>{titleText}</div>
    {descriptionText && (
      <div className="InfoSection__description">{descriptionText}</div>
    )}
  </div>
);

export default InfoSection;
