import { useCallback } from 'react';

import MarketingDesktopWebp from '../../images/marketing-desktop.webp';
import Marketing576Svg from '../../images/marketing-576.svg';
import Marketing768Svg from '../../images/marketing-768.svg';
import Marketing992Svg from '../../images/marketing-992.svg';
import Marketing1200Svg from '../../images/marketing-1200.svg';

import { useBreakpoint, SIZE_LG, SIZE_MD, SIZE_SM, SIZE_XS } from '../../../../hooks/useBreakPoint';

import './MarketingInfo.scss';

const MarketingInfo = () => {
  const size = useBreakpoint();

  const getImage = useCallback(() => {
    switch (size) {
      case SIZE_XS:
        return Marketing576Svg;
      case SIZE_SM:
        return Marketing768Svg;
      case SIZE_MD:
        return Marketing992Svg;
      case SIZE_LG:
        return Marketing1200Svg;
      default:
        return MarketingDesktopWebp;
    }
  }, [size]);

  return (
    <div className="MarketingInfo">
      <img
        src={getImage()}
        alt="marketing login"
      />
    </div>
  );
};

export default MarketingInfo;
