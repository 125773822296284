import React, { useCallback, useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import closeIcon from '../../images/close-icon.svg';

import './GeneralModal.scss';

/**
 * Usage:
 * From the calling component handle the showModal state
 *   const [showModal, setShowModal] = useState(false);
 *
 *   const toggleModal = () => {
 *     setShowModal((prev) => !prev);
 *   };
 *
 *   const handleClose = () => {
 *     toggleModal();
 *   };
 *
 * Then place the modal like:
 *
 *        <Modal
 *           title="My  Modal"
 *           onOpen={handleOpen}
 *           onClose={handleClose}
 *           saveButtonStyles={getMyGiftCardButton}
 *           saveModalText="Save"
 *           showModal={showModal}
 *         >
 *           <p>This is the content of the modal.</p>
 *         </Modal>
 *
 * @param {*} param0
 * @returns
 */
const GeneralModal = ({
  title,
  children,
  footer,
  onOpen,
  onClose,
  closeModalText,
  saveModalText,
  saveButtonStyles,
  closeButtonStyles,
  showModal,
  onSave,
  customCloseModalButton,
  showModalHeader = true,
  showModalFooter = true,
  canClose = true,
  showClose = false,
}) => {
  const [show, setShow] = useState(showModal);

  useEffect(() => {
    setShow(showModal);
    if (showModal && onOpen) {
      onOpen();
    }
  }, [showModal]);

  const handleClose = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSave = () => {
    handleClose();
    if (onSave) {
      onSave();
    }
  };

  const closeButton = useCallback(() => (
    customCloseModalButton || (
      <Button variant="link" onClick={handleClose} className="closeModalButtonHeader">
        <img className="iconClose" src={closeIcon} alt="close-icon" />
      </Button>
    )
  ), [customCloseModalButton, handleClose]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="GeneralModal"
      backdropClassName="GeneralModal__BackDrop"
      contentClassName="GeneralModal__Content"
      backdrop={canClose || 'static'}
      keyboard={canClose}
      autoFocus
    >
      {showModalHeader && (
        <Modal.Header className="GeneralModal__Header">
          {title && <Modal.Title className="GeneralModal__Header--Title">{title}</Modal.Title>}
          {showClose && closeButton()}
        </Modal.Header>
      )}
      <Modal.Body className="GeneralModal__Body">
        {!showModalHeader && showClose && closeButton()}
        {children}
      </Modal.Body>
      {showModalFooter && (
        <Modal.Footer className="GeneralModal__Footer">
          {footer || (
            <>
              {closeModalText &&
                (
                  <Button variant="secondary" onClick={handleClose} className={`closeModalButton ${closeButtonStyles}`}>
                    {closeModalText}
                  </Button>
                )}
              {saveModalText &&
                (
                  <Button variant="primary" onClick={handleSave} className={`saveModalButton ${saveButtonStyles}`}>
                    {saveModalText}
                  </Button>
                )}
            </>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default GeneralModal;
