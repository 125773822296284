import tCoinLogo from '../../../images/t-coin-logo.svg';
import { ROUTE_LOGIN } from '../../../config/routes';
import { getActionType, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import { isTrashieApp, trashieRoute } from '../../../../../config/config';

import './OfferItem.scss';

const {
  offers: {
    offerItem: trackerOfferItem,
  },
} = TRACKER_IDS;

const OfferItem = ({
  offerItem: {
    merchantId,
    name,
    offer,
    logoUrl,
    url,
  },
  isDashBoard,
}) => (
  <a
    className="OfferItem"
    href={isDashBoard
      ? url
      : `${!isTrashieApp() ? trashieRoute() : ''}${ROUTE_LOGIN}?uri=${url}`}
    {...{
      [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerOfferItem),
      [POSTHOG_CAPTURE_ATTRIBUTES.OFFER_MERCHANT_ID]: merchantId,
      [POSTHOG_CAPTURE_ATTRIBUTES.OFFER_MERCHANT_NAME]: name,
    }}
  >
    <div
      className="OfferItem__imgContainer"
      style={{
        backgroundImage: `url(${logoUrl || tCoinLogo})`,
      }}
    />
    <div className="OfferItem__details">
      <div className="OfferItem__details--activateButton">
        {offer}
      </div>
    </div>
  </a>
);

export default OfferItem;
