import { useCallback, useState, useEffect } from 'react';
import { getSustainabilityImpactUser } from '../../utils/service';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../../providers/AlertProvider';
import { formatQuantity } from '../../utils/numbers';

import './ImpactMetrics.scss';

const defaultWinsData = {
  base: [],
  impact: [],
  redemptions: 0,
};

const equivalenceMetrics = {
  landfillDiversion: 'avocados',
  co2EmissionDiversion: 'suvMiles',
  waterSaved: 'laundryLoads',
};

const emptyMetrics = {
  landfillDiversion: 'Get Started',
  co2EmissionDiversion: 'Act Now',
  waterSaved: 'Don’t Wait',
};

const ImpactMetrics = () => {
  const { isLoading: isFirebaseAuthLoading, user } = useFirebaseAuth();
  const setAlert = useAlert();

  const [winsData, setWinsData] = useState(defaultWinsData);
  const [isLoading, setIsLoading] = useState(true);

  const loadWins = useCallback(async () => {
    setIsLoading(true);

    const { data: userWins, error } = await getSustainabilityImpactUser();

    if (error) {
      setAlert({
        type: 'alert',
        message: error,
        error,
      });
      setWinsData(defaultWinsData);
      setIsLoading(false);

      return;
    }

    setWinsData(userWins);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isFirebaseAuthLoading && user) {
      loadWins();
    }
  }, [isFirebaseAuthLoading, user]);

  const formaterNumber = (quantity) => {
    const { value, suffix } = formatQuantity(quantity);
    return `${value}${suffix}`;
  };

  const metricsLabel = (metricId) => {
    const { unit, quantity, equivalences } =
    winsData.impact.find((item) => item.id === metricId) ?? { unit: '', quantity: 0, equivalences: [] };
    const { label: eqLabel, quantity: eqQuantity } =
     equivalences.find((item) => item.id === equivalenceMetrics[metricId]) ?? {};
    return (
      quantity === 0 || !equivalences || isLoading ? emptyMetrics[metricId] : (
        <div className="ImpactMetrics__container--content--item--value--metric">
          <div className="ImpactMetrics__container--content--item--value--metric--number">
            {`${formaterNumber(quantity)} ${unit}`}
          </div>
          <div className="ImpactMetrics__container--content--item--value--metric--equivalence">
            {`= ${formaterNumber(eqQuantity)} ${eqLabel}`}
          </div>
        </div>
      )
    );
  };

  return (
    <div className="ImpactMetrics">
      <div className="ImpactMetrics__container">
        <div className="ImpactMetrics__container--title">
          Your Impact
        </div>
        <div className="ImpactMetrics__container--content">
          <div className="ImpactMetrics__container--content--item">
            <div className="ImpactMetrics__container--content--item--title">
              Landfill diverted
            </div>
            <div className="ImpactMetrics__container--content--item--value">
              {metricsLabel('landfillDiversion')}
            </div>
          </div>
          <div className="ImpactMetrics__container--content--middleItem">
            <div className="ImpactMetrics__container--content--item--title">
              CO2 DIVERTED
            </div>
            <div className="ImpactMetrics__container--content--item--value">
              {metricsLabel('co2EmissionDiversion')}
            </div>
          </div>
          <div className="ImpactMetrics__container--content--lastItem">
            <div className="ImpactMetrics__container--content--item--title">
              WATER SAVED
            </div>
            <div className="ImpactMetrics__container--content--item--value">
              {metricsLabel('waterSaved')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactMetrics;
