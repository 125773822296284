import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import Loader from '../../../global/Loader';

import { ROUTE_LOGIN } from '../../config/routes';

import styles from './Logout.module.scss';

const { main, container } = styles;

const Logout = () => {
  const history = useHistory();
  const posthog = usePostHog();
  const { signOut } = useFirebaseAuth();

  useEffect(() => {
    const logout = async () => {
      await signOut();

      sessionStorage.removeItem('logged_into_closet_cash');
      sessionStorage.removeItem('closet_cash_balance');

      posthog.reset();

      history.push(ROUTE_LOGIN);
    };

    logout();
  }, []);

  return (
    <div className={main}>
      <div className={container}>
        <Loader message="...logging out" />
      </div>
    </div>
  );
};

export default Logout;
