import { useCallback, useState, useEffect } from 'react';
import { useAlert } from '../components/providers/AlertProvider';
import { getConfigurations } from '../components/trashie/utils/service';

const useTbbAmountsExchange = () => {
  const [tbbAmount, setTbbAmount] = useState();
  const [tbbValue, setTbbValue] = useState();
  const setAlert = useAlert();

  const tbbExchangeConfig = useCallback(
    async () => {
      const { value: configValue, error } = await getConfigurations('trashieCashTbbExchangeAmounts');
      if (error) {
        setAlert({
          type: 'alert',
          message: error,
          error,
        });
      } else {
        setTbbAmount(configValue.tbbAmount);
        setTbbValue(configValue.tbbValue);
      }
    },
    [],
  );

  useEffect(() => {
    tbbExchangeConfig();
  }, []);

  return { tbbAmount, tbbValue };
};

export default useTbbAmountsExchange;
