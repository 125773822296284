import { usePinInput } from 'react-pin-input-hook';
import classNames from 'classnames';

import './InputPin.scss';

const InputPin = ({
  className,
  isValidPin,
  pinValues = Array(6).fill(''),
  setPinValues,
  errorMessage = 'Invalid code. Verify it and try again or get a new code.',
}) => {
  const { fields } = usePinInput({
    values: pinValues,
    onChange: (nPinValues) => {
      setPinValues(nPinValues);
    },
  });

  return (
    <div className={classNames('InputPin', className)}>
      <div className="InputPin__container">
        {fields.map((propsField, index) => (
          <input
            // eslint-disable-next-line react/no-array-index-key
            key={`pin-field-${index}`}
            className={classNames('InputPin__container--pin-field', { error: !isValidPin })}
            {...propsField}
            placeholder="-"
            value={pinValues[index]}
          />
        ))}
      </div>
      {!isValidPin && (
        <span className="InputPin__error">{errorMessage}</span>
      )}
    </div>
  );
};

export default InputPin;
