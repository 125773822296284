import { useHistory } from 'react-router-dom';

import { ROUTE_DEALS, ROUTE_REWARDS } from '../../../config/routes';
import { SUPPORT_URL } from '../../../config/externalURL';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';
import {
  REWARDS_NO_MORE_CODES,
  REWARDS_NO_MORE_CODES_PER_USER,
} from '../../../utils/errors';

import './RewardContent.scss';

const errorMessages = {
  [REWARDS_NO_MORE_CODES]: {
    title: 'We’re sorry!',
    subtitle: 'We’ve run out of this reward',
    instruction: 'Check out other great rewards available in your dashboard',
  },
  [REWARDS_NO_MORE_CODES_PER_USER]: {
    title: 'reward limit reached',
    subtitle: 'You’ve reached the limit for this reward',
    instruction: 'Check out other great rewards available in your dashboard',
  },
};

const ModalContentError = ({
  errorId,
  onBack,
  isDeal,
}) => {
  const history = useHistory();

  return (
    <div className="body-style error-modal">
      <div className="body-top">
        <div className="bold-title">
          OOPS!
        </div>
        <div className="error-text">
          <p className="title" style={errorId && { textAlign: 'center' }}>
            { errorMessages[errorId]?.subtitle || `We encountered an error while processing your ${isDeal ? 'deal' : 'reward'}`}
          </p>
          <p className="description" style={errorId && { textAlign: 'center' }}>
            {
              errorMessages[errorId]?.instruction || 'Retry the action after a few seconds.'
            }
          </p>
          <p className="description">
            { !errorId && 'If the issue persists, please contact support.'}
          </p>
        </div>
        <div className="error-footer">
          <Button
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            onClick={() => {
              history.push(isDeal ? ROUTE_DEALS : ROUTE_REWARDS);
              onBack?.();
            }}
          >
            {`BACK TO ${isDeal ? 'DEALS' : 'REWARDS'}`}
          </Button>
          {!errorId && (
          <a className="contact-support-btn" href={SUPPORT_URL} target="_blank" rel="noreferrer">
            Contact support
          </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalContentError;
