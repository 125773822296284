import CompoundRadioOption from './CompoundRadioOption';

import './CompoundRadioSelector.scss';

/*
Example:
  <CompoundRadioSelector
    name='name'
    value='firstOption'
    title='Title'
    sections={[
      {
        id: 'firstSection',
        title: 'First Section',
        options: [
          {
            id: 'firstOption',
            title: 'First Option Value',
            subtitle: 'First Option Subtitle',
            description: 'First Option Description',
            status: OPTION_STATUS.AVAILABLE,
            disabled: false,
            trackerProps: {
              some-tracker-id: element.tracker.id,
              ...
            },
          },
          {
            id: 'secondOption',
            title: 'Second Option Value',
            subtitle: 'Second Option Subtitle',
            description: 'Second Option Description',
            status: OPTION_STATUS.USED,
            disabled: true,
            tooltipDisabled: 'Tooltip for Second Option when disabled',
          },
          ...
        ],
      },
      ...
    ]}
    onChange={(optionId) => updateValue(optionId)}
  />
*/

const CompoundRadioSelector = ({
  name,
  value,
  title,
  sections,
  onChange,
}) => (
  <div className="CompoundRadioSelector">
    {title && (
      <span className="CompoundRadioSelector__title">
        {title}
      </span>
    )}
    {sections.map(({
      id: sectionId,
      title: sectionTitle,
      iconTitle: sectionIconTitle,
      options: sectionOptions,
      extraContent: sectionExtraContent,
    }) => (
      <div
        key={`CompoundRadioSelector-${sectionId}`}
        className="CompoundRadioSelector__section"
      >
        {(sectionTitle || sectionIconTitle) && (
          <div className="CompoundRadioSelector__section--title">
            {sectionIconTitle && (
              <img
                className="CompoundRadioSelector__section--title-icon"
                src={sectionIconTitle}
                alt="icon"
              />
            )}
            {sectionTitle && (
              <div className="CompoundRadioSelector__section--title-text">
                {sectionTitle}
              </div>
            )}
          </div>
        )}
        {sectionOptions.map(({
          id: optionId,
          title: optionTitle,
          subtitle,
          description,
          status,
          tooltipDisabled,
          trackerProps,
        }) => (
          <CompoundRadioOption
            key={`CompoundRadioOption-${name}-${optionId}`}
            id={optionId}
            name={name}
            title={optionTitle}
            subtitle={subtitle}
            description={description}
            value={value}
            status={status}
            tooltip={tooltipDisabled}
            onClick={onChange}
            trackerProps={trackerProps}
          />
        ))}
        {sectionExtraContent && (
          <div className="CompoundRadioSelector__section--extra">
            {sectionExtraContent}
          </div>
        )}
      </div>
    ))}
  </div>
);

export default CompoundRadioSelector;
