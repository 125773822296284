import { useState, useCallback } from 'react';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { emailFormat } from '../../../../utils/emailFormatValidator';
import Input from '../../../../global/Input';
import InfoSection from '../../../../global/InfoSection';
import { getNextButtonStyles } from '../../../../utils/tbbRegistration';

import './EmailForm.scss';

const EmailForm = ({ storeConfig, email, onEmailChange, onSubmit }) => {
  const [formatError, setFormatError] = useState(false);

  const handleOnClick = useCallback(
    () => {
      if (emailFormat(email)) {
        onSubmit();
      } else {
        setFormatError(true);
      }
    },
    [email],
  );

  const handleEmailChange = useCallback(
    (e) => {
      setFormatError(false);
      onEmailChange(e);
    },
    [email],
  );

  return (
    <div>
      <div className="EmailForm">
        <div>
          <div className="EmailForm__infoContainer">
            <InfoSection
              titleText="What’s your email?"
              descriptionText={storeConfig?.registerTbbFlow.enterEmailStep.descriptionText}
            />
          </div>
          <div className="EmailForm__emailContainer">
            <Input
              type="text"
              id="email"
              label="Email*"
              value={email}
              errorMessage={formatError ? 'Oops, seems like this email needs a fix.' : null}
              onChange={handleEmailChange}
              containerClassName="EmailForm__emailContainer--inputEmail"
            />
          </div>
        </div>

        <div className="EmailForm__buttonContainer">
          <Button
            className="EmailForm__buttonContainer--button"
            type={BUTTON_TYPE.QUIRTARY}
            size={BUTTON_SIZE.LARGE}
            style={getNextButtonStyles(storeConfig)}
            onClick={handleOnClick}
            disabled={!email}
          >
            CONTINUE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
