import * as React from 'react';

import Style from './InformativeBanner.module.scss';

const {
  main,
  container,
  text,
} = Style;

const InformativeBanner = ({ mainText, ctaChildren }) => (
  <div className={main}>
    <div className={container}>
      <p className={text}>
        {mainText}
      </p>
      {ctaChildren}
    </div>
  </div>
);

export default InformativeBanner;
