/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, Placeholder, Row, Tab, Pagination, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import * as yup from 'yup';
import crud from '../../../api/crud';
import ContentWrapper from '../../global/ContentWrapper';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../providers/AlertProvider';
import { isNullOrEmpty } from '../../trashie/utils/utils';

export const intArray = length => Array.from(new Array(length));

const tabs = {
  addressList: 'addressList',
  addressDetail: 'addressDetail',
};

const Addresses = () => {
  const [pageTab, setPageTab] = useState(tabs.addressList);
  const { user } = useFirebaseAuth();
  const setAlert = useAlert();

  // Address Fetch Logic
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState({ id: '-1' });
  const [customerId, setCustomerId] = useState(null);
  const [LoadingAddresses, setLoadingAddresses] = useState(false);

  // Address Form Logic
  const [AddressForm, setAddressForm] = useState({ id: null, country: 'United States' });
  const [AddressFormValidationErrors, setAddressFormValidationErrors] = useState({});
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [provinceCode, setProvinceCode] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [provinceCodeError, setProvinceCodeError] = useState(false);
  const [zipError1, setZipError1] = useState(false);
  const [zipError2, setZipError2] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [refetch, setRefetch] = useState(true);
  const isEditAddress = AddressForm.id !== null;
  const states = [
    'AL', 'AK', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA',
    'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS',
    'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA',
    'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WI', 'WV', 'WY',
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName.length) {
      await setFirstNameError(true);
    } else {
      await setFirstNameError(false);
    }
    if (!lastName.length) {
      await setLastNameError(true);
    } else {
      await setLastNameError(false);
    }
    if (isNullOrEmpty(address1)) {
      await setAddress1Error(true);
    } else {
      await setAddress1Error(false);
    }
    if (isNullOrEmpty(city)) {
      await setCityError(true);
    } else {
      await setCityError(false);
    }
    if (!provinceCode.length) {
      await setProvinceCodeError(true);
    } else {
      await setProvinceCodeError(false);
    }
    if (!zip.length) {
      await setZipError2(false);
      await setZipError1(true);
    } else if (zip.length > 5 || zip.length < 5 || !Number(zip)) {
      await setZipError1(false);
      await setZipError2(true);
    } else {
      await setZipError1(false);
      await setZipError2(false);
    }
    if (phone.length && (phone.length > 10 || phone.length < 10 || !Number(phone))) {
      await setPhoneError(true);
    } else {
      await setPhoneError(false);
    }
    if (
      firstName.length && lastName.length && !isNullOrEmpty(address1) &&
      !isNullOrEmpty(city) && zip.length === 5 && Number(zip) && provinceCode.length &&
      (!phone.length || (phone.length === 10 && Number(phone)))
    ) {
      const firstNameCaps = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      const lastNameCaps = lastName.charAt(0).toUpperCase() + lastName.slice(1);
      const cityCaps = city.charAt(0).toUpperCase() + city.slice(1);
      const address1Caps = address1.charAt(0).toUpperCase() + address1.slice(1);
      const addressObject = {
        firstName: firstNameCaps.replace(/\s/g, ''),
        lastName: lastNameCaps.replace(/\s/g, ''),
        address1: address1Caps,
        address2: address2.replace(/\s/g, ''),
        city: cityCaps.replace(/\s/g, ''),
        provinceCode,
        zip: zip.replace(/\s/g, ''),
        phone,
      };
      await crud.post({
        path: '/addresses',
        body: {
          address: addressObject,
          email: user.email,
        },
      });
      setRefetch(!refetch);
      window.location.reload(true);
    }
  };

  useEffect(() => {
    (async () => {
      if (!LoadingAddresses) {
        try {
          setLoadingAddresses(true);
          const data = await crud.get({
            path: `/addresses/${user.email}`,
          });
          setAddresses(data.addresses);
          setDefaultAddress(data.defaultAddress);
          setCustomerId(data.id);
        } catch {
          setAlert({
            type: 'notification',
            message: 'Unable to get Addresses. Please try again later',
          });
        } finally {
          setLoadingAddresses(false);
        }
      }
    })();
    // eslint-disable-next-line no-use-before-define
  }, [refetch]);

  useEffect(() => {
    if (addresses?.length && defaultAddress?.id) {
      const sortedAddresses = addresses.sort((item) => {
        if (item.id === defaultAddress.id) return -1;
        return 0;
      });
      setAddresses(sortedAddresses);
    }
  }, [addresses, defaultAddress]);

  // Address Pagination Logic
  const itemsPerPage = 3;
  const [page, setPage] = useState(0);
  let pagesLength;
  if (addresses?.length) {
    pagesLength = Math.ceil(addresses.length / itemsPerPage) - 1;
  }
  const addressInPage = (index) => {
    const currPage = page * itemsPerPage;
    const nextPage = (page + 1) * itemsPerPage;
    return currPage <= index && nextPage > index;
  };

  useEffect(() => {
    setAddressFormValidationErrors({});
    setAddressForm(selectedAddressIndex === null ?
      { id: null, country: 'United States' } :
      {
        ...addresses[selectedAddressIndex],
        country: 'United States',
        isDefault: addresses[selectedAddressIndex].id === defaultAddress.id,
      });
  }, [selectedAddressIndex]);

  const register = (name, isCheckBox = false) => ({
    onChange: (e) => setAddressForm((currAddressForm) => {
      const { target: t } = e;
      return ({ ...currAddressForm, [t.name]: isCheckBox ? t.checked : t.value });
    }),
    name,
    ...(!isCheckBox && { value: AddressForm[name] }),
    ...(isCheckBox && { checked: AddressForm[name] }),
  });

  const renderFormError = useCallback((AddressFormItemName) => {
    const thisError = AddressFormValidationErrors[AddressFormItemName];
    const thisItem = AddressForm[AddressFormItemName];
    if (thisError && (!thisItem || thisItem === '')) {
      return (
        <Form.Text className="text-danger">
          {thisError}
        </Form.Text>
      );
    }
    return null;
  }, [AddressFormValidationErrors, AddressForm]);

  const handleAddAddress = () => {
    setSelectedAddressIndex(null);
    setPageTab(tabs.addressDetail);
  };

  const handleBackToAddressListPage = () => {
    setSelectedAddressIndex(null);
    if (!isEditAddress) {
      setAddressForm({ id: null, country: 'United States' });
      setAddressFormValidationErrors({});
    }
    setPageTab(tabs.addressList);
  };

  const [DeletingAddress, setDeletingAddress] = useState(false);
  const handleDeleteAddress = async (addressToDeleteId) => {
    if (DeletingAddress) return;
    /* eslint-disable no-alert */
    /* eslint-disable no-restricted-globals */
    if (!confirm('Are you sure you want to delete this address?')) return;

    const isDefaultAddress = addressToDeleteId === defaultAddress.id;

    const removeAddress = addresses.filter((item) => item.id !== addressToDeleteId);
    const deleteAddressesObject = {
      customerId,
      addresses: removeAddress,
    };

    try {
      setDeletingAddress(true);

      await crud.post({
        path: '/addresses/delete',
        body: deleteAddressesObject,
      });

      setAddresses(removeAddress);
      if (isDefaultAddress) setDefaultAddress({ id: '-1' });
      setAlert({
        type: 'notification',
        message: 'Deleted Address Successfully',
      });
    } catch (error) {
      console.log(error);
      setAlert({
        type: 'notification',
        message: 'Something went wrong!',
      });
    } finally {
      setDeletingAddress(false);
    }
  };

  return (
    <ContentWrapper>
      <Tab.Container activeKey={pageTab}>
        <Tab.Content>
          <Tab.Pane eventKey={tabs.addressList}>
            <Card>
              <Card.Body>
                <header className="d-flex align-items-center mb-32">
                  <h1>Addresses</h1>
                  <Button className="ms-auto" size="sm" onClick={handleAddAddress}>
                    {addresses?.length ? 'Edit Address' : 'Add Address'}
                  </Button>
                </header>
                <main>
                  {(LoadingAddresses && !addresses?.length) && (
                    <Placeholder animation="glow" className="d-block col-4">
                      <Placeholder className="d-block col-8 py-8 mb-8" />
                      <Placeholder className="d-block col-7 py-8 mb-8" />
                      <Placeholder className="d-block col-10 py-8 mb-8" />
                      <Placeholder className="d-block col-3 py-8 mb-8" />
                      <Placeholder className="d-block col-5 py-8 mb-8" />
                      <Placeholder className="d-block col-5 py-8 mb-8" />
                    </Placeholder>
                  )}
                  {
                    addresses?.length
                      ? addresses?.map((item, idx) => {
                        if (!addressInPage(idx)) return null;
                        const isDefaultAddress = defaultAddress.id === item.id;
                        return (
                          <div>
                            {isDefaultAddress && <small className="rounded-pill bg-dark text-primary px-16 py-8 float-end">Default Address</small>}
                            <div>{`${item.firstName} ${item.lastName}`}</div>
                            <div>{item.address1}</div>
                            <div>{item.address2}</div>
                            <div>{item.city}</div>
                            <div>{item.province}</div>
                            <div>{item.zip}</div>
                            <p>{item.country}</p>
                            <div className="d-flex align-items-center gap-16">
                              {/* <Button size="sm" variant="link p-0 m-0" onClick={() => handleSelectAddress(idx)}>Edit</Button> */}
                              <Button size="sm ms-8" onClick={() => handleDeleteAddress(item.id)} disabled={DeletingAddress}>Delete</Button>
                            </div>
                            {(idx < addresses.length - 1 && addressInPage(idx + 1)) && <hr className="my-32 opacity-50" />}
                          </div>
                        );
                      })
                      :
                      <p>No address on file</p>
                  }
                </main>
                <footer className="mt-40">
                  {addresses?.length !== 0 && (
                    <Pagination>
                      {page !== 0 && <Pagination.Prev onClick={() => setPage((p) => p - 1)} />}
                      <Pagination.Item active>{page + 1}</Pagination.Item>
                      {page < pagesLength && <Pagination.Next onClick={() => setPage((p) => p + 1)} />}
                    </Pagination>
                  )}
                </footer>
              </Card.Body>
            </Card>
          </Tab.Pane>
          <Tab.Pane eventKey={tabs.addressDetail} mountOnEnter unmountOnExit>
            <Card>
              <Card.Body>
                <Button variant="link" className="px-0 text-decoration-none mb-16" onClick={handleBackToAddressListPage}>
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" className="me-8" />
                  Back to Address List
                </Button>
                <h2 className="mb-24">
                  {isEditAddress ? 'Edit Address' : 'Add Address'}
                </h2>
                <Form className="d-flex flex-column gap-16" onSubmit={(e) => handleSubmit(e)}>
                  <Row xs={1} md={2}>
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      {firstNameError && (<small className="text-red ms-2">required field</small>)}
                      <Form.Control
                        value={firstName}
                        onChange={({ target }) => setFirstName(target.value)}
                      />
                      {renderFormError('firstName')}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      {lastNameError && (<small className="text-red ms-2">required field</small>)}
                      <Form.Control
                        value={lastName}
                        onChange={({ target }) => setLastName(target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group>
                    <Form.Label>Address 1</Form.Label>
                    {address1Error && (<small className="text-red ms-2">required field</small>)}
                    <Form.Control
                      value={address1}
                      onChange={({ target }) => setAddress1(target.value)}
                    />
                    {renderFormError('address1')}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      value={address2}
                      onChange={({ target }) => setAddress2(target.value)}
                    />
                  </Form.Group>
                  <Row xs={1} md={2}>
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      {cityError && (<small className="text-red ms-2">required field</small>)}
                      <Form.Control
                        value={city}
                        onChange={({ target }) => setCity(target.value)}
                      />
                      {renderFormError('city')}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control {...register('country')} disabled />
                    </Form.Group>
                  </Row>
                  <Row xs={1} md={2}>
                    <Form.Group>
                      <div className="d-flex align-items-end mb-2">
                        <p className="mt-3 mb-0">State*</p>
                        {provinceCodeError && (<small className="text-red ms-2">required field</small>)}
                      </div>
                      <Dropdown className="bg-white w-100" style={{ height: '3rem' }}>
                        <Dropdown.Toggle className="bg-white rounded-0 m-0 h-100 px-16 w-100">
                          {provinceCode || 'Select a state'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100" style={{ height: '15rem', overflowY: 'scroll' }}>
                          {states.map(state => (
                            <Dropdown.Item onClick={() => setProvinceCode(state)}>
                              <span className="fs-5">{state}</span>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Row>
                  <Row xs={1} md={2}>
                    <Form.Group>
                      <Form.Label>Postal / Zip Code</Form.Label>
                      {zipError1 && (<small className="text-red ms-2">required field</small>)}
                      {zipError2 && (<small className="text-red ms-2">invalid zip code</small>)}
                      <Form.Control
                        value={zip}
                        onChange={({ target }) => setZip(target.value)}
                      />
                      {renderFormError('zip')}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Phone Number</Form.Label>
                      {phoneError && (<small className="text-red ms-2">invalid phone number</small>)}
                      <Form.Control
                        value={phone}
                        onChange={({ target }) => setPhone(target.value)}
                      />
                    </Form.Group>
                  </Row>
                  {/* <Form.Check on label="set as Default Address" {...register('isDefault', true)} className="my-24" /> */}
                  <div>
                    <Button type="submit" variant="dark me-8" size="sm">
                      Submit
                    </Button>
                    <Button onClick={handleBackToAddressListPage} size="sm">Cancel</Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </ContentWrapper>
  );
};

export default Addresses;
