const TRASHIE_DOCUMENT_TITLE = 'Trashie';
const TRASHIE_FAVICON = 'favicon-trashie.png';

const FORDAYS_DOCUMENT_TITLE = 'Fordays - Closet App';
const FORDAYS_FAVICON = 'favicon.webp';

// TODO - Make these configurable per env
const TRASHIE_GA_TAG_ID = {
  STAGING: 'G-TFCNJWNSPW',
  PRODUCTION: 'G-JJHCBQLQ4R',
};

const TRASHIE_POSTHOG_API_KEY = {
  STAGING: 'phc_tCUfib7HsWpwrzmqTE9kmuPmsagpRTf2NccosoRCTnP',
  PRODUCTION: 'phc_eudH9GJGrFDW2HKu5vE1x1XkvNcaL6Qg69yivOQ22A',
};

const TRASHIE_LOGROCKET_APP_ID = {
  STAGING: '4wlolz/trashie-qa',
  PRODUCTION: '4wlolz/trashie-production',
};

const TRASHIE_POSTHOG_API_HOST = 'https://app.posthog.com';

const LIST_PAGE_SIZE = 12;

const LIST_SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

const PREVIEW_LIST_PAGE_SIZE = 3;
const INITIAL_PAGE = 1;

const FORDAYS_STORE_ID = '5280b4e0-5354-4e8e-9e4d-2bffee9e37f2';
const UPS_CARRIER_NAME = 'UPS';

export {
  TRASHIE_DOCUMENT_TITLE,
  TRASHIE_FAVICON,
  FORDAYS_DOCUMENT_TITLE,
  FORDAYS_FAVICON,
  TRASHIE_GA_TAG_ID,
  TRASHIE_POSTHOG_API_KEY,
  TRASHIE_POSTHOG_API_HOST,
  TRASHIE_LOGROCKET_APP_ID,
  LIST_PAGE_SIZE,
  LIST_SORT_DIRECTION,
  PREVIEW_LIST_PAGE_SIZE,
  INITIAL_PAGE,
  FORDAYS_STORE_ID,
  UPS_CARRIER_NAME,
};
