import classNames from 'classnames';

import { POSTHOG_CAPTURE_ATTRIBUTES, getTrackerId, TRACKER_IDS, getActionType } from '../../../config/tracker';
import tCoinLogo from '../../../images/t-coin-logo.svg';
import ChevronUp from '../../SvgComponents/ChevronUp_svg';

import './Card.scss';

const {
  myRewards: {
    detail: {
      cardLink: trackerCardLink,
    },
  },
} = TRACKER_IDS;

export const CARD_SIZE = {
  LARGE: 'large',
  SMALL: 'small',
};

export const CARD_TYPE = {
  DEFAULT: 'default',
  DETAILS: 'details',
};

const Card = ({
  logoUrl,
  logoStyle,
  value,
  info,
  size = CARD_SIZE.SMALL,
  type = CARD_TYPE.DETAILS,
  color,
  backgroundColor,
  cardLink,
  height,
}) => {
  if (cardLink) {
    return (
      <a
        href={cardLink}
        style={{ width: '100%' }}
        aria-label="card store url"
        {...{
          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCardLink),
          [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCardLink),
        }}
      >
        <CardBase
          logoUrl={logoUrl}
          logoStyle={logoStyle}
          value={value}
          info={info}
          size={size}
          type={type}
          color={color}
          backgroundColor={backgroundColor}
          cardLink={cardLink}
          height={height}
        />
      </a>
    );
  }

  return (
    <CardBase
      logoUrl={logoUrl}
      logoStyle={logoStyle}
      value={value}
      info={info}
      size={size}
      type={type}
      color={color}
      backgroundColor={backgroundColor}
      cardLink={cardLink}
      height={height}
    />
  );
};

const CardBase = ({
  logoUrl,
  logoStyle,
  value,
  info,
  size = CARD_SIZE.SMALL,
  type = CARD_TYPE.DETAILS,
  color,
  backgroundColor,
  height,
}) => (
  <div
    className={classNames('RewardCard', size, { default: type === CARD_TYPE.DEFAULT })}
    style={{ background: backgroundColor, height }}
  >
    <div className="RewardCard__top">
      <div className="logo" style={logoStyle}>
        <img src={logoUrl || tCoinLogo} alt="merchant logo" />
      </div>
      {type === CARD_TYPE.DETAILS && (
        <span
          className={classNames('total', { small: value.length >= 10 })}
          style={{ color }}
        >
          {value}
        </span>
      )}
    </div>
    {type === CARD_TYPE.DETAILS && (
      <div className="RewardCard__bottom">
        <div className="description" style={{ color }}>
          {info}
        </div>
        {size === CARD_SIZE.SMALL && (
          <div className="arrow">
            <ChevronUp color={color} />
          </div>
        )}
      </div>
    )}
  </div>
);

export default Card;
