import { useState, useEffect } from 'react';

import Loader from '../../../global/Loader';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import AddressesGlobal from '../../../views/Addresses';

import styles from './Addresses.module.scss';

const { main, container } = styles;

const Addresses = () => {
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, user } = useFirebaseAuth();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSignedIn && user) {
      setIsLoading(false);
    }
  }, [isSignedIn, user]);

  return (
    <div className={main}>
      <div className={container}>
        {isLoading || isLoadingFirebaseAuth ? (
          <Loader message="...Loading" />
        ) : (
          <AddressesGlobal />
        )}
      </div>
    </div>
  );
};

export default Addresses;
