import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

import { SIZE_MD, SIZE_SM, SIZE_XS, isMobile } from '../../../../hooks/useBreakPoint';

// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css';
import './Slider.scss';

const getPerPage = (slidesPerPage, mobileType) => {
  switch (mobileType) {
    case SIZE_SM:
      return Math.floor(slidesPerPage / 3) || 1;
    case SIZE_MD:
      return Math.floor(slidesPerPage / 2);
    default:
      return slidesPerPage;
  }
};

/*
  <Slider
    slides={
      [
        {
          key: 'key',
          slide: <Component />,
        },
        ...
      ]
    }
  />

- ONLY for slides without fixed width, need to pass 'slidesPerPage' prop,
  so slide's width is dynamically calculated.
  ie: slidesPerPage={4}
*/

const Slider = ({
  slides = [],
  slidesPerPage = 1,
  paginationArrows,
  paginationDots,
}) => {
  const mobileSM = isMobile([SIZE_SM, SIZE_XS]) && SIZE_SM;
  const mobileMD = isMobile([SIZE_MD]) && SIZE_MD;

  const mobileType = mobileMD || mobileSM || false;

  const widthOptions = slidesPerPage > 1 && mobileType ? {
    fixedWidth: `calc(((100% + 1rem) / ${getPerPage(slidesPerPage, mobileType)}) - 1rem)`,
  } : {};

  return (
    <Splide
      hasTrack={false}
      className="Slider"
      options={{
        ...widthOptions,
        arrows: Boolean(paginationArrows),
        pagination: Boolean(paginationDots),
        type: 'slide',
        rewind: true,
        autoplay: true,
      }}
      aria-labelledby="slider"
    >
      <SplideTrack>
        {slides.map(({ key, slide }) => (
          <SplideSlide key={key}>
            {slide}
          </SplideSlide>
        ))}
      </SplideTrack>
      <div className="splide__arrows">
        {paginationArrows && (
          <>
            <button type="button" className="splide__arrow splide__arrow--prev" aria-label="Prev" />
            <button type="button" className="splide__arrow splide__arrow--next" aria-label="Next" />
          </>
        )}
      </div>
      {paginationDots && (<ul className="splide__pagination" />)}
    </Splide>
  );
};

export default Slider;
