import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import style from './ItemListContainer.module.scss';

const { container, titleSection, titleStyle, link, content } = style;
const ItemListContainer = ({ title, redirectText, route, children }) => (
  <div className={container}>
    <div className={titleSection}>
      <div className={titleStyle}>{title}</div>
      <Link className={link} to={route}>{redirectText}</Link>
    </div>
    <div className={content}>
      {children}
    </div>
  </div>
);

export default ItemListContainer;
