import { useHistory } from 'react-router-dom';

import { ROUTE_DASHBOARD_REWARDS } from '../../../config/routes';
import TitleSection from '../../../global/TitleSection';
import tbbFrontAll from '../../../images/tbb-front-all.png';
import { TAKE_BACK_BAG_URL } from '../../../config/externalURL';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../global/Button';
import { formatCurrencyNumber } from '../../../utils/numbers';
import useTbbAmountsExchange from '../../../../../hooks/useTbbAmountsExchange';

import './RewardContent.scss';

const ModalContentUnavailable = ({ value }) => {
  const history = useHistory();
  const { tbbAmount } = useTbbAmountsExchange();

  return (
    <div className="body-style unavailable-modal">
      <div className="body-top">
        <div className="unavailable-title">
          <TitleSection
            title="Ah bummer"
            subtitle={`Looks like you need ${formatCurrencyNumber(value)} in TrashieCash™ to get this reward.`}
          />
        </div>
        <div className="unavailable-text">
          <img className="bags-image" src={tbbFrontAll} alt="take back bag front all" />
          <p className="title">
            {`Boost your balance by $${tbbAmount} with a Take Back Bag`}
          </p>
        </div>
        <div className="unavailable-footer">
          <a className="get-take-back-bag-btn" href={TAKE_BACK_BAG_URL}>
            GET A TAKE BACK BAG
          </a>
          <Button
            type={BUTTON_TYPE.LINK}
            size={BUTTON_SIZE.SMALL}
            onClick={() => history.push(ROUTE_DASHBOARD_REWARDS)}
          >
            BACK TO MY DASHBOARD
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalContentUnavailable;
