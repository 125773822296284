import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_DASHBOARD, ROUTE_PROFILE } from '../../config/routes';
import Button, { BUTTON_TYPE } from '../../global/Button/Button';
import Input from '../../global/Input';
import { getGenericError } from '../../utils/errors';

import Loader from '../../../global/Loader';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';

import './Settings.scss';

const Settings = () => {
  const history = useHistory();
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, user } = useFirebaseAuth();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFinished, setIsFinished] = useState(false);

  const handleResetPassword = useCallback(async () => {
    if (!password || !confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      setErrorMessage('');
      setIsLoading(true);

      await user.updatePassword(password);

      setPassword('');
      setConfirmPassword('');
      setIsFinished(true);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(error?.message || getGenericError());
      setIsLoading(false);
    }
  }, [password, confirmPassword]);

  const handleOnPasswordChange = useCallback((newValue) => {
    setErrorMessage('');
    setPassword(newValue);
  }, []);

  const handleOnConfirmPasswordChange = useCallback((newValue) => {
    setErrorMessage('');
    setConfirmPassword(newValue);
  }, []);

  const handleClear = useCallback(() => {
    setErrorMessage('');
    setPassword('');
    setConfirmPassword('');
    setIsFinished(false);
    setIsLoading(false);

    history.push(ROUTE_PROFILE);
  }, []);

  useEffect(() => {
    if (isSignedIn && user) {
      setIsLoading(false);
    }
  }, [isSignedIn, user]);

  if (isLoading || isLoadingFirebaseAuth) {
    return (
      <Loader className="loader" />
    );
  }

  return (
    <div className="Settings">
      <div className="content">
        <div className="container">
          <div className="pane">
            <div className="form">
              {isFinished ? (
                <>
                  <p className="intro">Your Password has been changed!</p>
                  <button
                    type="button"
                    className="cta"
                    onClick={() => history.push(ROUTE_DASHBOARD)}
                  >
                    Go to My Scoreboard
                  </button>
                  <div className="options">
                    <button
                      type="button"
                      className="option"
                      onClick={handleClear}
                    >
                      Go to My Account Details
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p className="intro">Change Password</p>
                  <Input
                    type="password"
                    id="password"
                    label="Enter New Password"
                    placeholder="OKcoolguy1!"
                    containerClassName="form-item"
                    value={password}
                    onChange={handleOnPasswordChange}
                    errorMessage={Boolean(errorMessage)}
                  />
                  <Input
                    type="password"
                    id="confirmPassword"
                    label="Re-enter New Password"
                    placeholder="OKcoolguy1!"
                    containerClassName="form-item"
                    value={confirmPassword}
                    onChange={handleOnConfirmPasswordChange}
                    errorMessage={errorMessage}
                  />
                  <Button
                    type={BUTTON_TYPE.PRIMARY}
                    onClick={handleResetPassword}
                    disabled={!password || !confirmPassword || errorMessage}
                  >
                    Change Password
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
