import { REWARD_AMOUNT_TYPES_ID } from '../../config/rewards';
import { FILTER_SEARCH_PARAMS } from '../../config/routes';
import IconAll from './images/categories-all.svg';
import IconHot from './images/categories-hot.svg';
import IconAllDeals from '../../images/wand-sparkles.svg';

export const FLAGS = {
  ALL: 'all',
  IS_HOT: 'isHot',
  IS_NEW: 'isNew',
};

export const FIXED_CATEGORIES = [
  {
    uid: FLAGS.ALL,
    name: 'All rewards',
    iconUrl: IconAll,
    routeName: FLAGS.ALL,
  },
  {
    uid: FLAGS.IS_HOT,
    name: 'Hot!',
    iconUrl: IconHot,
    routeName: FLAGS.IS_HOT,
  },
];

export const FIXED_DEALS_CATEGORIES = [
  {
    uid: FLAGS.ALL,
    name: 'All deals',
    iconUrl: IconAllDeals,
    routeName: FLAGS.ALL,
  },
  {
    uid: FLAGS.IS_HOT,
    name: 'Hot!',
    iconUrl: IconHot,
    routeName: FLAGS.IS_HOT,
  },
];

export const getCategoryParam = (categoryId) => {
  switch (categoryId) {
    case FLAGS.IS_HOT:
      return { [FLAGS.IS_HOT]: true };
    case FLAGS.ALL:
      return {};
    default:
      return { categoryId };
  }
};

export const getFilterParams = ({
  minValue,
  maxValue,
  type,
  other,
}) => ({
  ...(type && { amountType: type }),
  ...(minValue && { exchangeValueMin: minValue }),
  ...(maxValue && { exchangeValueMax: maxValue }),
  ...other,
});

export const getCategory = (categories, categoryRouteName) => (
  (categoryRouteName && categories.find(({ routeName }) => routeName === categoryRouteName)) ||
  FIXED_CATEGORIES[0]
);

export const DEFAULT_MIN_VALUE_OPTION = '1';

export const DEFAULT_MAX_VALUE_OPTION = '50';

export const DEFAULT_TYPE_OPTION = 'ANY';

export const DEFAULT_OTHER_OPTION = {};

export const VALUE_OPTIONS = [
  {
    value: DEFAULT_MIN_VALUE_OPTION,
    label: '1',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '30',
    label: '$30',
  },
  {
    value: '40',
    label: '40',
  },
  {
    value: DEFAULT_MAX_VALUE_OPTION,
    label: '50+',
  },
];

export const TYPE_OPTIONS = [
  {
    value: DEFAULT_TYPE_OPTION,
    label: 'Any',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.VALUE,
    label: '$ OFF',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.PERCENTAGE,
    label: '% OFF',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.BONUS,
    label: 'Bonus',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.FREE_PRODUCT,
    label: 'Free product',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.FREE_CLASS,
    label: 'Free class',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.FREE_TRIAL_MONTH,
    label: 'X months free',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.FREE_TRIAL_WEEK,
    label: 'X weeks free',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.BUNDLE_AND_SAVE,
    label: 'Bundle and save',
  },
  {
    value: REWARD_AMOUNT_TYPES_ID.FREE_DELIVERY,
    label: 'Free Delivery',
  },
];

export const OTHER_OPTIONS = [
  {
    value: FLAGS.IS_NEW,
    label: 'New rewards',
  },
];

export const OTHER_DEALS_OPTIONS = [
  {
    value: FLAGS.IS_NEW,
    label: 'New Deals',
  },
];

export const getFilter = ({
  minValue,
  maxValue,
  type,
  other,
}) => {
  let minValueFilter = minValue && minValue !== DEFAULT_MIN_VALUE_OPTION &&
    VALUE_OPTIONS.find(({ value }) => value === minValue);
  let maxValueFilter = maxValue && maxValue !== DEFAULT_MAX_VALUE_OPTION &&
    VALUE_OPTIONS.find(({ value }) => value === maxValue);

  if (minValueFilter && maxValueFilter) {
    if (Number(minValueFilter.value) > Number(maxValueFilter.value)) {
      minValueFilter = undefined;
      maxValueFilter = undefined;
    }
  }

  const typeFilter = type && TYPE_OPTIONS.find(({ value }) => value === type);

  const otherFilter = (other ? {
    ...(typeof other?.[FLAGS.IS_NEW] !== 'undefined' && other[FLAGS.IS_NEW]) && { [FLAGS.IS_NEW]: true },
  } : {});

  return {
    ...(minValueFilter && { minValue: minValueFilter.value }),
    ...(maxValueFilter && { maxValue: maxValueFilter.value }),
    ...(typeFilter && { type: typeFilter.value }),
    ...(!!Object.values(otherFilter).length && { otehr: otherFilter }),
  };
};

export const updateQueryFilter = (query, searchParam, filter) => {
  if (filter) {
    query.set(searchParam, filter);
  } else {
    query.delete(searchParam);
  }
};

export const updateQueryFilters = (
  query,
  {
    minValue,
    maxValue,
    type,
    other,
  },
) => {
  updateQueryFilter(query, FILTER_SEARCH_PARAMS.MIN_VALUE, minValue);
  updateQueryFilter(query, FILTER_SEARCH_PARAMS.MAX_VALUE, maxValue);
  updateQueryFilter(query, FILTER_SEARCH_PARAMS.TYPE, type);
  updateQueryFilter(query, FILTER_SEARCH_PARAMS.IS_NEW, other?.[FLAGS.IS_NEW]);
};
