import firebase from 'firebase/app';
import { getConfig } from '../config/config';

const REACT_APP_API_ENDPOINT = getConfig('REACT_APP_CLOUD_API_ENDPOINT');

export const apiFetch = async ({ path, body = undefined, method = 'GET', cacheControl }) => {
  const methodToUse = method || (body ? 'POST' : 'GET');
  const token = await firebase.auth().currentUser?.getIdToken() || '';
  const res = await fetch(REACT_APP_API_ENDPOINT + path, {
    method: methodToUse,
    body,
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Length': body ? body?.length?.toString() : undefined,
      'Content-Type': body ? 'application/json' : undefined,
      ...(cacheControl && { 'Cache-Control': cacheControl }),
    },
  });
  const result = await res.json();
  if (res.status > 202) {
    const error = { error: true, ...result };
    throw error;
  }
  return result;
};

const get = async ({ path, options, cacheControl }) => {
  const queryString = new URLSearchParams({ options: JSON.stringify(options) }).toString();
  const pathString = options ? `${path}?${queryString}` : path;

  const { data } = await apiFetch({ path: pathString, cacheControl });
  return data;
};

const post = async ({ path, body }) => {
  const { data } = await apiFetch({ path, body: JSON.stringify(body), method: 'POST' });
  return data;
};

const patch = async ({ path, body }) => {
  const { data } = await apiFetch({ path, body: JSON.stringify(body), method: 'PATCH' });
  return data;
};

const deleteFunc = async ({ path }) => {
  const { data } = await apiFetch({ path, method: 'DELETE' });
  return data;
};

export default {
  get,
  post,
  patch,
  delete: deleteFunc,
};
