import classNames from 'classnames';

import Checkbox from '../Mui/Checkbox';
import FormControlLabel from '../Mui/FormControlLabel';

import './OptIn.scss';

const OptIn = ({
  checked,
  onChange,
  disabled,
  className,
}) => (
  <FormControlLabel
    label="Be the first to get new offers and rewards."
    classes={{
      root: classNames('OptIn', className),
      label: 'OptIn__label',
    }}
    disabled={disabled}
    control={(
      <Checkbox
        value="optIn"
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Be the first to get new offers and rewards.' }}
      />
    )}
  />
);

export default OptIn;
