import loader from '../../../images/loader.svg';
import loaderWhite from '../../../images/loaderWhite.svg';

const Loader = ({
  message = '',
  width = '100',
  className,
  whiteLoader = false,
}) => (
  <div className={className}>
    <img
      src={whiteLoader === true ? loaderWhite : loader}
      width={width}
      alt=""
    />
    {message && <h4 className="mt-4">{message}</h4>}
  </div>
);

export default Loader;
