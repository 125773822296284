import React from 'react';

export default function ChevronUp({ color, width = 18, height = 20 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="chevron-up 2" clipPath="url(#clip0_35_84)">
        <path id="Vector" d="M8.66599 5.42551L1.26244 12.7134C0.905375 13.0649 0.905375 13.6347 1.26244 13.9862L2.12595 14.8362C2.4824 15.1871 3.06012 15.1877 3.41741 14.8377L9.3125 9.06193L15.2075 14.8377C15.5648 15.1878 16.1426 15.1871 16.499 14.8362L17.3625 13.9862C17.7196 13.6347 17.7196 13.0649 17.3625 12.7134L9.95898 5.42555C9.60195 5.07406 9.02305 5.07406 8.66599 5.42551Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_35_84">
          <rect width="17.0667" height="19.2" fill="white" transform="matrix(-1 -2.62268e-07 -2.62268e-07 1 17.8458 0.530865)" />
        </clipPath>
      </defs>
    </svg>
  );
}
