import classNames from 'classnames';
import ArrowCircledIcon from '../../images/arrow-circled-icon.svg';

import './NavigationBar.scss';

const NavigationBar = ({
  title,
  onGoBack,
  onGoForward,
  className = '',
}) => (
  <div className={classNames('NavigationBar', className)}>
    <button
      type="button"
      className="NavigationBar__back"
      onClick={onGoBack}
      disabled={!onGoBack}
    >
      {onGoBack && (
        <img src={ArrowCircledIcon} alt="back icon" />
      )}
    </button>
    <span className="NavigationBar__title">{title}</span>
    <button
      type="button"
      className="NavigationBar__forward"
      onClick={onGoForward}
      disabled={!onGoForward}
    >
      {onGoForward && (
        <img src={ArrowCircledIcon} alt="forward icon" />
      )}
    </button>
  </div>
);

export default NavigationBar;
