import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { isRedeemPath, isContainerPath } from '../../utils/routes';
import { setDocumentTitle } from '../../utils/utils';

import styles from './Container.module.scss';

const {
  main,
  blank,
  content,
} = styles;

const Container = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setDocumentTitle();
  }, [pathname]);

  if (!isContainerPath(pathname)) {
    return children;
  }

  return (
    <div className={`${main} ${isRedeemPath(pathname) && blank}`}>
      <div className={content}>
        {children}
      </div>
    </div>
  );
};

export default Container;
