import style from './TitleSection.module.scss';

const {
  shadowedTitle,
  contentText,
  contentCta,
  titleSection,
  pretitleStyle,
  subtitleStyle,
  preSubTitleStyle,
  containerSubtitleStyle,
} = style;

const TitleSection = ({
  pretitle,
  title,
  preSubTitle,
  subtitle,
  extraTitleStyle,
  extraPretitleStyle,
  extraSubtitleStyle,
  extraContentTextStyle,
  cta,
}) => (
  <div className={titleSection}>
    <div className={`${contentText} ${extraContentTextStyle ?? ''}`}>
      {pretitle && (
        <span className={`${pretitleStyle} ${extraPretitleStyle ?? ''}`}>
          {pretitle}
        </span>
      )}
      <div className={`${shadowedTitle} ${extraTitleStyle ?? ''}`}>
        {title}
      </div>
      <div className={containerSubtitleStyle}>
        {preSubTitle && (
          <span className={`${subtitleStyle} ${preSubTitleStyle ?? ''}`}>
            {preSubTitle}
          </span>
        )}
        {subtitle && (
          <span className={`${subtitleStyle} ${extraSubtitleStyle ?? ''}`}>
            {subtitle}
          </span>
        )}
      </div>
    </div>
    {cta && (
      <div className={contentCta}>
        {cta}
      </div>
    )}
  </div>
);

export default TitleSection;
